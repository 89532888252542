import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Button, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const ProductForm = ({ product, onAddProduct, onEditProduct, onCancel }) => {
  const { t } = useTranslation()
  const ArticleCategorySchema = Yup.object().shape({
    label: Yup.string().required(t('pages.articleCategories.labelRequired'))
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={product || { label: '' }}
      validationSchema={ArticleCategorySchema}
    >
      <Form style={{ textAlign: 'left' }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <Typography>{t('pages.products.addConfirm')}</Typography>
        </Div>
        <Div
          sx={{
            mt: 2,
            mb: 1,
            mx: 2,
            gap: '4px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button size='normal' onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            size='normal'
            onClick={() => {
              const handler = product ? onEditProduct : onAddProduct
              handler()
            }}
          >
            {t('buttons.ok')}
          </Button>
        </Div>
      </Form>
    </Formik>
  )
}

export default ProductForm
