import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Modal
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { FE_URL } from 'app/services/config'
import {
  addProductCategory,
  deleteProductCategory,
  editProductCategory
} from 'app/services/api/productCategory'
import CategoryItem from './CategoryItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ProductCategoryForm from './ProductCategoryForm'
import { getProducts } from 'app/services/api/product'
import Div from '@jumbo/shared/Div/Div'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '70vw',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const ProductCategories = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const [categoryModal, setCategoryModal] = useState()

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadCategories = async () => {
    await getProducts(customerId).then(response => {
      const { categories } = response || {}
      const categoryArr = Object.keys(categories || {}).map(id => ({
        ...categories[id],
        id
      }))
      const filteredCategories = searchName
        ? categoryArr.filter(
            category =>
              category?.metadata?.label &&
              category.metadata.label[i18n.language]
                .toLowerCase()
                .includes(searchName)
          )
        : categoryArr
      setCategories(filteredCategories || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadCategories()
  }, [searchName])

  const handleDelete = async category => {
    hideDialog()
    setLoading(true)
    try {
      await deleteProductCategory(category.url, customerId)
      await loadCategories()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleAddCategory = async category => {
    setLoading(true)
    setCategoryModal()
    try {
      await addProductCategory(customerId, { ...category, active: true })
      await loadCategories()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditCategory = async category => {
    setLoading(true)
    setCategoryModal()
    try {
      await editProductCategory(customerId, { ...category, active: true })
      await loadCategories()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = category => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(category),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.productCategories')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='productCategories'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.articleCategories.addCategory')}>
          <IconButton onClick={() => setCategoryModal({ type: 'addCategory' })}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {categories?.length ? (
        categories.map((category, index) => (
          <CategoryItem
            key={index}
            item={category}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => setCategoryModal({ type: 'editCategory', category })}
            productLanguages={productLanguages}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
      <Modal
        open={categoryModal !== undefined}
        onClose={() => setCategoryModal()}
      >
        <Div sx={modalStyle}>
          <ProductCategoryForm
            title={t(`pages.articleCategories.${categoryModal?.type}`)}
            category={categoryModal?.category}
            onAddCategory={handleAddCategory}
            productLanguages={productLanguages}
            onEditCategory={handleEditCategory}
            onCancel={() => setCategoryModal()}
            customerId={customerId}
          />
        </Div>
      </Modal>
    </React.Fragment>
  )
}

export default ProductCategories
