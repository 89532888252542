export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

const findLevel = (categories, category, currentLevel) => {
  let level = currentLevel
  if (category.parentCategory) {
    return findLevel(
      categories,
      { ...categories[category.parentCategory] },
      level + 1
    )
  }
  return level
}

export const prepareCategoryLevels = categories => {
  const categoryMap = {}
  categories.forEach(category => (categoryMap[category._id] = category))
  categories.forEach((category, index) => {
    if (category.parentCategory && categoryMap[category.parentCategory]) {
      category.level = findLevel(categoryMap, category, 0)
    } else {
      category.level = 0
    }
  })
  let sortedCategories = categories.sort((a, b) => a.level - b.level)
  sortedCategories.forEach((category, index) => {
    if (category.parentCategory && categoryMap[category.parentCategory]) {
      const parentIndex = sortedCategories.findIndex(
        item => item._id === category.parentCategory
      )
      sortedCategories = arrayMove(sortedCategories, index, parentIndex + 1)
    }
  })
  return sortedCategories
}
