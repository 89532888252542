import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import Tab from '@mui/material/Tab'
import { TabContext, TabPanel } from '@mui/lab'
import TabList from '@mui/lab/TabList'
import { useTranslation } from 'react-i18next'
import Div from '@jumbo/shared/Div'
import { Stack } from '@mui/system'

const ModuleList = ({ moduleList: { types, modules }, onCancel, onOk }) => {
  const { t, i18n } = useTranslation()
  const [value, setValue] = useState(`${types[0].id}`)
  const [selectedModule, setSelectedModule] = useState('')

  useEffect(() => {
    setSelectedModule()
  }, [value])

  return (
    <Div>
      <Div sx={{ display: 'flex', minHeight: '450px' }}>
        <TabContext value={value}>
          <TabList
            orientation='vertical'
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              minWidth: '180px'
            }}
            onChange={(event, newValue) => setValue(newValue)}
          >
            {types.map(({ id, label }) =>
              label &&
              label[i18n.language] &&
              Object.keys(modules[id]).length ? (
                <Tab
                  label={label[i18n.language]}
                  value={`${id}`}
                  key={id}
                  sx={{ flex: '1 1 auto' }}
                />
              ) : null
            )}
          </TabList>
          {types.map(({ id, label }) => (
            <TabPanel value={`${id}`} sx={{ pt: 0 }} key={id}>
              <FormControl component='fieldset'>
                <RadioGroup
                  name='direction'
                  aria-label='direction'
                  value={selectedModule || ''}
                  onChange={event => {
                    setSelectedModule(event.target.value)
                  }}
                >
                  {modules &&
                    modules[value] &&
                    Object.keys(modules[value]).length &&
                    Object.keys(modules[value]).map(key => {
                      const { description, label, id, code } =
                        modules[value][key]
                      return (
                        <Stack key={id} direction='column' mb={1}>
                          <FormControlLabel
                            value={code}
                            control={<Radio />}
                            label={label[i18n.language]}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: description[i18n.language]
                            }}
                          />
                        </Stack>
                      )
                    })}
                </RadioGroup>
              </FormControl>
            </TabPanel>
          ))}
        </TabContext>
      </Div>
      <Div
        sx={{
          mt: 2,
          mb: 1,
          mx: 2,
          gap: '4px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button size='normal' onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
        <Button
          type='submit'
          variant='contained'
          size='normal'
          disabled={!selectedModule}
          onClick={() => onOk(selectedModule)}
        >
          {t('buttons.ok')}
        </Button>
      </Div>
    </Div>
  )
}

export default ModuleList
