import Div from '@jumbo/shared/Div'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { visuallyHidden } from '@mui/utils'
import { toFixed } from '@jumbo/utils'

const ReportTable = React.forwardRef(
  (
    {
      reportType,
      products,
      devices,
      currencyCode,
      defaultLanguage,
      enableTablePagination,
      reportDate
    },
    ref
  ) => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState(0)
    const rowsPerPage = 10
    const [orderBy, setOrderBy] = useState('numberOfOrders')
    const [order, setOrder] = useState('desc')

    const reportSource = reportType === 'daily' ? devices : products

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }
    const sortedData = reportSource.sort((p1, p2) => {
      if (order === 'asc') {
        if (typeof p1[orderBy] === 'object') {
          const name1 =
            (p1[orderBy] && p1[orderBy][defaultLanguage || i18n.language]) || ''
          const name2 =
            (p2[orderBy] && p2[orderBy][defaultLanguage || i18n.language]) || ''
          return name1.localeCompare(name2)
        } else if (typeof p1[orderBy] === 'string') {
          return p1[orderBy].localeCompare(p2[orderBy])
        }
        return p1[orderBy] - p2[orderBy]
      } else {
        if (typeof p1[orderBy] === 'object') {
          const name1 =
            (p1[orderBy] && p1[orderBy][defaultLanguage || i18n.language]) || ''
          const name2 =
            (p2[orderBy] && p2[orderBy][defaultLanguage || i18n.language]) || ''
          return name2.localeCompare(name1)
        } else if (typeof p1[orderBy] === 'string') {
          return p2[orderBy].localeCompare(p1[orderBy])
        }
        return p2[orderBy] - p1[orderBy]
      }
    })

    const visibleRows = React.useMemo(
      () =>
        sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
      [page, sortedData, order, orderBy]
    )
    const dataSource = enableTablePagination ? visibleRows : sortedData
    const total = dataSource?.reduce(
      (accumulator, currentValue) => {
        const { numberOfOrders, totalWithVAT, totalWithoutVAT, VAT } =
          currentValue
        return {
          numberOfOrders: numberOfOrders + accumulator.numberOfOrders,
          totalWithVAT: totalWithVAT + accumulator.totalWithVAT,
          totalWithoutVAT: totalWithoutVAT + accumulator.totalWithoutVAT,
          VAT: VAT + accumulator.VAT
        }
      },
      { numberOfOrders: 0, totalWithVAT: 0, totalWithoutVAT: 0, VAT: 0 }
    )

    const headCells = [
      {
        id: reportType === 'daily' ? 'uuid' : 'productName',
        label:
          reportType === 'daily'
            ? t('pages.dsokReports.deviceId')
            : t('pages.products.productName')
      },
      {
        id: 'numberOfOrders',
        label: t('pages.dsokReports.numberOfOrders')
      },
      {
        id: 'totalWithoutVAT',
        label: t('pages.dsokReports.totalWithoutVAT')
      },
      {
        id: 'VAT',
        label: t('pages.dsokReports.VAT')
      },
      {
        id: 'totalWithVAT',
        label: t('pages.dsokReports.totalWithVAT')
      }
    ]

    const createSortHandler = property => event => {
      const isAsc = orderBy === property && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(property)
    }
    return (
      <Div ref={ref} sx={{ p: 4 }}>
        {reportDate && (
          <Typography mb={4} align='center' variant='h2'>
            {reportDate}
          </Typography>
        )}
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: { md: 650, xs: 0 } }} size='small'>
              <TableHead>
                <TableRow>
                  {headCells.map(headCell => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {/* <TableCell>{t('pages.products.productName')}</TableCell>
                  <TableCell>{t('pages.dsokReports.numberOfOrders')}</TableCell>
                  <TableCell>
                    {t('pages.dsokReports.totalWithoutVAT')}
                  </TableCell>
                  <TableCell>{t('pages.dsokReports.VAT')}</TableCell>
                  <TableCell>{t('pages.dsokReports.totalWithVAT')}</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='row'>
                      {reportType === 'daily'
                        ? row?.deviceName
                          ? row?.deviceName
                          : row?.uuid
                        : (row?.productName &&
                            row?.productName[
                              defaultLanguage || i18n.language
                            ]) ||
                          ''}
                    </TableCell>
                    <TableCell>{row.numberOfOrders}</TableCell>
                    <TableCell>{`${toFixed(row.totalWithoutVAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                    <TableCell>{`${toFixed(row.VAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                    <TableCell>{`${toFixed(row.totalWithVAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                  </TableRow>
                ))}
                {dataSource?.length > 0 && reportType === 'daily' && (
                  <TableRow selected>
                    <TableCell component='th' scope='row'>
                      {t('pages.dsokOrders.total')}
                    </TableCell>
                    <TableCell>{total.numberOfOrders}</TableCell>
                    <TableCell>{`${toFixed(total.totalWithoutVAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                    <TableCell>{`${toFixed(total.VAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                    <TableCell>{`${toFixed(total.totalWithVAT, 2)} ${
                      currencyCode || ''
                    }`}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component='div'
            count={sortedData.length}
            rowsPerPage={enableTablePagination ? rowsPerPage : -1}
            page={page}
            onPageChange={handleChangePage}
          />
        </Paper>
      </Div>
    )
  }
)
export default ReportTable
