import React from 'react'
import FormGenerator from './form/FormGenerator'

const ModuleSettings = ({ module: { parameters }, children }) => {
  return (
    <FormGenerator parameters={parameters} type='settings'>
      {children}
    </FormGenerator>
  )
}

export default ModuleSettings
