import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const CategoryItem = ({
  item,
  disabled,
  onDelete,
  onEdit,
  defaultLanguage,
  categories,
  searchName
}) => {
  const { t, i18n } = useTranslation()
  const parentCategory = categories?.find(
    category => item?.parentCategory === category._id
  )
  return (
    <Card sx={{ mb: 1, ml: searchName ? 0 : item.level * 5 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          {/* <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.presentation.id')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{item.id}</Typography>
            </Stack>
          </Div> */}
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.label')}
            </Typography>
            <Typography variant={'body1'}>
              <Link onClick={onEdit}>
                {(item?.name && item?.name[defaultLanguage || i18n.language]) ||
                  ''}
              </Link>
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokCategories.parentCategory')}
            </Typography>
            <Typography variant={'body1'}>
              {(parentCategory?.name &&
                parentCategory?.name[defaultLanguage || i18n.language]) ||
                ''}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokCategories.includeInMenu')}
            </Typography>
            <Typography variant={'body1'}>
              {`${item?.include_in_menu}` === '1'
                ? t('buttons.yes')
                : t('buttons.no')}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokCategories.menuType')}
            </Typography>
            <Typography variant={'body1'}>{item?.menuType}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 0 },
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 70
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={onEdit}
                >
                  <Tooltip title={t('buttons.edit')} placement='top-start'>
                    <EditIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => onDelete(item)}
                >
                  <Tooltip title={t('buttons.delete')} placement='top-start'>
                    <DeleteForeverIcon />
                  </Tooltip>
                </IconButton>
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default CategoryItem
