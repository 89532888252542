import { useEffect, useState } from "react";

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    let resizeObserver
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
      resizeObserver = new ResizeObserver(() => {
        handleResize()
      });
      resizeObserver.observe(myRef.current);
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
      resizeObserver && resizeObserver.disconnect();
    }
  }, [myRef])

  return dimensions;
};