import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Grid,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { useFormikContext } from 'formik'
import { ColorField, Label } from 'app/pages/presentation/module/form/types'
import * as Yup from 'yup'

const FIELD_NAME = 'UI_SETTINGS_FIELD'

export const UI_SETTINGS_FIELD_SCHEMA = {
  welcomeTextSize: 58,
  welcomeTextColor: '#f0f0f0',
  buttonBackgroundColor: '#de4f44',
  buttonFontColor: '#f0f0f0',
  fontColorPrimary: '#1A1A1A',
  fontColorSecondary: '#de4f44',
  priceColor: '#de4f44'
}

export const getUISettingsFieldValidationSchema = t => ({
  welcomeTextSize: Yup.number().required(t('messages.checkRequiredFields')),
  welcomeTextColor: Yup.string().required(t('messages.checkRequiredFields')),
  buttonBackgroundColor: Yup.string().required(
    t('messages.checkRequiredFields')
  ),
  buttonFontColor: Yup.string().required(t('messages.checkRequiredFields')),
  fontColorPrimary: Yup.string().required(t('messages.checkRequiredFields')),
  fontColorSecondary: Yup.string().required(t('messages.checkRequiredFields')),
  priceColor: Yup.string().required(t('messages.checkRequiredFields'))
})

const colorFieldStyle = {
  mt: 0,
  mb: 0,
  mx: 0,
  div: { borderColor: 'rgba(0, 0, 0, 0.23)' }
}

const UISettingsField = ({ handleChange, expanded }) => {
  const { t, i18n } = useTranslation()
  const { values, setFieldValue } = useFormikContext()

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.dsokDetails.uiSettings')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                type='number'
                InputLabelProps={{ required: false }}
                required
                fullWidth
                name='uiSettings.welcomeTextSize'
                label={
                  <Label
                    text={t('pages.dsokDetails.welcomeTextSize')}
                    required
                  />
                }
                InputProps={{
                  value: values.uiSettings?.welcomeTextSize,
                  onChange: e =>
                    setFieldValue('uiSettings.welcomeTextSize', e.target.value)
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.welcomeTextColor'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.welcomeTextColor')
                },
                required: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.buttonBackgroundColor'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.buttonBackgroundColor')
                },
                required: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.buttonFontColor'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.buttonFontColor')
                },
                required: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.fontColorPrimary'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.fontColorPrimary')
                },
                required: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.fontColorSecondary'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.fontColorSecondary')
                },
                required: true
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorField
              name='uiSettings.priceColor'
              lang={i18n.language}
              sx={colorFieldStyle}
              parameters={{
                label: {
                  [i18n.language]: t('pages.dsokDetails.priceColor')
                },
                required: true
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default UISettingsField
