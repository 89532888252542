import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Tooltip, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { FE_URL } from 'app/services/config'
import {
  addArticleCategory,
  deleteArticleCategory,
  editArticleCategory,
  getArticleCategories
} from 'app/services/api/kiosk'
import CategoryItem from './CategoryItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ArticleCategoryForm from './ArticleCategoryForm'

const ArticleCategories = () => {
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()
  const { t } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()

  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadCategories = async () => {
    await getArticleCategories(customerId).then(categories => {
      const filteredCategories = searchName
        ? categories.filter(category =>
            category.label.toLowerCase().includes(searchName)
          )
        : categories
      setCategories(filteredCategories || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadCategories()
  }, [searchName])

  const handleDelete = async category => {
    hideDialog()
    setLoading(true)
    try {
      await deleteArticleCategory(category.url)
      await loadCategories()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, category) => {
    showDialog({
      variant: 'default',
      title: t(`pages.articleCategories.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <ArticleCategoryForm
          category={category}
          onAddCategory={handleAddCategory}
          onEditCategory={handleEditCategory}
          onCancel={hideDialog}
        />
      )
    })
  }

  const handleAddCategory = async category => {
    setLoading(true)
    hideDialog()
    try {
      await addArticleCategory(category)
      await loadCategories()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditCategory = async category => {
    setLoading(true)
    hideDialog()
    try {
      await editArticleCategory(category)
      await loadCategories()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = category => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(category),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.articleCategories')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='articleCategories'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.articleCategories.addCategory')}>
          <IconButton onClick={() => openModal('addCategory')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {categories?.length ? (
        categories.map((category, index) => (
          <CategoryItem
            key={index}
            item={category}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => openModal('editCategory', category)}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default ArticleCategories
