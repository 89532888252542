import Div from '@jumbo/shared/Div'
import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import ModuleItem from './ModuleItem'

const ModuleItemList = SortableContainer(
  ({ block, onUpdate, onDelete, blockUrl, presentationUrl }) => {
    const handleUpdate = updatedModule => {
      const { modules } = block
      const newModules = modules.map(module =>
        module.id === updatedModule.id ? updatedModule : module
      )
      onUpdate(newModules)
    }

    return (
      <Div>
        {block?.modules?.length ? (
          block.modules.map((module, index) => (
            <ModuleItem
              key={index}
              item={module}
              blockUrl={blockUrl}
              presentationUrl={presentationUrl}
              index={module.moduleOrder}
              handleUpdate={handleUpdate}
              handleDelete={onDelete}
            />
          ))
        ) : (
          <JumboListNoDataPlaceHolder />
        )}
      </Div>
    )
  }
)

export default ModuleItemList
