import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import Images from '../media/images/Images'
import { PROD_URL } from 'app/services/config'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { getMenu, getMenus, editMenu } from 'app/services/api/menu'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { Field, Form, Formik } from 'formik'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import { DAYS } from 'app/utils/constants/settings'
import { getProducts } from 'app/services/api/product'
import ProductItems from './ProductItems'
import { arrayMove } from 'react-sortable-hoc'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'

const username = 'menu'
const password = 'menu'

const EditMenu = () => {
  const { url } = useParams()
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { theme } = useJumboTheme()
  const [updating, setUpdating] = useState(false)
  const [selectedDay, setSelectedDay] = useState('monday')
  const [menu, setMenu] = useState()
  const [meals, setMeals] = useState([])
  const [selectedMeals, setSelectedMeals] = useState([])
  const [soups, setSoups] = useState([])
  const [selectedSoups, setSelectedSoups] = useState([])
  const [showPassword, setShowPassword] = useState(false)
  const formRef = useRef()
  const selectedLanguage =
    (productLanguages && productLanguages[0]?.code) || i18n.language

  const handleSortEnd = (day, type, oldIndex, newIndex) => {
    const products = type === 'soup' ? selectedSoups : selectedMeals
    const setProducts = type === 'soup' ? setSelectedSoups : setSelectedMeals
    const newArr = arrayMove(products[day], oldIndex, newIndex)
    setProducts({
      ...products,
      [day]: newArr.map((product, index) => ({ ...product, number: index + 1 }))
    })
    // setBlockList(arrayMove(blockList, oldIndex, newIndex))
  }
  const handleProductUpdate = (product, day, type, field, value) => {
    const products = type === 'soup' ? selectedSoups : selectedMeals
    const setProducts = type === 'soup' ? setSelectedSoups : setSelectedMeals
    const newProducts = { ...products }
    const selectedProduct = products[day].find(
      item => item.number === product.number
    )
    selectedProduct[field] = value
    setProducts(newProducts)
  }

  const handleAddProduct = (productId, day, type) => {
    const products = type === 'soup' ? soups : meals
    const product = products.find(
      item => `${item.id}` === `${productId}`
    ).metadata
    const selectedProducts = type === 'soup' ? selectedSoups : selectedMeals
    if (
      selectedProducts[day]?.find(
        item => item.name[selectedLanguage] === product.name[selectedLanguage]
      )
    ) {
      enqueueSnackbar(t('pages.menus.productIsAlreadyAdded'), {
        variant: 'warning'
      })
      return
    }

    const setSelectedProducts =
      type === 'soup' ? setSelectedSoups : setSelectedMeals
    // const newProducts = { ...selectedProducts }
    const number = selectedProducts[day].length + 1
    setSelectedProducts({
      ...selectedProducts,
      [day]: [
        ...selectedProducts[day],
        { ...product, number, label: product.name[selectedLanguage] }
      ]
    })
  }

  const handleDeleteProduct = (product, day, type) => {
    const products = type === 'soup' ? selectedSoups : selectedMeals
    const setProducts = type === 'soup' ? setSelectedSoups : setSelectedMeals
    const newProducts = {
      ...products,
      [day]: products[day]
        .filter(item => item.number !== product.number)
        .map((product, index) => ({ ...product, number: index + 1 }))
    }
    setProducts(newProducts)
  }

  const { enqueueSnackbar } = useSnackbar()

  const loadMenu = async () => {
    const menuPromise = getMenu(url, customerId).then(response => {
      const menu = {
        ...response,
        allMeals: [],
        allSoups: []
      }
      const meals = {}
      const soups = {}
      DAYS.forEach(day => {
        meals[day] = (menu.metadata && menu.metadata[day]?.meal) || []
        soups[day] = (menu.metadata && menu.metadata[day]?.soup) || []
      })
      setSelectedMeals(meals)
      setSelectedSoups(soups)
      setMenu(menu)
    })
    const productsPromise = getProducts(customerId).then(response => {
      const { products } = response || {}
      const productArr = Object.keys(products || {}).map(id => ({
        ...products[id],
        id
      }))
      const meals = [...productArr]
      const soups = [...productArr]
      setMeals(meals)
      setSoups(soups)
    })
    await Promise.all([menuPromise, productsPromise])
  }

  useEffect(() => {
    loadMenu()
  }, [url])

  const handleUpdateMenu = async menu => {
    setUpdating(true)
    const values = { ...menu }
    delete values.allMeals
    delete values.allSoups
    delete values.username
    delete values.password
    if (!values.metadata) {
      values.metadata = {}
    }
    let dateNotFound = false
    DAYS.forEach(day => {
      if (!values.metadata[day]) {
        values.metadata[day] = {}
      }
      values.metadata[day].meal = selectedMeals[day] || []
      values.metadata[day].soup = selectedSoups[day] || []
      if (!values.metadata[day].date) {
        dateNotFound = true
      }
    })
    if (dateNotFound || !values.label) {
      if (dateNotFound) {
        const message = t('pages.menus.dateRequired')
        enqueueSnackbar(message, {
          variant: 'error'
        })
      }
      if (!values.label) {
        const message = t('pages.articleCategories.labelRequired')
        enqueueSnackbar(message, {
          variant: 'error'
        })
      }
      setUpdating(false)
      return
    }
    try {
      await editMenu(customerId, values)
      await loadMenu()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <Link to='/'>{t('sidebar.menuItem.home')}</Link>
        <Link to='/menus'>{t('sidebar.menuItem.menus')}</Link>
        <Typography color='text.primary'>{menu?.label || ''}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: 'flex'
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  variant={'h4'}
                  mb={0}
                  sx={{
                    minWidth: 245,
                    [theme.breakpoints.down('md')]: {
                      minWidth: '100%',
                      marginBottom: 2
                    }
                  }}
                >
                  {menu?.label || ''}
                </Typography>
                <Stack direction='row' spacing={2}>
                  <Link to={`/menus`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: '100%'
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    startIcon={<BrowserUpdatedIcon />}
                    sx={{
                      boxShadow: 'none'
                    }}
                    size='small'
                    disabled={updating}
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.handleSubmit()
                      }
                    }}
                  >
                    {t('buttons.update')}
                  </Button>
                </Stack>
              </Div>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            width: '100%'
          }}
          wrapperSx={{
            p: 0,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          <>
            <Stack
              direction={'row'}
              spacing={2}
              display='flex'
              sx={{ mb: 2, width: '100%' }}
              alignItems='center'
            >
              {menu && (
                <Formik
                  // validateOnChange={true}
                  innerRef={formRef}
                  initialValues={menu}
                  // validationSchema={ArticleCategorySchema}
                  onSubmit={async data => {
                    handleUpdateMenu(data)
                  }}
                >
                  {props => (
                    <Form
                      style={{ textAlign: 'left', width: '100%' }}
                      noValidate
                    >
                      <Div
                        sx={{ mt: 2, mb: 1, mx: 2, display: 'flex', gap: 1 }}
                      >
                        <FormControlLabel
                          control={
                            <Field
                              name={'active'}
                              component={Switch}
                              onChange={e =>
                                props.setFieldValue('active', e.target.checked)
                              }
                            />
                          }
                          label={t('pages.products.active')}
                          checked={props.values.active}
                        />
                        <FormControlLabel
                          control={
                            <Field
                              name={'displayPrice'}
                              component={Switch}
                              onChange={e =>
                                props.setFieldValue(
                                  'displayPrice',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={t('pages.menus.displayPrice')}
                          checked={props.values.displayPrice}
                        />
                        <Link
                          to={`/menu/remote-control/${customerId}/${url}`}
                          style={{
                            textDecoration: 'none',
                            alignSelf: 'center'
                          }}
                          target='_blank'
                        >
                          <Button
                            variant='contained'
                            startIcon={<SettingsRemoteIcon />}
                            sx={{
                              boxShadow: 'none',
                              height: 36
                            }}
                            size='small'
                            disabled={updating}
                          >
                            {t('pages.menus.remoteControl')}
                          </Button>
                        </Link>

                        <JumboTextField
                          value={username}
                          defaultValue={username}
                          name={`password`}
                          label={t('pages.login.username')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <AccountCircleIcon />
                              </InputAdornment>
                            ),
                            readOnly: true
                          }}
                        />
                        <JumboTextField
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          defaultValue={password}
                          name={`password`}
                          label={t('pages.login.password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge='end'
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            readOnly: true
                          }}
                        />
                      </Div>
                      <Stack direction='row'>
                        <Div sx={{ mt: 1, mb: 2, ml: 2, mr: 0, width: '100%' }}>
                          <JumboTextField
                            fullWidth
                            required
                            multiline
                            name={`label`}
                            label={t('pages.presentation.label')}
                          />
                        </Div>
                        <FormControl
                          sx={{ mt: 1, mb: 2, ml: 2, mr: 2, width: '100%' }}
                          fullWidth
                        >
                          <JumboTextField
                            select
                            name='languageCode'
                            label={t('pages.articles.language')}
                          >
                            {productLanguages.map((language, index) => {
                              return (
                                <MenuItem key={index} value={language.code}>
                                  {language.label}
                                </MenuItem>
                              )
                            })}
                          </JumboTextField>
                        </FormControl>
                      </Stack>

                      <Stack
                        direction='row'
                        alignItems='center'
                        width='100%'
                        spacing={1}
                        px={2}
                      >
                        <TabContext value={selectedDay}>
                          <Stack width='100%'>
                            <Box
                              sx={{
                                borderBottom: 1,
                                borderColor: 'divider'
                              }}
                            >
                              <TabList
                                onChange={(_, val) => setSelectedDay(val)}
                              >
                                {DAYS.map(day => {
                                  return (
                                    <Tab
                                      key={day}
                                      label={t(`days.${day}`)}
                                      value={day}
                                    />
                                  )
                                })}
                              </TabList>
                            </Box>
                            {DAYS.map(day => {
                              return (
                                <TabPanel
                                  key={day}
                                  value={day}
                                  sx={{
                                    pb: 1,
                                    pr: 0,
                                    pl: 0
                                  }}
                                >
                                  <Stack display='flex' direction='column'>
                                    <FormControl
                                      sx={{
                                        mt: 1,
                                        mb: 1,
                                        pl: 0,
                                        pr: 0,
                                        width: '15%',
                                        minWidth: '150px'
                                      }}
                                    >
                                      <TextField
                                        id='date'
                                        required
                                        label={t('pages.menus.date')}
                                        type='date'
                                        name={`metadata.${day}.date`}
                                        value={
                                          (props.values.metadata &&
                                            props.values.metadata[day]?.date) ||
                                          ''
                                        }
                                        onChange={e =>
                                          props.setFieldValue(
                                            `metadata.${day}.date`,
                                            e.target.value
                                          )
                                        }
                                        fullWidth
                                        sx={{ input: { px: 1 } }}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                      />
                                    </FormControl>
                                    <Typography variant='h4' sx={{ mt: 1 }}>
                                      {t('pages.menus.soups')}
                                    </Typography>
                                    <FormControl
                                      sx={{
                                        mt: 1,
                                        mb: 1,
                                        mx: 0,
                                        pl: 0,
                                        pr: 0,
                                        width: '35%',
                                        minWidth: '280px'
                                      }}
                                    >
                                      <Autocomplete
                                        options={soups}
                                        autoHighlight
                                        getOptionLabel={option =>
                                          option.metadata?.name[
                                            selectedLanguage
                                          ] || option.id
                                        }
                                        value={null}
                                        blurOnSelect={true}
                                        onChange={e =>
                                          handleAddProduct(
                                            e.target.value,
                                            day,
                                            'soup'
                                          )
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            component='li'
                                            sx={{
                                              '& > img': {
                                                mr: 2,
                                                flexShrink: 0
                                              }
                                            }}
                                            value={option.id}
                                            {...props}
                                          >
                                            {option.metadata?.name[
                                              selectedLanguage
                                            ] || option.id}
                                          </Box>
                                        )}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label={t('pages.menus.selectSoups')}
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                    <Field
                                      component={ProductItems}
                                      products={selectedSoups[day]}
                                      onSortEnd={({ oldIndex, newIndex }) =>
                                        handleSortEnd(
                                          day,
                                          'soup',
                                          oldIndex,
                                          newIndex
                                        )
                                      }
                                      type='soup'
                                      day={day}
                                      onUpdate={handleProductUpdate}
                                      onDelete={handleDeleteProduct}
                                      useDragHandle={true}
                                    />
                                    <Typography variant='h4' sx={{ mt: 1 }}>
                                      {t('pages.menus.meals')}
                                    </Typography>
                                    <FormControl
                                      sx={{
                                        mt: 1,
                                        mb: 1,
                                        mx: 0,
                                        pl: 0,
                                        pr: 0,
                                        width: '35%',
                                        minWidth: '280px'
                                      }}
                                    >
                                      <Autocomplete
                                        options={meals}
                                        autoHighlight
                                        getOptionLabel={option =>
                                          option.metadata?.name[
                                            selectedLanguage
                                          ] || option.id
                                        }
                                        value={null}
                                        blurOnSelect={true}
                                        onChange={e =>
                                          handleAddProduct(
                                            e.target.value,
                                            day,
                                            'meal'
                                          )
                                        }
                                        renderOption={(props, option) => (
                                          <Box
                                            component='li'
                                            sx={{
                                              '& > img': {
                                                mr: 2,
                                                flexShrink: 0
                                              }
                                            }}
                                            value={option.id}
                                            {...props}
                                          >
                                            {option.metadata?.name[
                                              selectedLanguage
                                            ] || option.id}
                                          </Box>
                                        )}
                                        renderInput={params => (
                                          <TextField
                                            {...params}
                                            label={t('pages.menus.selectMeals')}
                                            inputProps={{
                                              ...params.inputProps,
                                              autoComplete: 'new-password' // disable autocomplete and autofill
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                    <Field
                                      component={ProductItems}
                                      products={selectedMeals[day]}
                                      onSortEnd={({ oldIndex, newIndex }) =>
                                        handleSortEnd(
                                          day,
                                          'meal',
                                          oldIndex,
                                          newIndex
                                        )
                                      }
                                      type='meal'
                                      day={day}
                                      onUpdate={handleProductUpdate}
                                      onDelete={handleDeleteProduct}
                                      useDragHandle={true}
                                    />
                                  </Stack>
                                </TabPanel>
                              )
                            })}
                          </Stack>
                        </TabContext>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              )}
            </Stack>
          </>
        </JumboCardQuick>
      </Div>
    </>
  )
}

export default EditMenu
