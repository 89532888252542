import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import Div from '@jumbo/shared/Div/Div'
import { Button, Grid, IconButton, Stack, Typography } from '@mui/material'
import { getWidgets } from 'app/services/api/home'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import ImageIcon from '@mui/icons-material/Image'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder'

const LIST_COUNT_LIMIT = 5

const Home = () => {
  const { t, i18n } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const { customerId, customer } = getAuthUser()
  const [widgets, setWidgets] = useState({})
  const superadmin = customer?.customerType === 1

  useEffect(() => {
    getWidgets(customerId).then(response => {
      setWidgets(response)
    })
  }, [])

  const widgetList = widgets?.widgets || {
    screens: true,
    presentations: true,
    products: true,
    kiosk: true,
    dsok: true,
    images: true,
    support: true,
    news: true
  }

  return (
    <Div>
      <Typography variant='h2'>{t('pages.home.header')}</Typography>
      <Grid container spacing={2} alignItems={'stretch'}>
        {widgetList.screens && (
          <Grid item xs={12} md={4}>
            <JumboCardQuick
              title={
                <Typography variant='h2'>
                  {t('sidebar.menuItem.screens')}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
            >
              <Stack spacing={1}>
                {widgets?.screens
                  ?.slice(0, LIST_COUNT_LIMIT)
                  .map(({ name, url }) => (
                    <Link
                      component={RouterLink}
                      underline='hover'
                      key={url}
                      to={`/screen/${url}`}
                      sx={{ width: 'fit-content' }}
                    >
                      <Typography variant='body'>{name}</Typography>
                    </Link>
                  ))}
                <Link
                  underline='hover'
                  component={RouterLink}
                  to='/screens'
                  style={{ width: 'fit-content', alignSelf: 'flex-end' }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      width: 'fit-content',
                      mt: 2
                    }}
                    startIcon={<ScreenshotMonitorIcon />}
                  >
                    {t('pages.home.allScreens')}
                  </Button>
                </Link>
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
        {widgetList.presentations && (
          <Grid item xs={12} md={4}>
            <JumboCardQuick
              title={
                <Typography variant='h2'>
                  {t('sidebar.menuItem.presentations')}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
            >
              <Stack spacing={1}>
                {widgets?.presentations
                  ?.slice(0, LIST_COUNT_LIMIT)
                  .map(({ name, url }) => (
                    <Link
                      component={RouterLink}
                      underline='hover'
                      key={url}
                      to={`/presentation/${url}`}
                      sx={{ width: 'fit-content' }}
                    >
                      <Typography variant='body'>{name}</Typography>
                    </Link>
                  ))}
                <Link
                  underline='hover'
                  component={RouterLink}
                  to='/presentations'
                  sx={{ width: 'fit-content', alignSelf: 'flex-end' }}
                >
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      width: 'fit-content',
                      mt: 2
                    }}
                    startIcon={<ScreenshotMonitorIcon />}
                  >
                    {t('pages.home.allPresentations')}
                  </Button>
                </Link>
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
        {widgetList.images && (
          <Grid item xs={12} md={4}>
            <JumboCardQuick
              title={
                <Typography variant='h2'>
                  {t('sidebar.menuItem.images')}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              sx={{ height: '100%' }}
            >
              <Stack>
                <Link
                  component={RouterLink}
                  underline='hover'
                  to='/media/images'
                  sx={{
                    width: 'fit-content',
                    alignSelf: 'center',
                    color: 'text.primary'
                  }}
                >
                  <ImageIcon sx={{ fontSize: 150 }} />
                </Link>
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
        {widgetList.news && (
          <Grid item xs={12} md={8}>
            <JumboCardQuick
              title={
                <Typography variant='h2'>
                  {t('sidebar.menuItem.news')}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              sx={{ height: '100%' }}
            >
              <Stack>
                <JumboListNoDataPlaceholder width={{ md: 120, xs: 180 }} />
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
        {widgetList.support && (
          <Grid item xs={12} md={4}>
            <JumboCardQuick
              title={
                <Typography variant='h2'>{t('pages.home.contacts')}</Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              sx={{ height: '100%' }}
            >
              <Stack spacing={4}>
                {Object.keys(widgets?.support || {}).map(lang => {
                  return (
                    <Stack key={lang}>
                      <Typography variant='h5'>
                        {widgets?.support[lang].name}
                      </Typography>
                      <Link href={`mailto:${widgets?.support[lang].email}`}>
                        <Typography variant='body'>
                          {widgets?.support[lang].email}
                        </Typography>
                      </Link>
                      <Link href={`phone:${widgets?.support[lang].phone}`}>
                        <Typography variant='body'>
                          {widgets?.support[lang].phone}
                        </Typography>
                      </Link>
                    </Stack>
                  )
                })}
                <Stack>
                  <Link href='https://login.datavision-online.eu/uploads/datavision-app/datavision_win_app.zip'>
                    <Typography variant='body'>
                      {t('pages.home.downloadApp')}
                    </Typography>
                  </Link>
                  {superadmin && (
                    <Link href='https://jenkins.gastrokiosk.online/job/Datavision Reverse SSH/lastSuccessfulBuild/artifact/*zip*/archive.zip'>
                      <Typography variant='body'>
                        {t('pages.home.downloadReverseSSH')}
                      </Typography>
                    </Link>
                  )}
                </Stack>
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
      </Grid>
    </Div>
  )
}

export default Home
