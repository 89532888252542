import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Pagination,
  Box,
  Modal
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import AttributeItem from './AttributeItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DSOKProductAttributeForm from './DSOKProductAttributeForm'
import {
  deleteDSOKProductAttribute,
  getCustomer,
  getDSOKProductAttributes,
  upsertDSOKProductAttribute
} from 'app/services/api/management'
import jwtAuthAxiosKiosk from 'app/services/auth/jwtAuthKioskStaging'
import Div from '@jumbo/shared/Div'

const ITEM_PER_PAGE = 10

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '30vw',
  maxHeight: '80vh',
  overflowY: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const DSOKProductAttributes = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { url }
  } = getAuthUser()

  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const [openModal, setOpenModal] = useState()
  const [page, setPage] = useState(1)

  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [customer, setCustomer] = useState()
  const defaultLanguage =
    customer?.dsokSettings?.customer?.defaultLanguage || i18n.language
  const customerUrl = url || ''

  const languages = customer?.dsokSettings?.customer?.languages || []

  const loadAttributes = async () => {
    await getDSOKProductAttributes().then(attributes => {
      const attributesArr = Object.keys(attributes || {}).map(id => ({
        ...attributes[id]
      }))

      let filteredProducts = searchName
        ? attributesArr.filter(
            attribute =>
              attribute?.name &&
              attribute.name[defaultLanguage].toLowerCase().includes(searchName)
          )
        : attributesArr

      setAttributes(filteredProducts || [])
    })
    setLoading(false)
  }

  const searchByName = async searchName => {
    setLoading(true)
    if (!jwtAuthAxiosKiosk.defaults.baseURL) {
      await loadCustomer()
    }
    searchName !== undefined && loadAttributes()
  }

  useEffect(() => {
    searchByName(searchName)
    setPage(1)
  }, [searchName])

  const loadCustomer = async () => {
    await getCustomer(customerUrl).then(customer => setCustomer(customer))
  }

  const handleDelete = async attributeId => {
    hideDialog()
    setLoading(true)
    try {
      await deleteDSOKProductAttribute(attributeId)
      await loadAttributes()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleAddAttribute = async attribute => {
    setLoading(true)
    setOpenModal()
    try {
      await upsertDSOKProductAttribute(attribute)
      await loadAttributes()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditAttribute = async attribute => {
    setLoading(true)
    setOpenModal()
    try {
      await upsertDSOKProductAttribute(attribute)
      await loadAttributes()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = attribute => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(attribute._id),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.productAttributes')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        mb={2}
        spacing={1}
      >
        <Box width='50%' display='flex' flexDirection='row'>
          <Card sx={{ mb: 0, width: '100%', flex: 5 }}>
            <JumboSearch
              page='DSOKProductAttributes'
              onChange={setSearchName}
              sx={{
                width: '100%'
              }}
              placeholder={t('pages.presentation.searchPresentation')}
            />
          </Card>
          <Tooltip title={t('pages.productAttributes.addAttribute')}>
            <IconButton onClick={() => setOpenModal({ type: 'addAttribute' })}>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Pagination
          count={Math.ceil(attributes?.length / ITEM_PER_PAGE)}
          color='primary'
          page={page}
          onChange={(_, val) => setPage(val)}
        />
      </Stack>
      {attributes?.length ? (
        attributes.map((attribute, index) => (
          <AttributeItem
            key={index}
            item={attribute}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => setOpenModal({ type: 'editAttribute', attribute })}
            productLanguages={languages}
            defaultLanguage={defaultLanguage}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
      <Modal open={openModal !== undefined} onClose={() => setOpenModal()}>
        {openModal ? (
          <Div sx={modalStyle}>
            <Typography variant='h5' mb={3}>
              {t(`pages.productAttributes.${openModal?.type}`)}
            </Typography>
            <DSOKProductAttributeForm
              attribute={openModal?.attribute}
              onAddAttribute={handleAddAttribute}
              productLanguages={languages}
              defaultLanguage={defaultLanguage}
              onEditAttribute={handleEditAttribute}
              onCancel={hideDialog}
            />
          </Div>
        ) : (
          <></>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default DSOKProductAttributes
