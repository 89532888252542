import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Box
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import moment from 'moment'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const StatusIcon = ({ status }) =>
  status ? (
    <CheckCircleIcon sx={{ color: '#2C9E4B' }} />
  ) : (
    <HighlightOffIcon sx={{ color: '#DA1113' }} />
  )

const OrderItem = ({
  item,
  currencyCode,
  onPreview,
  onDelete,
  onEkasaRegister,
  customer
}) => {
  const { t } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const { type } = getAuthUser() || {}
  const dsokOperator = type === 4
  const { dsokSettings, customerType } = customer || {}
  const devices = dsokSettings?.customer?.devices
  let deviceName = ''
  for (const key in devices) {
    const device = devices[key]
    if (device?.uuid === item.kioskUUID) {
      deviceName = device?.deviceName
    }
  }
  let posError = ''
  let transactionID = ''
  if (item?.orderStatus === 'payment failed') {
    transactionID = item?.posError?.transactionID
      ? `TID: ${item?.posError?.transactionID}`
      : ''
    posError = item?.posError?.Result
      ? item?.posError?.Result
      : item?.posError?.error?.ErrorCondition
    if (item?.posError?.Result && item?.posError?.error?.ErrorCondition) {
      posError = item?.posError?.error?.ErrorCondition
    }
  }
  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '0 1 auto' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.deviceName')}
            </Typography>
            <Typography variant={'body1'}>{deviceName}</Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 1 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.createdAt')}
            </Typography>
            <Typography variant={'body1'}>
              {item?.createdAt
                ? moment(item?.createdAt).format('DD.MM.YYYY - HH:mm:ss')
                : ''}
              <br />
              {transactionID}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '1' },
              flexShrink: 0,
              px: 1,
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokOrders.orderStatus')}
            </Typography>
            <Typography textTransform='capitalize' variant={'body1'}>
              {item?.orderStatus} {item?.demo ? ' - DEMO' : ''}
              <br />
              {posError}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: 1 },
              flexShrink: 0,
              px: 1,
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={1}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokOrders.orderSent')}
              <span
                style={{
                  display: 'inline'
                }}
              >
                {item?.internalOrderId && `/${t('pages.dsokOrders.orderId')}`}
              </span>
            </Typography>
            <Typography
              variant={'body1'}
              display='flex'
              alignItems='center'
              gap={1}
              pb='6px'
            >
              <StatusIcon status={item?.orderSentStatus} />
              {item?.internalOrderId}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '1 1' },
              flexShrink: 0,
              px: 1,
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={1}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokOrders.payment')}
            </Typography>
            <Typography variant={'body1'}>
              <StatusIcon status={item?.posStatus} />
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '1 1' },
              flexShrink: 0,
              px: 1,
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={1}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokDetails.ekasa')}
            </Typography>
            <Typography variant={'body1'}>
              <StatusIcon status={item?.ekasaStatus} />
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '0 1 auto' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={1}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokOrders.pickupCode')}
              <span
                style={{
                  display: 'inline',
                  fontWeight: 700,
                  color: '#2C9E4B'
                }}
              >
                {item?.locator && `/${t('pages.dsokOrders.locator')}`}
              </span>
            </Typography>
            <Typography variant={'body1'}>
              {item?.pickupCode}
              <span
                style={{
                  display: 'inline',
                  fontWeight: 700,
                  color: '#2C9E4B'
                }}
              >
                {item?.locator && `/${item?.locator}`}
              </span>
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: '0 1 auto' },
              flexShrink: 0,
              px: 1,
              display: { xs: 'none', md: 'block' }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.dsokOrders.total')}
            </Typography>
            <Typography variant={'body1'}>{`${
              item?.grandTotal?.toFixed
                ? item?.grandTotal.toFixed(2)
                : item?.grandTotal
            } ${currencyCode || ''}`}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 0 },
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: { xs: 'none', md: 70 }
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  onClick={onPreview}
                >
                  <Tooltip title={t('buttons.preview')} placement='top-start'>
                    <VisibilityIcon />
                  </Tooltip>
                </IconButton>
                {item?.orderSentStatus &&
                  item?.posStatus &&
                  !item?.demo &&
                  !item?.ekasaStatus && (
                    <IconButton
                      size='small'
                      variant={'contained'}
                      onClick={() => onEkasaRegister(item?._id)}
                    >
                      <Tooltip
                        title={t('pages.dsokOrders.eKasaRegistration')}
                        placement='top'
                      >
                        <AppRegistrationIcon />
                      </Tooltip>
                    </IconButton>
                  )}
                {!dsokOperator && (
                  <IconButton
                    size='small'
                    variant={'contained'}
                    onClick={() => onDelete(item?._id)}
                  >
                    <Tooltip title={t('buttons.delete')} placement='top'>
                      <DeleteForeverIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default OrderItem
