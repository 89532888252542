import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes, {
  kioskRoutes,
  presentationRoutes,
  routesForAuthenticatedOnly,
  routesForNotAuthenticatedOnly,
  routesForSuperAdminOnly,
  screenRoutes,
  templateRoutes,
  productRoutes,
  dsokRoutes
} from '../../../app/routes'
import routePathMatch from '@jumbo/hooks/useRoutePathMatch'
import {
  getMainAuth,
  removeAuth,
  storeToken,
  storeUser,
  storeVersion
} from './authHelpers'
import { config } from '../../../app/config/main'
import { AuthContext } from '@jumbo/components/JumboAuthProvider/JumboAuthContext'

const APP_VERSION = process.env.REACT_APP_VERSION

const storedToken = localStorage.getItem('token')
const storedUser = localStorage.getItem('user')
const storedVersion = localStorage.getItem('DV_VERSION')
let firstTimePageLoad = true

const init = () => {
  if (!config?.authSetting) {
    throw Error(
      `You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`
    )
  }

  if (storedToken) {
    storeToken(storedToken) // also sets axios header
  }

  return {
    authToken: storedToken ?? null,
    authUser: storedUser,
    isLoading: false,
    fallbackPath: config.authSetting.fallbackPath
  }
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set-auth-data':
      return {
        ...state,
        ...action.payload
      }

    case 'start-loading':
      return {
        ...state,
        isLoading: true
      }

    case 'stop-loading':
      return {
        ...state,
        isLoading: false
      }
  }
}

const JumboAuthProvider = ({ children, ...restProps }) => {
  const [authOptions, setAuthOptions] = React.useReducer(
    authReducer,
    restProps,
    init
  )
  const routesForSuperAdminOnlyArr = [...routesForSuperAdminOnly]
  const templateRoutesArr = [...templateRoutes]
  const presentationRoutesArr = [...presentationRoutes]
  const productRoutesArr = [...productRoutes]
  const kioksRoutesArr = [...kioskRoutes]
  const dsokRoutesArr = [...dsokRoutes]
  const screenRoutesArr = [...screenRoutes]
  const location = useLocation()
  const storedToken = localStorage.getItem('token')
  const [isAuthenticatedRouteOnly, setIsAuthenticatedRouteOnly] = useState()
  const [isNotAuthenticatedRouteOnly, setIsNotAuthenticatedRouteOnly] =
    useState()
  const [isSuperAdminOnly, setIsSuperAdminOnly] = useState()
  const [isPresentationRoute, setIsPresentationRoute] = useState()
  const [isKioskRoute, setIsKioskRoute] = useState()
  const [isDsokRoute, setIsDsokRoute] = useState()
  const [isTemplateRoute, setIsTemplateRoute] = useState()
  const [isProductRoute, setIsProductRoute] = useState()
  const [isScreenRoute, setIsScreenRoute] = useState()
  const [logout, setLogout] = React.useState(!storedToken)
  const navigate = useNavigate()

  useEffect(() => {
    if (location?.pathname) {
      const authenticatedRouteOnly = routePathMatch(
        location,
        routesForAuthenticatedOnly
      )
      setIsAuthenticatedRouteOnly(authenticatedRouteOnly)

      const superAdminOnly = routePathMatch(
        location,
        routesForSuperAdminOnlyArr
      )

      setIsSuperAdminOnly(superAdminOnly)

      const notAuthenticatedRouteOnly = routePathMatch(
        location,
        routesForNotAuthenticatedOnly
      )

      setIsNotAuthenticatedRouteOnly(notAuthenticatedRouteOnly)

      const presentationRoute = routePathMatch(location, presentationRoutesArr)

      setIsPresentationRoute(presentationRoute)

      const templateRoute = routePathMatch(location, templateRoutesArr)

      setIsTemplateRoute(templateRoute)

      const productRoute = routePathMatch(location, productRoutesArr)

      setIsProductRoute(productRoute)

      const kioskRoute = routePathMatch(location, kioksRoutesArr)

      setIsKioskRoute(kioskRoute)

      const dsokRoute = routePathMatch(location, dsokRoutesArr)

      setIsDsokRoute(dsokRoute)

      const screenRoute = routePathMatch(location, screenRoutesArr)

      setIsScreenRoute(screenRoute)
    }
  }, [
    location,
    location?.pathname,
    routesForSuperAdminOnlyArr,
    kioksRoutesArr,
    dsokRoutesArr,
    templateRoutesArr,
    presentationRoutesArr,
    screenRoutesArr
  ])

  React.useEffect(() => {
    if (logout) {
      removeAuth()
      setAuthOptions({
        type: 'set-auth-data',
        payload: { authToken: null, authUser: null, isLoading: false }
      })
      setLogout(false)
    }
  }, [logout])

  React.useEffect(() => {
    if (!storedToken) {
      setLogout(true)
    }
  }, [storedToken])

  React.useEffect(() => {
    if (APP_VERSION !== storedVersion) {
      storeVersion(APP_VERSION)
      setLogout(true)
    }
  }, [storedVersion])

  const setAuthToken = React.useCallback(async (response, temporary) => {
    if (!response) {
      setLogout(true)
      return
    }
    const { token, data } = response
    if (!token || !data) {
      setLogout(true)
      return
    }
    const { user, customer } = data
    const { username, firstName, lastName, email, customerId, userId, type } =
      user
    const mainAuth = temporary && getMainAuth()

    setAuthOptions({ type: 'start-loading' })

    storeToken(token)
    try {
      const authUser = {
        role: 'user',
        username,
        firstName,
        lastName,
        email,
        customerId,
        userId,
        customer,
        mainAuth,
        type
      } // here we can store roles etc.
      setAuthOptions({
        type: 'set-auth-data',
        payload: { authToken: token, authUser: authUser, isLoading: false }
      })
      storeUser(authUser)
      return
    } catch (error) {
      setLogout(true)
      console.error(error)
    }
  }, [])

  //todo: maybe in next version
  const setRedirectPath = React.useCallback(redirectPath => {
    setAuthOptions({ type: 'set-redirect-path', payload: { redirectPath } })
  }, [])

  const setAuthData = React.useCallback(data => {
    setAuthOptions({ type: 'set-auth-data', payload: data })
  }, [])

  const getAuthUser = () => {
    try {
      return JSON.parse(authOptions.authUser)
    } catch (error) {
      return authOptions.authUser
    }
  }

  const contextValue = React.useMemo(() => {
    return {
      ...authOptions,
      setAuthData,
      setRedirectPath,
      setAuthToken,
      setAuthOptions,
      getAuthUser
    }
  }, [authOptions])

  React.useEffect(() => {
    if (!authOptions.authToken) {
      if (isAuthenticatedRouteOnly) {
        navigate(authOptions?.fallbackPath)
      }
    } else if (!authOptions.authUser) {
      setAuthToken(authOptions.authToken)
    } else if (isNotAuthenticatedRouteOnly) {
      if (!firstTimePageLoad)
        navigate(config.authSetting.redirectNotAuthenticatedPath ?? '/')
      else firstTimePageLoad = false
    } else if (isSuperAdminOnly) {
      let authUser
      try {
        authUser = JSON.parse(authOptions.authUser)
      } catch (error) {
        authUser = authOptions.authUser
      }
      const customerType = authUser?.customer?.customerType
      if (customerType !== 1 && customerType !== 2) {
        navigate('/')
      }
    } else if (
      isPresentationRoute ||
      isKioskRoute ||
      isTemplateRoute ||
      isProductRoute ||
      isDsokRoute
    ) {
      let authUser
      try {
        authUser = JSON.parse(authOptions.authUser)
      } catch (error) {
        authUser = authOptions.authUser
      }
      const dsokOperator = authUser?.type === 4
      const kiosk = authUser?.customer?.kiosk
      const dsok = authUser?.customer?.dsok
      const presentations = authUser?.customer?.presentations
      const templates = authUser?.customer?.templates
      const products = authUser?.customer?.products
      const screens = authUser?.customer?.screens
      if (isKioskRoute && !kiosk) {
        navigate('/')
        return
      }
      if (dsokOperator && !isDsokRoute) {
        navigate('/')
        return
      }
      if (isDsokRoute && !dsok) {
        navigate('/')
        return
      }
      if (isPresentationRoute && !presentations) {
        navigate('/')
        return
      }
      if (isTemplateRoute && !templates) {
        navigate('/')
        return
      }
      if (isProductRoute && !products) {
        navigate('/')
        return
      }
      if (isScreenRoute && !screens) {
        navigate('/')
        return
      }
    } else {
      let authUser
      try {
        authUser = JSON.parse(authOptions.authUser)
      } catch (error) {
        authUser = authOptions.authUser
      }
      const dsokOperator = authUser?.type === 4
      if (dsokOperator && location.pathname !== '/customer/dsok/orders') {
        navigate('/')
        return
      }
    }
  }, [
    authOptions.authUser,
    isSuperAdminOnly,
    location?.pathname,
    isPresentationRoute,
    isKioskRoute,
    isTemplateRoute,
    isScreenRoute,
    isDsokRoute
  ])

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export default JumboAuthProvider
