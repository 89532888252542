import Div from '@jumbo/shared/Div'
import Images from 'app/pages/media/images/Images'
import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import JumboList from '@jumbo/components/JumboList'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import { PROD_URL } from 'app/services/config'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { useTranslation } from 'react-i18next'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import DeleteIcon from '@mui/icons-material/Delete'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useSnackbar } from 'notistack'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import { useParams } from 'react-router-dom'
import { getGallery, updateGallery } from 'app/services/api/media'

const EditGallery = () => {
  const { url } = useParams()
  const { getAuthUser } = useJumboAuth()
  const [images, setImages] = useState([])
  const [previewImageIndex, setPreviewImageIndex] = useState()
  const [imageDescription, setImageDescription] = useState()
  const [imageDimensions, setImageDimensions] = useState({
    width: 100,
    height: 100
  })
  const [openModal, setOpenModal] = useState()
  const [imageList, setImageList] = useState([])
  const [title, setTitle] = useState('')
  const { t } = useTranslation()
  const { customerId } = getAuthUser()
  const [updating, setUpdating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleImageDescription = () => {
    const newImages = [...images]
    newImages[openModal].label = imageDescription
    setImages(newImages)
    handleClose()
  }

  const handleClose = () => {
    setOpenModal()
    setImageDescription()
  }

  const handleNextImage = () => {
    setPreviewImageIndex(previewImageIndex + 1)
  }
  const handlePrevImage = () => {
    setPreviewImageIndex(previewImageIndex - 1)
  }

  const handleDeleteImage = index => {
    const newImages = images.filter((_, imageIndex) => imageIndex !== index)
    setImages(newImages)
  }

  const handleUpdateGallery = async () => {
    setUpdating(true)
    try {
      await updateGallery({
        url,
        label: title,
        images
      })
      await loadGallery()
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const onDragEnd = e => {
    if (e.destination?.droppableId === 'gallery') {
      if (e.source?.droppableId === 'images') {
        // Add to gallery
        const image = imageList[e.source.index]
        const url = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`
        const imageItem = { path: url, label: '' }
        const newImages = [...images]
        newImages.splice(e.destination.index, 0, imageItem)
        setImages(
          newImages.map((image, index) => ({ ...image, order: index + 1 }))
        )
      } else if (e.source?.droppableId === 'gallery') {
        // Reorder
        const items = Array.from(images)
        const [reorderedItem] = items.splice(e.source.index, 1)
        items.splice(e.destination.index, 0, reorderedItem)
        setImages(items)
        setImages(items.map((image, index) => ({ ...image, order: index + 1 })))
      }
    }
  }

  const loadGallery = async () => {
    await getGallery(url).then(gallery => {
      setTitle(gallery.label)
      if (gallery.images?.length) {
        setImages(
          gallery.images
            .sort((a, b) => a.order - b.order)
            .map((image, index) => ({ ...image, order: index + 1 }))
        )
      }
    })
  }

  useEffect(() => {
    loadGallery(url)
  }, [url])

  useEffect(() => {
    if (openModal !== undefined) {
      setImageDescription(images[openModal]?.label)
    }
  }, [openModal])

  const renderImage = useCallback(image => {
    if (!image) {
      return null
    }
    const { label, path } = image
    const index = images.findIndex(selectedImage => image === selectedImage)
    return (
      <Draggable
        draggableId={`${index}gallery`}
        index={index}
        isDragDisabled={false}
        key={index}
      >
        {(provided, snapshot) => {
          return (
            <ImageListItem
              key={index}
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                borderRadius: 2,
                overflow: 'hidden',
                width: 'auto',
                marginRight: 1,
                marginBottom: 1,
                img: {
                  height: '140px !important',
                  width: 'auto !important'
                },
                '& .MuiImageListItemBar-root': {
                  transition: 'all 0.3s ease',
                  transform: 'translateY(100%)'
                },

                '&:hover .MuiImageListItemBar-root': {
                  transform: 'translateY(0)'
                }
              }}
            >
              <img src={path} alt={label} loading='lazy' />
              <ImageListItemBar
                sx={{
                  '.MuiImageListItemBar-titleWrap': {
                    px: '5%'
                  }
                }}
                subtitle={
                  <Stack
                    direction='column'
                    spacing={1}
                    sx={{
                      mb: 0
                    }}
                  >
                    <Typography variant={'p'} noWrap mb={0}>
                      {label}
                    </Typography>
                    <Stack
                      direction='row'
                      spacing={0}
                      justifyContent='space-between'
                      sx={{
                        mb: 0
                      }}
                    >
                      <TextFieldsIcon
                        fontSize='medium'
                        onClick={() => setOpenModal(index)}
                      />
                      <ZoomInIcon
                        fontSize='medium'
                        onClick={() => setPreviewImageIndex(index)}
                      />
                      <DeleteIcon
                        fontSize='medium'
                        onClick={() => handleDeleteImage(index)}
                      />
                    </Stack>
                  </Stack>
                }
              />
            </ImageListItem>
          )
        }}
      </Draggable>
    )
  })

  const imageStyle =
    imageDimensions.width > imageDimensions.height
      ? {
          width: '60vw',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: 'auto',
          background: 'white'
        }
      : {
          width: 'auto',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: '60vh',
          background: 'white'
        }

  const previewImage = images[previewImageIndex]

  return (
    <Div sx={{ px: 1 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <JumboCardQuick
          title={
            <Div>
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <TextField
                  label={t('pages.presentation.label')}
                  fullWidth
                  sx={{ input: { px: 1 }, maxWidth: { lg: '160px' } }}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <Button
                  variant='contained'
                  size='small'
                  sx={{
                    boxShadow: 'none',
                    height: '38px'
                  }}
                  startIcon={<BrowserUpdatedIcon />}
                  disabled={updating}
                  onClick={handleUpdateGallery}
                >
                  {t('buttons.update')}
                </Button>
              </Div>
            </Div>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            flex: 1,
            mb: 1
          }}
          wrapperSx={{
            p: 1,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          <Div
            sx={{ flex: 1 }}
            headerSx={{
              borderBottom: 1,
              borderBottomColor: 'divider',
              '& .MuiCardHeader-action': {
                my: -0.75
              }
            }}
            wrapperSx={{
              p: 0,
              '&:last-child': {
                pb: 2
              },
              '& .MuiCollapse-entered:last-child': {
                '& .MuiListItemButton-root': {
                  borderBottom: 0,
                  borderBottomColor: 'transparent'
                }
              }
            }}
          >
            <Droppable droppableId={`gallery`} direction='horizontal'>
              {provided => {
                return (
                  <Div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{
                      border: 'solid 1px',
                      borderColor: 'text.secondary',
                      borderRadius: '4px',
                      my: 1
                    }}
                  >
                    <Typography
                      sx={{
                        margin: '-12px 0 0 10px',
                        background: 'white',
                        color: 'text.secondary',
                        width: 'fit-content',
                        paddingX: 1
                      }}
                    >
                      {t('pages.modules.imagesDescription')}
                    </Typography>
                    <JumboScrollbar
                      autoHeight
                      autoHeightMin={'40vh'}
                      autoHide
                      autoHideDuration={200}
                      autoHideTimeout={500}
                    >
                      <JumboList
                        primaryKey={'id'}
                        primary={'id'}
                        view='grid'
                        data={images}
                        renderItem={renderImage}
                        componentElement={'div'}
                        wrapperSx={{ pb: 1, paddingLeft: 5, paddingTop: 5 }}
                      />
                    </JumboScrollbar>
                    {provided.placeholder}
                  </Div>
                )
              }}
            </Droppable>
            <Modal
              open={previewImage !== undefined}
              onClose={() => setPreviewImageIndex()}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Div sx={modalStyle}>
                <Stack
                  direction='column'
                  divider={
                    previewImage &&
                    previewImage.label && (
                      <Divider orientation='horizontal' flexItem />
                    )
                  }
                  spacing={2}
                  sx={{
                    mb: 2,
                    color: 'white',
                    height: '100%'
                  }}
                >
                  <Typography
                    id='modal-modal-title'
                    variant='h4'
                    component='h2'
                    color='white'
                  >
                    {previewImage && previewImage.label}
                  </Typography>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    spacing={0}
                    sx={{ height: '100%' }}
                  >
                    <NavigateBeforeIcon
                      fontSize='large'
                      onClick={handlePrevImage}
                      sx={{
                        opacity: previewImageIndex === 0 ? 0.5 : 1.0,
                        cursor: previewImageIndex === 0 ? 'default' : 'pointer',
                        pointerEvents: previewImageIndex === 0 ? 'none' : 'all'
                      }}
                    />
                    <Avatar
                      src={previewImage && previewImage.path}
                      variant={'rounded'}
                      onLoad={e => {
                        setImageDimensions({
                          width: e.target.offsetWidth,
                          height: e.target.offsetHeight
                        })
                      }}
                      sx={imageStyle}
                    />
                    <NavigateNextIcon
                      fontSize='large'
                      onClick={handleNextImage}
                      sx={{
                        opacity:
                          images.length <= previewImageIndex + 1 ? 0.5 : 1.0,
                        cursor:
                          images.length <= previewImageIndex + 1
                            ? 'default'
                            : 'pointer',
                        pointerEvents:
                          images.length <= previewImageIndex + 1
                            ? 'none'
                            : 'all'
                      }}
                    />
                  </Stack>
                </Stack>
              </Div>
            </Modal>
          </Div>
        </JumboCardQuick>
        <Div>
          <Droppable droppableId={`images`} direction='horizontal'>
            {provided => {
              return (
                <Div {...provided.droppableProps} ref={provided.innerRef}>
                  <Images
                    layout={'row-reverse'}
                    noDragContext
                    passImageList={setImageList}
                  />
                  {provided.placeholder}
                </Div>
              )
            }}
          </Droppable>
        </Div>
        <Dialog open={openModal !== undefined} onClose={handleClose} fullWidth>
          <DialogTitle>{t('pages.modules.imageDescription')}</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <TextField
              margin='dense'
              label={t('pages.modules.imageDescription')}
              type='text'
              fullWidth
              variant='standard'
              value={imageDescription}
              onChange={e => setImageDescription(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
            <Button onClick={handleImageDescription}>{t('buttons.ok')}</Button>
          </DialogActions>
        </Dialog>
      </DragDropContext>
    </Div>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '90vh',
  width: '90vw',
  bgcolor: '#00000099',
  boxShadow: 24,
  p: 4
}

export default EditGallery
