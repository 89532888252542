import moment from 'moment'

export const fancyTimeFormat = duration => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600)
  const mins = ~~((duration % 3600) / 60)
  const secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs

  return ret
}

export const checkBetweenTime = (start, stop, givenTime) => {
  const format = 'hh:mm:ss'
  const time = moment(givenTime, format)
  const beforeTime = moment(start, format)
  const afterTime = moment(stop, format)

  return time.isBetween(beforeTime, afterTime)
}

export const isBeforeDate = (d1, d2) => {
  const date = moment(d1)
  const beforeDate = moment(d2)

  return date.isSameOrBefore(beforeDate)
}

export const isAfterDate = (d1, d2) => {
  const date = moment(d1)
  const afterDate = moment(d2)

  return date.isSameOrAfter(afterDate)
}
