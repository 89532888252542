import React, { useState } from 'react'

// query is the state
// SearchHandler is a function for changing the state.
const SearchContext = React.createContext({
  query: {},
  search: () => {}
})

// Defining a simple HOC component
const SearchContextProvider = props => {
  const [query, setQuery] = useState('')

  const search = (page, text) => {
    setQuery({ ...query, [page]: text })
  }

  return (
    <SearchContext.Provider value={{ query: query, search: search }}>
      {props.children}
    </SearchContext.Provider>
  )
}

export const useSearch = () => {
  return React.useContext(SearchContext)
}

export default SearchContextProvider
