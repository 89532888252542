import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import Div from '@jumbo/shared/Div/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { Field, useFormikContext } from 'formik'
import { Label } from 'app/pages/presentation/module/form/types'
import { DSOK_ORDER_MANAGEMENT_TYPES } from 'app/utils/constants/settings'
import * as Yup from 'yup'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { fetchDSOKProducts } from 'app/services/api/management'

const FIELD_NAME = 'ORDER_MANAGEMENT_FIELD'

export const ORDER_MANAGEMENT_FIELD_SCHEMA = {
  status: '',
  type: 'none',
  api: {
    createOrder: '',
    fetchProductsUrl: ''
  }
}

export const getOrderManagementFieldValidationSchema = t => ({
  status: '',
  type: Yup.string().required(t('messages.checkRequiredFields')),
  api: Yup.object().shape({
    createOrder: Yup.string().required(t('messages.checkRequiredFields')),
    fetchProductsUrl: Yup.string().required(t('messages.checkRequiredFields'))
  })
})

const OrderManagementField = ({ handleChange, expanded }) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext()
  const [fetching, setFetching] = useState()
  const { showDialog, hideDialog } = useJumboDialog()
  const { enqueueSnackbar } = useSnackbar()
  const fetchProductsUrl = DSOK_ORDER_MANAGEMENT_TYPES.find(
    type => type.code === values.orderManagement?.type
  )?.fetchProductsUrl

  const fetchProducts = async url => {
    hideDialog()
    setFetching(true)
    const fetchUrl = `${url}${url?.endsWith('/') ? '' : '/'}${
      values.customer.code
    }`
    try {
      await fetchDSOKProducts(fetchUrl)
      const message = `${t('pages.dsokDetails.fetchProducts')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('pages.dsokDetails.fetchProducts')} ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setFetching(false)
  }

  useEffect(() => {
    if (fetchProductsUrl && !values.orderManagement?.api?.fetchProductsUrl) {
      setFieldValue('orderManagement.api.fetchProductsUrl', fetchProductsUrl)
    }
  }, [fetchProductsUrl])

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.dsokDetails.orderManagement')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name='orderManagement.status'
                    component={Switch}
                    onChange={e =>
                      setFieldValue('orderManagement.status', e.target.checked)
                    }
                  />
                }
                label={t('pages.screens.status')}
                checked={values.orderManagement?.status}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
              <JumboTextField
                select
                required
                InputLabelProps={{ required: false }}
                name='orderManagement.type'
                label={<Label text={t('pages.dsokDetails.type')} required />}
              >
                {DSOK_ORDER_MANAGEMENT_TYPES.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type.code}>
                      {type.company}
                    </MenuItem>
                  )
                })}
              </JumboTextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                required
                name='orderManagement.api.createOrder'
                label={t('pages.dsokDetails.createOrder')}
              />
            </Div>
          </Grid>
          {fetchProductsUrl && (
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={9} md={9}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    required
                    name='orderManagement.api.fetchProductsUrl'
                    label={t('pages.dsokDetails.fetchProductsUrl')}
                  />
                </Div>
              </Grid>
              <Grid item xs={3} md={3}>
                <Div sx={{ mt: 1, mb: 1, height: '100%' }}>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      height: '75%'
                    }}
                    disabled={fetching}
                    onClick={() => {
                      showDialog({
                        variant: 'confirm',
                        title: t('widgets.confirmDialog.areYouSure'),
                        onYes: () =>
                          fetchProducts(
                            values.orderManagement?.api?.fetchProductsUrl
                          ),
                        onNo: () => hideDialog()
                      })
                    }}
                  >
                    {t('pages.dsokDetails.fetchProducts')}
                  </Button>
                </Div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default OrderManagementField
