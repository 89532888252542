import Div from '@jumbo/shared/Div/Div'
import { SortableContainer } from 'react-sortable-hoc'
import ProductItemSortable from './ProductItemSortable'

const ProductItems = SortableContainer(
  ({ products, onUpdate, type, day, onDelete }) => {
    return (
      <Div>
        {products.map((meal, index) => (
          <ProductItemSortable
            key={index}
            index={index}
            item={meal}
            onUpdate={onUpdate}
            onDelete={onDelete}
            type={type}
            day={day}
          />
        ))}
      </Div>
    )
  }
)

export default ProductItems
