import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import {
  Chip,
  Divider,
  Typography,
  Stack,
  Modal,
  Avatar,
  ImageListItem,
  ImageListItemBar,
  Button
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useJumboTheme } from '@jumbo/hooks'
import JumboList from '@jumbo/components/JumboList'
import Div from '@jumbo/shared/Div'
import { deleteFile, getImages } from 'app/services/api/media'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import FileStructure from 'app/shared/FileStructure/FileStructure'
import { BE_URL } from 'app/services/config'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import DeleteIcon from '@mui/icons-material/Delete'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { Draggable } from 'react-beautiful-dnd'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'

const Images = ({
  layout,
  noDragContext,
  passImageList,
  selectorMode,
  onCancel,
  onSelect,
  allowed
}) => {
  const { t } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const { showDialog, hideDialog } = useJumboDialog()
  const { enqueueSnackbar } = useSnackbar()

  const [fileStructure, setFileStructure] = useState([])
  const [images, setImages] = useState()
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedImage, setSelectedImage] = useState()
  const [selectedFolder, setSelectedFolder] = useState()
  const [previewImageIndex, setPreviewImageIndex] = useState()
  const [imageDimensions, setImageDimensions] = useState({
    width: 100,
    height: 100
  })
  const { theme } = useJumboTheme()
  const { customerId } = getAuthUser()

  const handleNextImage = () => {
    setPreviewImageIndex(previewImageIndex + 1)
  }
  const handlePrevImage = () => {
    setPreviewImageIndex(previewImageIndex - 1)
  }

  useEffect(() => {
    setSelectedImage()
  }, [selectedFolder])

  const handleDelete = async id => {
    await deleteFile(id, customerId)
      .then(res => {
        const message = `${t('buttons.delete')} ${t(
          'notifications.wasSuccessful'
        )}`
        enqueueSnackbar(message, {
          variant: 'success'
        })
      })
      .catch(() => {
        const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
        enqueueSnackbar(message, {
          variant: 'error'
        })
      })
    hideDialog()
    await loadImages()
  }

  const renderImage = React.useCallback(image => {
    const label = image.label.split('.')[0]
    const index = selectedImages.findIndex(
      selectedImage => image.id === selectedImage.id
    )
    const imageListItem = (
      <ImageListItem
        key={image.id}
        onClick={() => selectorMode && setSelectedImage(image)}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          width: 'auto',
          opacity:
            selectedImage && selectedImage.id !== image.id ? '0.5' : '1.0',
          marginRight: 1,
          marginBottom: 1,
          cursor: selectorMode && 'pointer',
          img: {
            height: '140px !important',
            width: 'auto !important'
          },
          '& .MuiImageListItemBar-root': {
            transition: 'all 0.3s ease',
            transform: 'translateY(100%)'
          },

          '&:hover .MuiImageListItemBar-root': {
            transform: 'translateY(0)'
          }
        }}
      >
        <img
          src={`${BE_URL}/uploads/files/${customerId}/Images/${image.filename}`}
          alt={image.label}
          loading='lazy'
        />
        <ImageListItemBar
          subtitle={
            <Stack
              direction='column'
              spacing={1}
              sx={{
                mb: 0
              }}
            >
              <Typography variant={'p'} noWrap mb={0}>
                {label}
              </Typography>
              <Stack
                direction='row'
                spacing={0}
                justifyContent='space-between'
                sx={{
                  mb: 0
                }}
              >
                <ZoomInIcon
                  onClick={() => setPreviewImageIndex(index)}
                  sx={{ cursor: 'pointer' }}
                />
                <DeleteIcon
                  onClick={() =>
                    showDialog({
                      variant: 'confirm',
                      title: t('widgets.confirmDialog.areYouSure'),
                      onYes: () => handleDelete(image.id),
                      onNo: () => hideDialog()
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                />
              </Stack>
            </Stack>
          }
        />
      </ImageListItem>
    )
    return noDragContext ? (
      <Draggable draggableId={`${index}`} index={index}>
        {(provided, snapshot) => {
          return (
            <Div
              ref={provided.innerRef}
              snapshot={snapshot}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              sx={{
                background: 'white',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {imageListItem}
            </Div>
          )
        }}
      </Draggable>
    ) : (
      <>{imageListItem}</>
    )
  })

  const loadImages = async () => {
    await getImages(customerId).then(({ media, catalogTree }) => {
      const images = allowed
        ? media.filter(image => allowed.includes(image.filename?.split('.')[1]))
        : media
      setImages(images)
      setFileStructure(catalogTree)
    })
  }

  useEffect(() => {
    loadImages()
  }, [])

  useEffect(() => {
    if (selectedFolder) {
      setSelectedImages(
        images
          .filter(image => `${image.catalogTree}` === selectedFolder)
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
  }, [selectedFolder, images])

  useEffect(() => {
    if (passImageList) {
      passImageList(selectedImages)
    }
  }, [selectedImages])

  const handleFolderSelect = folder => {
    setSelectedFolder(folder.id)
  }
  const previewImage = selectedImages[previewImageIndex]

  const imageStyle =
    imageDimensions.width > imageDimensions.height
      ? {
          width: '60vw',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: 'auto'
        }
      : {
          width: 'auto',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: '60vh'
        }
  return (
    <Stack
      direction={layout || 'column'}
      spacing={1}
      sx={{
        display: 'flex'
      }}
    >
      <JumboCardQuick
        sx={{ flex: 1 }}
        title={
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap'
              }
            }}
          >
            <Typography
              variant={'h4'}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down('md')]: {
                  minWidth: '100%',
                  marginBottom: 2
                }
              }}
            >
              {t('sidebar.menuItem.images')}{' '}
              <Chip
                size={'small'}
                label={selectedImages.length}
                color='primary'
              />
            </Typography>
          </Typography>
        }
        headerSx={{
          borderBottom: 1,
          borderBottomColor: 'divider',
          '& .MuiCardHeader-action': {
            my: -0.75
          }
        }}
        wrapperSx={{
          p: 0,
          '&:last-child': {
            pb: 2
          },
          '& .MuiCollapse-entered:last-child': {
            '& .MuiListItemButton-root': {
              borderBottom: 0,
              borderBottomColor: 'transparent'
            }
          }
        }}
      >
        <JumboScrollbar
          autoHeight
          autoHeightMin={'40vh'}
          autoHide
          autoHideDuration={200}
          autoHideTimeout={500}
        >
          <JumboList
            primaryKey={'id'}
            primary={'id'}
            view='grid'
            data={selectedImages}
            renderItem={renderImage}
            isLoading={!images}
            componentElement={'div'}
            wrapperSx={{ pb: 1, paddingLeft: 5, paddingTop: 5 }}
          />
        </JumboScrollbar>
        <Modal
          open={previewImage !== undefined}
          onClose={() => setPreviewImageIndex()}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Div sx={modalStyle}>
            <Stack
              direction='column'
              divider={<Divider orientation='horizontal' flexItem />}
              spacing={2}
              sx={{
                mb: 2
              }}
            >
              <Typography id='modal-modal-title' variant='h4' component='h2'>
                {previewImage && previewImage.label}
              </Typography>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={0}
              >
                <NavigateBeforeIcon
                  fontSize='large'
                  onClick={handlePrevImage}
                  sx={{
                    opacity: previewImageIndex === 0 ? 0.5 : 1.0,
                    cursor: previewImageIndex === 0 ? 'default' : 'pointer',
                    pointerEvents: previewImageIndex === 0 ? 'none' : 'all'
                  }}
                />
                <Avatar
                  src={
                    previewImage &&
                    `${BE_URL}/uploads/files/${customerId}/Images/${previewImage.filename}`
                  }
                  variant={'rounded'}
                  onLoad={e => {
                    setImageDimensions({
                      width: e.target.offsetWidth,
                      height: e.target.offsetHeight
                    })
                  }}
                  sx={imageStyle}
                />
                <NavigateNextIcon
                  fontSize='large'
                  onClick={handleNextImage}
                  sx={{
                    opacity:
                      selectedImages.length <= previewImageIndex + 1
                        ? 0.5
                        : 1.0,
                    cursor:
                      selectedImages.length <= previewImageIndex + 1
                        ? 'default'
                        : 'pointer',
                    pointerEvents:
                      selectedImages.length <= previewImageIndex + 1
                        ? 'none'
                        : 'all'
                  }}
                />
              </Stack>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {/* {previewTemplate && previewTemplate.description[i18n.language]} */}
              </Typography>
            </Stack>
          </Div>
        </Modal>
        {selectorMode && (
          <Stack direction='row' justifyContent='flex-end' spacing={2} mr={2}>
            <Button variant='contained' onClick={() => onCancel()}>
              {t('buttons.cancel')}
            </Button>
            <Button
              variant='contained'
              onClick={() => onSelect(selectedImage)}
              disabled={!selectedImage}
            >
              {t('buttons.ok')}
            </Button>
          </Stack>
        )}
      </JumboCardQuick>
      <FileStructure
        fileStructure={fileStructure}
        mediaType='image'
        onFolderSelect={handleFolderSelect}
        sx={{ minHeight: '40vh' }}
        defaultExpanded={'2'}
        reload={loadImages}
        customerId={customerId}
        uploadEnabled={true}
      />
    </Stack>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  maxWidth: '90vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

export default Images
