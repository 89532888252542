import { matchRoutes } from 'react-router-dom'
import { buildRoutes } from '@jumbo/utils'

const routePathMatch = (location, routes, searchPath) => {
  if (
    Array.isArray(routes) &&
    routes.length > 0 &&
    (searchPath || location?.pathname)
  ) {
    const generatedRoutes = buildRoutes(routes)
    const matchedRoutes = matchRoutes(generatedRoutes, location.pathname)
    return matchedRoutes && matchedRoutes.length > 0
  }

  return false
}

export default routePathMatch
