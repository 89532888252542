import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Button, MenuItem } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const DailyOrderCategoryForm = ({
  categories,
  onAddCategory,
  onCancel,
  productLanguages
}) => {
  const { t, i18n } = useTranslation()
  const DailyOrderCategorySchema = Yup.object().shape({
    category: Yup.string().required(t('pages.articles.categoryRequired'))
  })

  const selectedLanguage =
    (productLanguages && productLanguages[0]?.code) || i18n.language

  return (
    <Formik
      validateOnChange={true}
      initialValues={{ category: '' }}
      validationSchema={DailyOrderCategorySchema}
      onSubmit={async data => {
        onAddCategory(data)
      }}
    >
      <Form style={{ textAlign: 'left' }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            select
            fullWidth
            name='category'
            label={t('pages.products.categories')}
          >
            {Object.keys(categories || {}).map((item, index) => {
              const category = categories[item]
              return (
                <MenuItem key={category.id} value={category.id}>
                  {category.metadata.label[selectedLanguage]}
                </MenuItem>
              )
            })}
          </JumboTextField>
        </Div>
        <Div
          sx={{
            mt: 2,
            mb: 1,
            mx: 2,
            gap: '4px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button size='normal' onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button type='submit' variant='contained' size='normal'>
            {t('buttons.ok')}
          </Button>
        </Div>
      </Form>
    </Formik>
  )
}

export default DailyOrderCategoryForm
