import jwtAuthAxios from '../auth/jwtAuth'
import jwtAuthKioskStagingAxios from '../auth/jwtAuthKioskStaging'

export const getMediaTypes = async () => {
  const { data } = await jwtAuthAxios.get('/media/types')
  return data.data
}

export const getImages = async customerId => {
  const options = {
    params: {
      customerId,
      mediaTypeId: 1
    }
  }
  const { data } = await jwtAuthAxios.get('/media/getByType', options)
  return data.data
}

export const getVideos = async customerId => {
  const options = {
    params: {
      customerId,
      mediaTypeId: 2
    }
  }
  const { data } = await jwtAuthAxios.get('/media/getByType', options)
  return data.data
}

export const addFolder = async (parentId, folderName, mediaRootCode) => {
  const bodyFormData = new FormData()
  bodyFormData.append('parentId', parentId)
  bodyFormData.append('folderName', folderName)
  bodyFormData.append('mediaRootCode', mediaRootCode)
  const { data } = await jwtAuthAxios.post('/media/addFolder', bodyFormData)
  return data.status === 'OK'
}

export const renameFolder = async (folderId, customerId, label) => {
  const { data } = await jwtAuthAxios.post('/media/renameFolder', null, {
    params: {
      folderId,
      customerId,
      label
    }
  })
  return data.status === 'TRUE'
}

export const deleteFolder = async (folderId, customerId) => {
  const { data } = await jwtAuthAxios.delete('/media/deleteFolder', {
    params: {
      folderId,
      customerId
    }
  })
  return data
}

export const deleteFile = async (id, customerId) => {
  const { data } = await jwtAuthAxios.delete('/media/delete', {
    params: {
      id,
      customerId
    }
  })
  return data
}

export const addFile = async (customerId, files, catalogTreeId) => {
  const bodyFormData = new FormData()
  bodyFormData.append('customerId', customerId)
  files.forEach(file => {
    bodyFormData.append('files[]', file)
  })
  bodyFormData.append('catalogTreeId', catalogTreeId)
  const { data } = await jwtAuthAxios.post('/media/addMedia', bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data.status === 'OK'
}

export const getGalleries = async (customerId, userId) => {
  const options = {
    params: {
      customerId,
      userId
    }
  }
  const { data } = await jwtAuthAxios.get('/gallery/list', options)
  return data.galleries
}

export const getGallery = async galleryUrl => {
  const options = {
    params: {
      galleryUrl
    }
  }
  const { data } = await jwtAuthAxios.get('/gallery', options)
  return { images: data.images, label: data.galleryLabel }
}

export const addGallery = async gallery => {
  const { data } = await jwtAuthAxios.post('/gallery/add', gallery)
  return data.url
}

export const updateGallery = async gallery => {
  const { data } = await jwtAuthAxios.post('/gallery/update', gallery)
  return data
}

export const deleteGallery = async url => {
  const { data } = await jwtAuthAxios.delete(
    `/gallery/delete?galleryUrl=${url}`
  )
  return data?.result
}

export const uploadToImagekit = async (image, path) => {
  const bodyFormData = new FormData()
  bodyFormData.append('image', image)
  bodyFormData.append('path', path)
  const { data } = await jwtAuthKioskStagingAxios.post(
    '/imagekit/upload',
    bodyFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data.image
}
