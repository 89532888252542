import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Tooltip, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { FE_URL } from 'app/services/config'
import {
  addProductAttribute,
  deleteProductAttribute,
  editProductAttribute
} from 'app/services/api/productAttribute'
import AttributeItem from './AttributeItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ProductAttributeForm from './ProductAttributeForm'
import { getProducts } from 'app/services/api/product'

const ProductAttributes = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()

  const [attributes, setAttributes] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadAttributes = async () => {
    await getProducts(customerId).then(response => {
      const { attributes } = response || {}
      const attributeArr = Object.keys(attributes || {}).map(id => ({
        ...attributes[id],
        id
      }))
      const filteredAttributes = searchName
        ? attributeArr.filter(
            attribute =>
              attribute?.metadata &&
              attribute.metadata[i18n.language]
                .toLowerCase()
                .includes(searchName)
          )
        : attributeArr
      setAttributes(filteredAttributes || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadAttributes()
  }, [searchName])

  const handleDelete = async attribute => {
    hideDialog()
    setLoading(true)
    try {
      await deleteProductAttribute(attribute.url, customerId)
      await loadAttributes()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, attribute) => {
    showDialog({
      variant: 'default',
      title: t(`pages.productAttributes.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <ProductAttributeForm
          attribute={attribute}
          onAddAttribute={handleAddAttribute}
          productLanguages={productLanguages}
          onEditAttribute={handleEditAttribute}
          onCancel={hideDialog}
        />
      )
    })
  }

  const handleAddAttribute = async attribute => {
    setLoading(true)
    hideDialog()
    try {
      await addProductAttribute(customerId, {
        ...attribute.label,
        number: attribute.number
      })
      await loadAttributes()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditAttribute = async (attribute, url) => {
    setLoading(true)
    hideDialog()
    try {
      await editProductAttribute(
        customerId,
        { ...attribute.label, number: attribute.number },
        url
      )
      await loadAttributes()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = attribute => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(attribute),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.productAttributes')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='productAttributes'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.productAttributes.addAttribute')}>
          <IconButton onClick={() => openModal('addAttribute')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {attributes?.length ? (
        attributes.map((attribute, index) => (
          <AttributeItem
            key={index}
            item={attribute}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => openModal('editAttribute', attribute)}
            productLanguages={productLanguages}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default ProductAttributes
