import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Button, IconButton, InputAdornment } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TokenIcon from '@mui/icons-material/Token'
import * as Yup from 'yup'
import { generateToken } from 'app/services/api/management'

const CustomerForm = ({ customer, onEditCustomer, onCancel }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const CustomerSchema = Yup.object().shape({
    branch: Yup.string().required(t('pages.buildAndDeploy.branchRequired')),
    siteEnv: Yup.string().required(t('pages.buildAndDeploy.siteEnvRequired')),
    bearerToken: Yup.string().required(
      t('pages.buildAndDeploy.bearerTokenRequired')
    )
  })

  const handleGenerateToken = async setFieldValue => {
    setLoading(true)
    try {
      const token = await generateToken(customer?.id)
      setFieldValue('bearerToken', token)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        customerId: customer?.id || '',
        branch: customer?.branch || '',
        siteEnv: customer?.siteEnv || '',
        bearerToken: customer?.bearerToken || '',
        _id: customer?._id || ''
      }}
      validationSchema={CustomerSchema}
      onSubmit={async data => {
        onEditCustomer(data)
      }}
    >
      {({ setFieldValue }) => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              required
              name='branch'
              label={t('pages.buildAndDeploy.branch')}
            />
          </Div>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              required
              name='siteEnv'
              label={t('pages.buildAndDeploy.siteEnv')}
            />
          </Div>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              required
              name='bearerToken'
              label={t('pages.buildAndDeploy.bearerToken')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => handleGenerateToken(setFieldValue)}
                      disabled={loading}
                      edge='end'
                    >
                      <TokenIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Div>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: '4px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button size='normal' onClick={onCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='normal'>
              {t('buttons.ok')}
            </Button>
          </Div>
        </Form>
      )}
    </Formik>
  )
}

export default CustomerForm
