import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  Stack,
  Tooltip,
  Modal,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import merge from "lodash/merge";

import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import { editDevice, getDevice } from "app/services/api/kiosk";
import { Form, Formik } from "formik";
import MainField, { MAIN_FIELD_SCHEMA } from "./fields/MainField";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import ColorsField, { getColorsFieldSchema } from "./fields/ColorsField";
import ContactsField, { CONTACTS_FIELD_SCHEMA } from "./fields/ContactsField";
import WeatherField, { WEATHER_FIELD_SCHEMA } from "./fields/WeatherField";
import GalleriesField from "./fields/GalleriesField";
import MenusField from "./fields/MenusField";
import TranslationsField, {
  getTranslationsFieldSchema,
} from "./fields/TranslationsField";
import MainMenuField, { getMainMenuFieldSchema } from "./fields/MainMenuField";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import { mergeDeep } from "app/utils/objectHelpers";

const EditDevice = () => {
  const { url } = useParams();
  const { t } = useTranslation();
  const { theme } = useJumboTheme();
  const { getAuthUser } = useJumboAuth();
  const {
    customerId,
    customer: { kioskSettings },
  } = getAuthUser();
  const [device, setDevice] = useState();
  const [unsaved, setUnsaved] = useState(false);
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formRef = useRef();

  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const loadDevice = async () => {
    await getDevice(url).then((device) => setDevice(device));
  };

  const handleUpdateDevice = async (settings) => {
    setUpdating(true);
    try {
      await editDevice({ url, settings });
      await loadDevice();
      const message = `${t("buttons.update")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.update")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setUpdating(false);
    }
    setUpdating(false);
  };

  useEffect(() => {
    loadDevice();
  }, []);

  const initialValues = device && {
    main: merge(MAIN_FIELD_SCHEMA, device?.settings?.main || {}),
    colors: merge(getColorsFieldSchema(), device?.settings?.colors || {}),
    contact: merge(CONTACTS_FIELD_SCHEMA, device?.settings?.contact || {}),
    weather: merge(WEATHER_FIELD_SCHEMA, device?.settings?.weather || {}),
    galleries: device?.settings?.galleries || [],
    menus: device?.settings?.menus || [],
    translations: merge(
      getTranslationsFieldSchema(kioskSettings.languages),
      device?.settings?.translations || {}
    ),
    menu: mergeDeep(
      getMainMenuFieldSchema(kioskSettings.languages),
      device?.settings?.menu || {}
    ),
  };
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t("sidebar.menuItem.home")}</Link>
        <Link to="/kiosk/devices">{t("sidebar.menuItem.devices")}</Link>
        <Typography color="text.primary">
          {device?.settings?.main?.label}
        </Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: "flex",
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: 29,
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Typography
                variant={"h4"}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down("md")]: {
                    minWidth: "100%",
                    marginBottom: 2,
                  },
                }}
              >
                {device?.settings?.main?.label}
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{
                  boxShadow: "none",
                  height: "38px",
                }}
                startIcon={<BrowserUpdatedIcon />}
                disabled={updating}
                onClick={() => {
                  if (formRef.current) {
                    formRef.current.handleSubmit();
                  }
                }}
              >
                {t("buttons.update")}
              </Button>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            flex: 1,
            mr: 2,
          }}
          wrapperSx={{
            p: 1,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          {device && (
            <Formik
              innerRef={formRef}
              validateOnChange={true}
              initialValues={initialValues}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await handleUpdateDevice(data);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form style={{ textAlign: "left" }} noValidate>
                  <MainField handleChange={handleChange} expanded={expanded} />
                  {/* <ColorsField
                    handleChange={handleChange}
                    expanded={expanded}
                  /> */}
                  {/* <ContactsField
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                  <WeatherField
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                  <GalleriesField
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                  <MenusField handleChange={handleChange} expanded={expanded} />
                  <TranslationsField
                    handleChange={handleChange}
                    expanded={expanded}
                  />
                  <MainMenuField
                    handleChange={handleChange}
                    expanded={expanded}
                  /> */}
                </Form>
              )}
            </Formik>
          )}
        </JumboCardQuick>
      </Div>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="info">
          <AlertTitle>{t("messages.unsavedChanges.title")}</AlertTitle>
          {t("messages.unsavedChanges.description")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditDevice;
