import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Button } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const AddPresentationForm = ({ onAddPresentation, onCancel }) => {
  const { t } = useTranslation()
  const PresentationSchema = Yup.object().shape({
    label: Yup.string().required(t('pages.articleCategories.labelRequired'))
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={{ label: '' }}
      validationSchema={PresentationSchema}
      onSubmit={async data => {
        onAddPresentation(data)
      }}
    >
      <Form style={{ textAlign: 'left' }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            required
            name='label'
            label={t('pages.presentation.label')}
          />
        </Div>
        <Div
          sx={{
            mt: 2,
            mb: 1,
            mx: 2,
            gap: '4px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button size='normal' onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button type='submit' variant='contained' size='normal'>
            {t('buttons.ok')}
          </Button>
        </Div>
      </Form>
    </Formik>
  )
}

export default AddPresentationForm
