import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getRemoteControlMenu,
  updateRemoteControlOffer
} from 'app/services/api/menu'

import { useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div/Div'
import Logo from 'app/shared/Logo/Logo'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import RemoteControlProduct from '../menu/RemoteControlProduct'

const localStorageItemName = 'DV_REMOTE_LOGIN'

const RemoteControlOffer = () => {
  const { customerId, url } = useParams()

  const { t } = useTranslation()
  const [menu, setMenu] = useState()
  const [updating, setUpdating] = useState()
  const [credentials, setCredentials] = useState()
  const [loaded, setLoaded] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogin = async (username, password) => {
    const menu = await loadMenu(username, password)
    if (!menu) {
      enqueueSnackbar(t('pages.login.error'), {
        variant: 'error'
      })
      return
    }
    const todayMoment = moment()
    const oneMonthLater = todayMoment.clone().add(1, 'months')
    const credentials = { username, password, expiration: oneMonthLater }
    localStorage.setItem(localStorageItemName, JSON.stringify(credentials))
    setCredentials(credentials)
    setMenu(menu)
    setLoaded(true)
  }

  const loadMenu = async (username, password) => {
    const menu = await getRemoteControlMenu(url, customerId, username, password)
    return menu
  }

  useEffect(() => {
    try {
      const item = JSON.parse(localStorage.getItem(localStorageItemName))
      setCredentials(item)
      if (moment() < moment(item.expiration)) {
        loadMenu(item.username, item.password).then(menu => {
          setMenu(menu)
          setLoaded(true)
        })
      } else {
        setLoaded(true)
      }
    } catch (error) {
      setLoaded(true)
    }
  }, [])

  const handleProductUpdate = async (product, category, type, field, value) => {
    const products = menu?.metadata.products[category]
    const selectedProduct = products.find(
      item => item.number === product.number
    )
    selectedProduct[field] = value
    const newMenu = { ...menu }
    newMenu.metadata.products[category] = products
    setMenu(newMenu)
  }

  const handleMenuUpdate = async () => {
    setUpdating(true)
    try {
      await updateRemoteControlOffer(customerId, menu)
      await loadMenu(credentials.username, credentials.password)
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const Control = () => (
    <>
      <>
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={() => {}}
        >
          {props => (
            <Form style={{ textAlign: 'left', width: '100%' }} noValidate>
              {!menu ? (
                <>
                  <Div sx={{ mt: 3, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name='username'
                      label={t('pages.login.username')}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name='password'
                      label={t('pages.login.password')}
                      type='password'
                    />
                  </Div>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    size='large'
                    sx={{ mb: 3 }}
                    loading={updating}
                    onClick={() =>
                      handleLogin(props.values.username, props.values.password)
                    }
                  >
                    {t('pages.login.signIn')}
                  </Button>
                </>
              ) : (
                <>
                  {Object.keys(menu?.metadata.products || {}).map(category => {
                    const categoryObj = menu.categories[category]
                    const selectedLanguage =
                      (menu.languages && menu.languages[0]?.code) ||
                      menu.languageCode
                    const products = menu.metadata.products[category]
                    const categoryName =
                      categoryObj.metadata.label[selectedLanguage]
                    return (
                      <Div key={category}>
                        <Typography
                          variant='h2'
                          textAlign='center'
                          pb={1}
                          pt={3}
                        >
                          {categoryName}
                        </Typography>
                        {products.map((item, index) => (
                          <RemoteControlProduct
                            item={item}
                            key={index}
                            category={category}
                            type='soup'
                            disabled={updating}
                            onUpdate={handleProductUpdate}
                          />
                        ))}
                      </Div>
                    )
                  })}
                </>
              )}
            </Form>
          )}
        </Formik>
        {menu && (
          <Button
            variant='contained'
            type='button'
            startIcon={<BrowserUpdatedIcon />}
            sx={{
              mt: 2
            }}
            fullWidth
            size='large'
            disabled={updating}
            onClick={handleMenuUpdate}
          >
            {t('buttons.update')}
          </Button>
        )}
      </>
    </>
  )

  const NoCategoryWarning = () => (
    <Typography variant='h3' mt={3}>
      {t('pages.dailyOffers.noCategoryAdded')}
    </Typography>
  )

  return (
    <Div
      sx={{
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Div sx={{ width: 300 }}>
        <Logo />
        {(() => {
          if (!loaded) {
            return <CircularProgress sx={{ m: '5px 50%' }} />
          } else {
            if (!credentials) {
              return <Control />
            }
            if (moment() > moment(credentials.expiration)) {
              return <Control />
            }
            if (Object.keys(menu?.metadata?.products || {}).length) {
              return <Control />
            } else {
              return <NoCategoryWarning />
            }
          }
        })()}
      </Div>
    </Div>
  )
}

export default RemoteControlOffer
