import { useFormikContext } from 'formik'
import React, { useEffect } from 'react'

const FormController = ({ setSettings, setErrors, module }) => {
  const { values, errors, validateForm } = useFormikContext()
  useEffect(() => {
    if (values) {
      setSettings(values)
    }
  }, [values])

  useEffect(() => {
    if (setErrors) {
      validateForm().then(res => {
        setErrors(res)
      })
    }
  }, [errors])

  return null
}

export default FormController
