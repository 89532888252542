import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import GalleryItem from './GalleryItem'
import { useSnackbar } from 'notistack'
import { addGallery, deleteGallery, getGalleries } from 'app/services/api/media'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import AddPresentationForm from 'app/pages/presentation/AddPresentationForm'
import { useNavigate } from 'react-router-dom'

const Galleries = ({ selectorMode, handleSelect }) => {
  const { getAuthUser } = useJumboAuth()
  const { customerId, userId } = getAuthUser()
  const { t } = useTranslation()

  const [galleries, setGalleries] = useState([])
  const [loading, setLoading] = useState(false)
  const { showDialog, hideDialog } = useJumboDialog()
  const { enqueueSnackbar } = useSnackbar()
  const [searchName, setSearchName] = useState()
  const navigate = useNavigate()

  const handleDelete = async url => {
    hideDialog()
    setLoading(true)
    try {
      await deleteGallery(url)
      await loadGalleries()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const loadGalleries = async () => {
    await getGalleries(customerId, userId).then(galleries => {
      const filteredGalleries = searchName
        ? galleries.filter(gallery =>
            gallery.label.toLowerCase().includes(searchName)
          )
        : galleries
      setGalleries(filteredGalleries || [])
    })
    setLoading(false)
  }

  const handleAddGallery = async ({ label }) => {
    hideDialog()
    setLoading(true)
    try {
      const galleryUrl = await addGallery({
        label,
        customerId,
        userId
      })
      await loadGalleries()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/media/gallery/${galleryUrl}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = () => {
    showDialog({
      variant: 'default',
      title: t('pages.galleries.addGallery'),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <AddPresentationForm
          onAddPresentation={handleAddGallery}
          onCancel={hideDialog}
        />
      )
    })
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadGalleries()
  }, [searchName])

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.galleries')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='galleries'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.galleries.addGallery')}>
          <IconButton onClick={openModal}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {galleries?.length ? (
        galleries.map((gallery, index) => (
          <GalleryItem
            key={index}
            item={gallery}
            selectorMode={selectorMode}
            disabled={loading}
            handleSelect={handleSelect}
            handleDelete={() =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDelete(gallery.url),
                onNo: () => hideDialog()
              })
            }
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Galleries
