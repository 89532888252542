import React from 'react'

const TextArrayPreview = ({ module }) => {
  const { parameters_settings } = module.parameters
  let content = ''
  if (parameters_settings?.fields) {
    const field = Object.keys(parameters_settings.fields).find(key => {
      const field = parameters_settings.fields[key]
      return field.type === 'textArea'
    })
    content = module.parameters[field]
  }
  return <div dangerouslySetInnerHTML={{ __html: content }} />
}

export default TextArrayPreview
