import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import {
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  Stack,
  Tooltip,
  Modal
} from '@mui/material'
import {
  getPresentation,
  updatePresentation
} from 'app/services/api/presentation'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import PresentationSettings from './PresentationSettings'
import CircularProgress from '@mui/material/CircularProgress'
import { useFormikContext } from 'formik'
import { FE_URL } from 'app/services/config'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import MonitorIcon from '@mui/icons-material/Monitor'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import { useSnackbar } from 'notistack'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ShareIcon from '@mui/icons-material/Share'
import { PresentationPreview } from './preview/PresentationPreview'
import { addBlock, deleteBlock } from 'app/services/api/block'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import SharedBlocks from './block/SharedBlocks'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  backgroundColor: 'background.default',
  boxShadow: 24,
  p: 2
}

const EditPresentation = () => {
  const { url } = useParams()
  const { t } = useTranslation()
  const { theme } = useJumboTheme()
  const [settings, setSettings] = useState()
  const [unsaved, setUnsaved] = useState(false)
  const [openBlockModal, setOpenBlockModal] = useState(false)
  const [boxUpdater, setBoxUpdater] = useState()
  const [updating, setUpdating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { showDialog, hideDialog } = useJumboDialog()

  const FormikController = () => {
    const { values } = useFormikContext()
    useEffect(() => {
      if (values) {
        setSettings(values)
      }
    }, [values])
    return null
  }

  const [presentation, setPresentation] = useState({})
  const [boxes, setBoxes] = useState({})
  const [addedBlocks, setAddedBlocks] = useState({})
  const [ratios, setRatios] = useState({ widthRatio: 1, heightRatio: 1 })

  const loadPresentation = async () => {
    await getPresentation(url).then(presentation => {
      setPresentation(presentation)
    })
  }

  useEffect(() => {
    loadPresentation()
  }, [])

  useEffect(() => {
    if (settings && presentation) {
      const {
        blocks,
        name,
        resolution,
        presentation_width,
        presentation_height,
        background_color,
        border_color,
        background_image,
        template,
        language
      } = settings
      const newBlocks = {}
      Object.keys(boxes).forEach(key => {
        const { widthRatio, heightRatio } = ratios
        const box = boxes[key]
        newBlocks[key] = {
          ...blocks[key],
          block_settings: {
            ...blocks[key]?.block_settings,
            block_position_x: `${Math.round(box.x / widthRatio)}`,
            block_position_y: `${Math.round(box.y / heightRatio)}`,
            block_width: `${Math.round(box.width / widthRatio)}`,
            block_height: `${Math.round(box.height / heightRatio)}`
          }
        }
      })
      const clonedBlocks = structuredClone(presentation.blocks)
      Object.keys(clonedBlocks).forEach(key => {
        delete clonedBlocks[key].css
        delete clonedBlocks[key].index
      })
      const clonedBoxes = structuredClone(newBlocks)
      Object.keys(clonedBoxes).forEach(key => {
        delete clonedBoxes[key].css
        delete clonedBoxes[key].index
        clonedBoxes[key].block_settings.order =
          1000 - clonedBoxes[key].block_settings.order
      })

      const blocksChanged =
        Object.keys(clonedBlocks).length &&
        JSON.stringify(clonedBlocks) !== JSON.stringify(clonedBoxes)

      const updated = {
        ...presentation,
        blocks,
        name,
        resolution,
        template: template ? 1 : 0,
        language,
        presentation_parameters: {
          ...presentation.presentation_parameters,
          presentation_width,
          presentation_height,
          background_color,
          border_color,
          background_image:
            background_image === 'removed' ? '' : background_image
        }
      }
      delete updated.blocks
      updated.presentation_parameters = Object.keys(
        updated.presentation_parameters
      )
        .sort()
        .reduce((obj, key) => {
          obj[key] = updated.presentation_parameters[key]
          return obj
        }, {})
      const updatedOrdered = Object.keys(updated)
        .sort()
        .reduce((obj, key) => {
          obj[key] = updated[key]
          return obj
        }, {})
      const defaultPresentation = structuredClone(presentation)
      delete defaultPresentation.blocks
      defaultPresentation.presentation_parameters = Object.keys(
        defaultPresentation.presentation_parameters
      )
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultPresentation.presentation_parameters[key]
          return obj
        }, {})
      const updatedDefault = Object.keys(defaultPresentation)
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultPresentation[key]
          return obj
        }, {})
      setUnsaved(
        JSON.stringify(updatedOrdered) !== JSON.stringify(updatedDefault) ||
          blocksChanged
      )
    }
  }, [settings, presentation, boxes])

  const handleAction = (type, ...params) => {
    const types = {
      previewPresentation: handlePreviewPresentation,
      addBlock: handleAddBlock,
      addSharedBlock: handleAddSharedBlock
    }
    types[type] && types[type](...params)
  }

  const handlePreviewPresentation = () => {
    window.open(
      FE_URL + '/presentation/' + presentation.url,
      '_blank',
      'noreferrer'
    )
  }

  const handleAddBlock = async () => {
    await addBlock(url)
    await getPresentation(url).then(updatedPresentation => {
      const newBlockKey = Object.keys(updatedPresentation?.blocks || {}).find(
        block => !boxes[block]
      )
      const newBlock = updatedPresentation?.blocks[newBlockKey]
      setAddedBlocks({ ...addedBlocks, [newBlockKey]: newBlock })
      boxUpdater(newBlock)
    })
  }

  const handleAddSharedBlock = async parentId => {
    setOpenBlockModal(false)
    await addBlock(url, parentId)
    await getPresentation(url).then(updatedPresentation => {
      const newBlockKey = Object.keys(updatedPresentation?.blocks || {}).find(
        block => !boxes[block]
      )
      const newBlock = updatedPresentation?.blocks[newBlockKey]
      setAddedBlocks({ ...addedBlocks, [newBlockKey]: newBlock })
      boxUpdater(newBlock)
    })
  }

  const handlePreviewUpdate = ({ boxes, widthRatio, heightRatio }) => {
    setBoxes(boxes)
    setRatios({ widthRatio, heightRatio })
  }

  const handleDeleteBlock = async blockUrl => {
    hideDialog()
    setUpdating(true)
    try {
      await deleteBlock(blockUrl)
      await loadPresentation()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const handleUpdatePresentation = async () => {
    const {
      blocks,
      name,
      resolution,
      presentation_width,
      presentation_height,
      background_color,
      border_color,
      background_image,
      template,
      language
    } = settings
    setUpdating(true)
    Object.keys(boxes).forEach(key => {
      const { widthRatio, heightRatio } = ratios
      const box = boxes[key]
      const block = blocks[key] || addedBlocks[key]

      block.block_settings.block_position_x = `${Math.round(
        box.x / widthRatio
      )}`
      block.block_settings.block_position_y = `${Math.round(
        box.y / heightRatio
      )}`
      block.block_settings.block_width = `${Math.round(box.width / widthRatio)}`
      block.block_settings.block_height = `${Math.round(
        box.height / heightRatio
      )}`
    })
    const data = {
      data: {
        presentation: {
          ...presentation,
          blocks: { ...blocks, ...addedBlocks },
          name,
          resolution,
          template,
          language,
          presentation_parameters: {
            ...presentation.presentation_parameters,
            presentation_width,
            presentation_height,
            background_color,
            border_color,
            background_image:
              background_image === 'removed' ? '' : background_image
          }
        }
      }
    }
    try {
      await updatePresentation(data)
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      await loadPresentation()
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }

    setUpdating(false)
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <Link to='/'>{t('sidebar.menuItem.home')}</Link>
        <Link to='/presentations'>{t('sidebar.menuItem.presentations')}</Link>
        <Typography color='text.primary'>{presentation?.name}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: 'flex'
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 29,
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                {presentation?.name}
              </Typography>
              <Stack direction='row'>
                {!presentation?.sourceId && (
                  <>
                    <Tooltip title={t('pages.presentation.addBlock')}>
                      <IconButton onClick={() => handleAction('addBlock')}>
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('pages.presentation.addSharedBlock')}>
                      <IconButton onClick={() => setOpenBlockModal(true)}>
                        <ShareIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}

                <Tooltip title={t('pages.presentation.previewPresentation')}>
                  <IconButton
                    onClick={() => handleAction('previewPresentation')}
                  >
                    <MonitorIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            flex: 1,
            mr: 2
          }}
          wrapperSx={{
            p: 1,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          {presentation && Object.values(presentation).length ? (
            <PresentationPreview
              role='container'
              presentation={presentation}
              borderColor={settings?.border_color}
              backgroundColor={settings?.background_color}
              backgroundImage={settings?.background_image}
              onChange={handlePreviewUpdate}
              setBoxUpdater={setBoxUpdater}
            />
          ) : (
            <CircularProgress sx={{ m: '5px 50%' }} />
          )}
        </JumboCardQuick>
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                <Div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Link
                    to={`/presentations`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: '100%'
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: unsaved && '#F39711'
                    }}
                    startIcon={<BrowserUpdatedIcon />}
                    disabled={updating}
                    onClick={handleUpdatePresentation}
                  >
                    {t('buttons.update')}
                  </Button>
                </Div>
              </Typography>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            maxWidth: 300,
            flex: 1
          }}
          wrapperSx={{
            p: 0,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          {presentation && Object.values(presentation).length ? (
            <PresentationSettings
              presentation={presentation}
              addedBlocks={addedBlocks}
              onDeleteBlock={blockUrl =>
                showDialog({
                  variant: 'confirm',
                  title: t('widgets.confirmDialog.areYouSure'),
                  onYes: () => handleDeleteBlock(blockUrl),
                  onNo: () => hideDialog()
                })
              }
            >
              <FormikController />
            </PresentationSettings>
          ) : (
            <CircularProgress sx={{ m: '5px 50%' }} />
          )}
        </JumboCardQuick>
      </Div>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity='info'>
          <AlertTitle>{t('messages.unsavedChanges.title')}</AlertTitle>
          {t('messages.unsavedChanges.description')}
        </Alert>
      </Snackbar>
      <Modal
        open={openBlockModal}
        onClose={() => setOpenBlockModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Div sx={modalStyle}>
          <SharedBlocks
            selectorMode
            onAdd={parentId => handleAction('addSharedBlock', parentId)}
            sx={{ maxHeight: '90vh', overflowY: 'scroll', p: 2 }}
          />
        </Div>
      </Modal>
    </>
  )
}

export default EditPresentation
