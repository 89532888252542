import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  FormControl,
  Grid,
  MenuItem,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import Div from '@jumbo/shared/Div/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { useFormikContext } from 'formik'
import { ColorField, Label } from 'app/pages/presentation/module/form/types'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const FIELD_NAME = 'COLORS_FIELD'

const COLORS = [
  'main',
  'menuBackground',
  'mainScreenBackground',
  'icons.background',
  'icons.font',
  'icons.active.background',
  'icons.active.font',
  'icons.weather.icon',
  'icons.weather.temperature',
  'icons.social.background'
]

export const getColorsFieldSchema = () => {
  const schema = {}
  COLORS.forEach(color => {
    if (color.includes('.')) {
      const names = color.split('.')
      let root = schema
      names.forEach((name, index) => {
        if (!root[name]) {
          root[name] = index == names.length - 1 ? '' : {}
        }
        root = root[name]
      })
    } else {
      schema[color] = ''
    }
  })
  return schema
}

const ColorsField = ({ handleChange, expanded }) => {
  const { t, i18n } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const {
    customer: { kioskSettings }
  } = getAuthUser()
  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.editDevice.colors')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {COLORS.map(color => (
            <Grid item xs={12} md={4} key={color}>
              <ColorField
                name={`colors.${color}`}
                lang={i18n.language}
                parameters={{
                  label: {
                    [i18n.language]: t(`pages.editDevice.colorLabels.${color}`)
                  }
                }}
              />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ColorsField
