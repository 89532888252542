import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack,
  Chip
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import CachedIcon from '@mui/icons-material/Cached'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const ScreenItem = ({
  item,
  handleCopy,
  handleRefresh,
  handleDelete,
  handlePreview,
  disabled
}) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const templateStyle = {
    marginRight: '2%'
  }

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.presentation.id')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{item.id}</Typography>
              {item.sourceId && (
                <Tooltip
                  title={t('pages.presentation.basedOnTemplate')}
                  placement='top'
                >
                  <AutoStoriesIcon fontSize='small' sx={{ color: '#475259' }} />
                </Tooltip>
              )}
              {item.template === 1 && (
                <Tooltip
                  title={t('pages.presentation.template')}
                  placement='top'
                >
                  <Chip
                    style={templateStyle}
                    label={'Template'}
                    color='warning'
                    size={'small'}
                  />
                </Tooltip>
              )}
            </Stack>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.label')}
            </Typography>
            <Typography variant={'body1'}>
              <Link
                onClick={() => {
                  navigate(`/screen/${item.url}`)
                }}
              >
                {item.label}
              </Link>
            </Typography>
          </Div>
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '14%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.resolution')}
            </Typography>
            <Typography variant={'body1'}>
              {item.height && item.width && `${item.width}x${item.height}`}
            </Typography>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.screens.livePresentation')}
            </Typography>
            <Typography variant={'body1'}>{item.presentation}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              px: 1,
              maxWidth: { sm: 260, xs: 80 },
              minWidth: { sm: 200, xs: 80 }
            }}
            // sx={{
            //   flex: { xs: '0 2 auto', md: 1 },
            //   px: 1,
            //   maxWidth: { sm: 260, xs: 80 },
            //   minWidth: { sm: 160, xs: 80 }
            // }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 70
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => navigate(`/screen/${item.url}`)}
                >
                  <Tooltip title={t('buttons.edit')} placement='top-start'>
                    <EditIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => navigate(`/screens/${item.url}/scheduler`)}
                >
                  <Tooltip title={t('buttons.scheduler')} placement='top-start'>
                    <HistoryToggleOffIcon />
                  </Tooltip>
                </IconButton>
              </Div>
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => handlePreview(item.url)}
                >
                  <Tooltip title={t('buttons.preview')} placement='top-start'>
                    <VisibilityIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => handleRefresh(item.url)}
                >
                  <Tooltip title={t('buttons.refresh')} placement='top-start'>
                    <CachedIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => handleDelete(item.url)}
                >
                  <Tooltip title={t('buttons.delete')} placement='top-start'>
                    <DeleteForeverIcon />
                  </Tooltip>
                </IconButton>
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default ScreenItem
