import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  TextField,
  FormControlLabel,
  Switch,
  Link,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import DragHandleIcon from "@mui/icons-material/DragHandle";

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: "grab", verticalAlign: "middle" }} />
));

const ModuleItem = ({
  item,
  presentationUrl,
  blockUrl,
  handleUpdate,
  handleDelete,
  disabled,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              justifyContent: "space-between",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              color: "text.secondary",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <DragHandle />
          <Div
            sx={{
              display: { xs: "none", md: "block" },
              width: "40px",
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              {t("pages.presentation.id")}
            </Typography>
            <Typography variant={"body1"}>{item.id}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: 2 },
              minWidth: "100px",
              maxWidth: "150px",
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
            >
              {t("pages.blocks.moduleName")}
            </Typography>
            <Typography variant={"body1"}>
              <Link
                onClick={() =>
                  navigate(
                    `/presentation/${presentationUrl}/block/${blockUrl}/module/${item.url}`
                  )
                }
              >
                {item.moduleName}
              </Link>
            </Typography>
          </Div>
          <Div
            sx={{
              width: "250px",
              flexShrink: 0,
            }}
          >
            <Typography variant={"body1"}>
              <TextField
                fullWidth
                sx={{ pr: 1 }}
                name="label"
                value={item.label || ""}
                onChange={(e) =>
                  handleUpdate({ ...item, label: e.target.value })
                }
                label={t("pages.presentation.label")}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Typography>
          </Div>
          <Div
            sx={{
              display: { xs: "none", md: "block" },
              width: "130px",
              flexShrink: 0,
              pr: 1,
            }}
          >
            <TextField
              id="time"
              label={t("pages.blocks.duration")}
              type="time"
              name="duration"
              value={item.duration || ""}
              inputProps={{
                step: 1,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                handleUpdate({ ...item, duration: e.target.value })
              }
              sx={{ width: "100%" }}
            />
          </Div>
          <Div
            sx={{
              display: { xs: "none", md: "block" },
              width: "75px",
              flexShrink: 0,
              px: 1,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.blocks.hideShow")}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) =>
                    handleUpdate({
                      ...item,
                      statusId: e.target.checked ? 4 : 5,
                    })
                  }
                />
              }
              checked={item["statusId"] === 4}
            />
          </Div>
          <Div
            sx={{
              px: 1,
              width: "80px",
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.presentation.actions")}
            </Typography>
            <Div
              sx={{
                display: "flex",
                flex: { direction: "row", flexWrap: "wrap" },
                minWidth: 70,
              }}
            >
              <Div>
                <IconButton
                  size="small"
                  variant={"contained"}
                  disabled={disabled}
                  onClick={() =>
                    navigate(
                      `/presentation/${presentationUrl}/block/${blockUrl}/module/${item.url}`
                    )
                  }
                >
                  <Tooltip title={t("buttons.edit")} placement="top-start">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
              </Div>
              <Div>
                <IconButton
                  size="small"
                  variant={"contained"}
                  disabled={disabled}
                  onClick={() => handleDelete(item.url)}
                >
                  <Tooltip title={t("buttons.delete")} placement="top-start">
                    <DeleteForeverIcon />
                  </Tooltip>
                </IconButton>
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
export default SortableElement(ModuleItem);
