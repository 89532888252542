import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Box,
  Pagination
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { addArticleCategory, editArticleCategory } from 'app/services/api/kiosk'
import CustomerItem from './CustomerItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CustomerForm from './CustomerForm'
import { getCustomer, getCustomers } from 'app/services/api/management'
import authServices from 'app/services/auth-services'
import { useNavigate } from 'react-router-dom'

const ITEM_PER_PAGE = 10

const Customers = () => {
  const { getAuthUser, setAuthToken } = useJumboAuth()
  const { customerId } = getAuthUser() || {}
  const { t } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadCustomers = async () => {
    await getCustomers(customerId).then(customers => {
      const customerData = customers.map(customer => ({
        ...customer.customerData,
        users: customer.users
      }))
      const filteredCustomers = searchName
        ? customerData.filter(customer =>
            customer.companyName.toLowerCase().includes(searchName)
          )
        : customerData
      setCustomers(filteredCustomers || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadCustomers()
  }, [searchName])

  const openModal = (type, customer) => {
    showDialog({
      variant: 'default',
      title: t(`pages.customers.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <CustomerForm
          customer={customer}
          onAddCustomer={handleAddCustomer}
          onEditCustomer={handleEditCustomer}
          onCancel={hideDialog}
        />
      )
    })
  }

  const handleLoginAsCustomer = async customer => {
    setLoading(true)
    try {
      const user = customer.users[0]
      const data = await authServices.signIn({
        username: user.username,
        password: user.password,
        type: 'hash'
      })
      const message = `${t('buttons.loginAsCustomer')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      setAuthToken(data, true)
      if (data?.data?.customer?.url) {
        await getCustomer(data?.data?.customer?.url) // Load customer data to fetch DSOK BE Domain
      }
      navigate('/')
      setLoading(false)
    } catch (error) {
      const message = `${t('buttons.loginAsCustomer')} ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleAddCustomer = async customer => {
    setLoading(true)
    hideDialog()
    try {
      await addArticleCategory(customer)
      await loadCustomers()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditCustomer = async customer => {
    setLoading(true)
    hideDialog()
    try {
      await editArticleCategory(customer)
      await loadCustomers()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  // const deleteConfirmation = category => {
  //   showDialog({
  //     variant: 'confirm',
  //     title: t('widgets.confirmDialog.areYouSure'),
  //     onYes: () => handleDelete(category),
  //     onNo: () => hideDialog()
  //   })
  // }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.customers')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        mb={2}
        spacing={1}
      >
        <Box width='50%' display='flex' flexDirection='row' gap={1}>
          <Card sx={{ mb: 0, width: '100%' }}>
            <JumboSearch
              page='customers'
              onChange={setSearchName}
              sx={{
                width: '100%'
              }}
              placeholder={t('pages.presentation.searchPresentation')}
            />
          </Card>
          <Tooltip title={t('pages.customer.addCustomer')}>
            <IconButton disabled onClick={() => openModal('addCustomer')}>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Pagination
          count={Math.ceil(customers?.length / ITEM_PER_PAGE)}
          color='primary'
          page={page}
          onChange={(_, val) => setPage(val)}
        />
      </Stack>
      {customers?.length ? (
        customers.map((customer, index) =>
          index + 1 > ITEM_PER_PAGE * (page - 1) &&
          index + 1 <= ITEM_PER_PAGE * page ? (
            <CustomerItem
              key={index}
              item={customer}
              disabled={loading}
              onLogin={handleLoginAsCustomer}
              onEdit={() => openModal('editCustomer', customer)}
            />
          ) : null
        )
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Customers
