import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Stack,
  Tab,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import Div from '@jumbo/shared/Div/Div'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'

const FIELD_NAME = 'MAIN_MENU_FIELD'

const menuFields = [
  {
    title: '',
    icon: 'home',
    pageKey: 'home'
  },
  {
    title: '',
    icon: 'services',
    pageKey: 'services'
  },
  {
    title: '',
    icon: 'gallery',
    pageKey: 'galleries'
  },
  {
    title: '',
    icon: 'weather',
    pageKey: 'weather'
  },
  {
    title: '',
    icon: 'map',
    pageKey: 'map'
  },
  {
    title: '',
    icon: 'restaurant',
    pageKey: 'menu'
  },
  {
    title: '',
    icon: 'contact',
    pageKey: 'contact'
  },
  {
    title: '',
    icon: 'news',
    pageKey: 'news'
  }
]

export const getMainMenuFieldSchema = kioskLanguages => {
  const schema = {}
  if (!kioskLanguages?.length) {
    return schema
  }
  kioskLanguages.forEach(language => {
    schema[language.code] = [...menuFields]
  })
  return schema
}

const MainMenuField = ({ handleChange, expanded }) => {
  const { t } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const {
    customer: { kioskSettings }
  } = getAuthUser()

  const [selectedLanguage, setSelectedLanguage] = useState(
    kioskSettings.languages?.[0].code
  )

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.editDevice.mainMenu')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!kioskSettings.languages?.length ? (
          <Typography
            variant='caption'
            color='text.secondary'
            sx={{
              margin: '0 0 0 10px',
              background: 'white',
              color: 'text.secondary',
              width: 'fit-content'
            }}
          >
            {t('pages.editDevice.noKioskLanguage')}
          </Typography>
        ) : (
          selectedLanguage && (
            <TabContext value={selectedLanguage}>
              <Stack sx={{ mb: 2 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}
                >
                  <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                    {kioskSettings.languages.map(({ code, label }) => {
                      return <Tab key={code} label={label} value={code} />
                    })}
                  </TabList>
                </Box>
                {kioskSettings.languages.map(({ code, label }) => {
                  return (
                    <TabPanel
                      key={code}
                      value={code}
                      sx={{ pb: 1, pr: 0, minHeight: 350 }}
                    >
                      <Grid container spacing={2}>
                        {menuFields.map(({ pageKey }, index) => (
                          <Grid item xs={12} md={4} key={pageKey}>
                            <Div sx={{ mb: 1, mx: 0 }}>
                              <JumboTextField
                                fullWidth
                                name={`menu.${code}[${index}].title`}
                                label={t(`pages.editDevice.${pageKey}`)}
                              />
                            </Div>
                          </Grid>
                        ))}
                      </Grid>
                    </TabPanel>
                  )
                })}
              </Stack>
            </TabContext>
          )
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default MainMenuField
