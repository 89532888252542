import React, { useEffect, useRef, useState } from 'react'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Typography,
  Grid,
  MenuItem,
  TextField,
  IconButton,
  CircularProgress
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getCustomer,
  getDSOKOrders,
  getDSOKProducts
} from 'app/services/api/management'
import { DSOK_REPORTS_TYPES, MONTHS } from 'app/utils/constants/settings'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ReportTable from './ReportTable'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from '@mui/icons-material/Print'

const defaultFilter = {
  type: 'daily',
  day: new Date(),
  month: MONTHS[new Date().getMonth()],
  year: `${new Date().getFullYear()}`
}

const DSOKReports = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customer: { url }
  } = getAuthUser()

  const { t, i18n } = useTranslation()
  const tableRef = useRef()

  const [products, setProducts] = useState([])
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState()
  const [enableTablePagination, setEnableTablePagination] = useState(true)
  const [reportDate, setReportDate] = useState('')
  const [filter, setFilter] = useState(defaultFilter)
  const customerUrl = url || ''
  const defaultLanguage =
    customer?.dsokSettings?.customer?.defaultLanguage || i18n.language
  const currencyCode = customer?.dsokSettings?.customer?.currencyCode

  const loadCustomer = async () => {
    return await getCustomer(customerUrl).then(customer => {
      setCustomer(customer)
      return customer
    })
  }

  const handlePrint = useReactToPrint({
    content: () => {
      return tableRef.current
    }
  })

  function sameDay (d1, d2) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    )
  }
  function sameMonth (month, d2) {
    const d1 = new Date()
    d1.setMonth(month)
    d1.setFullYear(filter.year)
    return (
      d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth()
    )
  }

  const loadOrders = async () => {
    const orders = await getDSOKOrders().then(orders => {
      const orderArr = Object.keys(orders || {})
        .filter(id => {
          if (orders[id].isDeleted) {
            return false
          }
          if (filter.type === 'daily') {
            return sameDay(filter.day, new Date(orders[id].createdAt))
          }
          if (filter.type === 'monthly') {
            return sameMonth(
              MONTHS.findIndex(month => month === filter.month.toLowerCase()),
              new Date(orders[id].createdAt)
            )
          }
        })
        .map(id => ({
          ...orders[id]
        }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      return orderArr || []
    })
    return orders
  }

  const loadProducts = async orders => {
    await getDSOKProducts().then(products => {
      const productArr = products
        .map(product => {
          let totalWithoutVAT = 0
          let VAT = 0
          let totalWithVAT = 0
          const productOrders = orders.filter(order => {
            if (
              Object.values(order.products || {}).find(
                orderProduct =>
                  orderProduct.customId === product.customProductId
              ) &&
              (order.orderStatus === 'complete' || order.orderStatus === 'paid')
            ) {
              totalWithoutVAT += +order.grandTotalWithoutVat
              VAT += +order.vatTotal
              totalWithVAT += +order.grandTotal
              return true
            }
            return false
          })
          return {
            ...product,
            numberOfOrders: productOrders.length,
            totalWithoutVAT,
            VAT,
            totalWithVAT
          }
        })
        .filter(product => product.numberOfOrders > 0)

      setProducts(productArr || [])
    })
  }

  const loadDevices = async (orders, customer) => {
    const devices = {}
    Object.values(customer?.dsokSettings?.customer?.devices || {}).forEach(
      device => (devices[device.uuid] = device)
    )
    const deviceList = Object.keys(devices || {})
      .map(deviceId => {
        const device = devices[deviceId]
        let totalWithoutVAT = 0
        let VAT = 0
        let totalWithVAT = 0
        const deviceOrders = orders.filter(order => {
          if (
            order.kioskUUID === deviceId &&
            (order.orderStatus === 'complete' || order.orderStatus === 'paid')
          ) {
            totalWithoutVAT += +order.grandTotalWithoutVat
            VAT += +order.vatTotal
            totalWithVAT += +order.grandTotal
            return true
          }
          return false
        })

        return {
          ...device,
          numberOfOrders: deviceOrders.length,
          totalWithoutVAT,
          VAT,
          totalWithVAT
        }
      })
      .filter(device => device.numberOfOrders > 0)
    setDevices(deviceList)
  }

  const loadData = async () => {
    setLoading(true)
    const customer = await loadCustomer()
    const orders = await loadOrders()
    await loadProducts(orders)
    await loadDevices(orders, customer)
    setLoading(false)
  }

  const updateFilter = newFilter => {
    setFilter({ ...filter, ...newFilter })
  }

  useEffect(() => {
    loadData()
  }, [filter])

  useEffect(() => {
    updateFilter({ day: defaultFilter.day, month: defaultFilter.month })
  }, [filter.type])

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.dsokReports')}
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} mb={2}>
        <Grid item md={12} xs={12}>
          <JumboCardQuick
            title={
              <Grid container columnSpacing={2} rowGap={2} mb={2}>
                <Grid item md={3} xs={12}>
                  <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                    <TextField
                      select
                      sx={{ width: '100%' }}
                      name={`menuType`}
                      value={filter.type}
                      onChange={e => updateFilter({ type: e.target.value })}
                      label={t('pages.dsokReports.reportType')}
                    >
                      {DSOK_REPORTS_TYPES.map((type, index) => {
                        return (
                          <MenuItem key={index} value={type}>
                            {t(`pages.dsokReports.${type}`)}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  </Div>
                </Grid>
                {filter.type === 'daily' && (
                  <Grid item md={3} xs={12}>
                    <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t('pages.dsokReports.day')}
                          sx={{ width: '100%' }}
                          format='dd/MM/yy'
                          slotProps={{
                            textField: {
                              InputLabelProps: { shrink: true },
                              placeholder: ''
                            }
                          }}
                          onChange={newValue => updateFilter({ day: newValue })}
                          value={filter.day}
                        />
                      </LocalizationProvider>
                    </Div>
                  </Grid>
                )}
                {filter.type === 'monthly' && (
                  <>
                    <Grid item md={3} xs={12}>
                      <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            sx={{ width: '100%' }}
                            label={t('pages.dsokReports.year')}
                            openTo='year'
                            views={['year']}
                            value={new Date(filter.year)}
                            onChange={e =>
                              updateFilter({ year: `${e.getFullYear()}` })
                            }
                          />
                        </LocalizationProvider>
                      </Div>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                        <TextField
                          select
                          sx={{ width: '100%' }}
                          name={`menuType`}
                          value={filter.month}
                          onChange={e =>
                            updateFilter({ month: e.target.value })
                          }
                          label={t('pages.dsokReports.month')}
                        >
                          {MONTHS.map((month, index) => {
                            return (
                              <MenuItem key={index} value={month}>
                                {t(`months.${month}`)}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      </Div>
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  md={filter.type === 'daily' ? 6 : 3}
                  xs={12}
                  display='flex'
                  justifyContent='flex-end'
                >
                  <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                    <IconButton
                      onClick={async () => {
                        setEnableTablePagination(false)
                        const monthlyDate = `${t(`months.${filter.month}`)} ${
                          filter.year
                        }`
                        const dateString =
                          filter.type === 'daily'
                            ? filter.day.toLocaleDateString()
                            : monthlyDate
                        setReportDate(dateString)
                        await new Promise(resolve => {
                          setTimeout(() => {
                            resolve()
                          }, 500)
                        })
                        handlePrint()
                        setEnableTablePagination(true)
                        setReportDate('')
                      }}
                    >
                      <PrintIcon fontSize='large' />
                    </IconButton>
                  </Div>
                </Grid>
              </Grid>
            }
            headerSx={{
              borderBottom: 1,
              borderBottomColor: 'divider',
              '& .MuiCardHeader-action': {
                my: -0.75
              }
            }}
            wrapperSx={{
              p: 0,
              '&:last-child': {
                pb: 2
              },
              '& .MuiCollapse-entered:last-child': {
                '& .MuiListItemButton-root': {
                  borderBottom: 0,
                  borderBottomColor: 'transparent'
                }
              }
            }}
          >
            {loading ? (
              <Div
                sx={{
                  width: '100%',
                  height: 100,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress />
              </Div>
            ) : (
              <ReportTable
                products={products}
                devices={devices}
                reportType={filter.type}
                currencyCode={currencyCode}
                defaultLanguage={defaultLanguage}
                enableTablePagination={enableTablePagination}
                reportDate={reportDate}
                ref={tableRef}
              />
            )}
          </JumboCardQuick>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default DSOKReports
