import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import {
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import Images from '../media/images/Images'
import { PROD_URL } from 'app/services/config'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { getMenu, getMenus, editMenu } from 'app/services/api/menu'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { Field, Form, Formik } from 'formik'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import { getProducts } from 'app/services/api/product'
import ProductItems from './ProductItems'
import { arrayMove } from 'react-sortable-hoc'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import DailyOrderCategoryForm from './DailyOfferCategoryForm'
import { editDailyOffer, getDailyOffer } from 'app/services/api/dailyOffer'
import JumboListNoDataPlaceholder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ClearIcon from '@mui/icons-material/Clear'

const username = 'menu'
const password = 'menu'

const EditDailyOffer = () => {
  const { url } = useParams()
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { theme } = useJumboTheme()
  const [updating, setUpdating] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [offer, setOffer] = useState()
  const [allCategories, setAllCategories] = useState({})
  const [productCategories, setProductCategories] = useState([])
  const [products, setProducts] = useState({})
  const [selectedProducts, setSelectedProducts] = useState({})
  const [showPassword, setShowPassword] = useState(false)
  const { showDialog, hideDialog } = useJumboDialog()
  const formRef = useRef()
  const selectedLanguage =
    (productLanguages && productLanguages[0]?.code) || i18n.language

  const handleSortEnd = (day, type, oldIndex, newIndex) => {
    const newArr = arrayMove(selectedProducts[day], oldIndex, newIndex)
    setSelectedProducts({
      ...selectedProducts,
      [day]: newArr.map((product, index) => ({ ...product, number: index + 1 }))
    })
    // setBlockList(arrayMove(blockList, oldIndex, newIndex))
  }
  const handleProductUpdate = (product, day, type, field, value) => {
    const newProducts = { ...selectedProducts }
    const selectedProduct = selectedProducts[day].find(
      item => item.number === product.number
    )
    selectedProduct[field] = value
    setSelectedProducts(newProducts)
  }

  const handleAddProduct = (productId, day) => {
    const product = products[day].find(
      item => `${item.id}` === `${productId}`
    ).metadata
    if (
      selectedProducts[`${day}`]?.find(
        item => item.name[selectedLanguage] === product.name[selectedLanguage]
      )
    ) {
      enqueueSnackbar(t('pages.menus.productIsAlreadyAdded'), {
        variant: 'warning'
      })
      return
    }
    // const newProducts = { ...selectedProducts }
    const number = selectedProducts[day].length + 1
    setSelectedProducts({
      ...selectedProducts,
      [day]: [
        ...selectedProducts[day],
        { ...product, number, label: product.name[selectedLanguage] }
      ]
    })
  }

  const handleDeleteProduct = (product, category) => {
    const newProducts = {
      ...selectedProducts,
      [category]: selectedProducts[category]
        .filter(item => item.number !== product.number)
        .map((product, index) => ({ ...product, number: index + 1 }))
    }
    setSelectedProducts(newProducts)
  }

  const handleDeleteCategory = category => {
    setProductCategories(productCategories.filter(item => item !== category))
    const products = { ...selectedProducts }
    delete products[category]
    setSelectedProducts(products)
    hideDialog()
    setSelectedCategory(productCategories[0])
  }

  const handleAddCategory = () => {
    showDialog({
      variant: 'default',
      title: t('pages.dailyOffers.selectCategory'),
      content: (
        <DailyOrderCategoryForm
          categories={allCategories}
          onAddCategory={({ category }) => {
            if (!productCategories.find(item => item === `${category}`)) {
              setProductCategories([...productCategories, `${category}`])
              setSelectedProducts({
                ...selectedProducts,
                [category]: []
              })
              setSelectedCategory(`${category}`)
            } else {
              enqueueSnackbar(t('pages.dailyOffers.existingCategory'), {
                variant: 'warning'
              })
            }
            hideDialog()
          }}
          onCancel={hideDialog}
          productLanguages={productLanguages}
        />
      )
    })
  }

  const { enqueueSnackbar } = useSnackbar()

  const loadOffer = async () => {
    const menuPromise = getDailyOffer(url, customerId).then(response => {
      const offer = {
        ...response,
        allProducts: []
      }
      const productCategories = Object.keys(offer.metadata?.products || {})
      const products = {}
      productCategories.forEach(category => {
        products[category] =
          (offer.metadata && offer.metadata?.products[category]) || []
      })
      setSelectedProducts(products)
      setOffer(offer)
      setProductCategories(productCategories)
      if (productCategories?.length) {
        setSelectedCategory(`${productCategories[0]}`)
      }
    })
    const productsPromise = getProducts(customerId).then(response => {
      const { products, categories } = response || {}
      const productsWithCategories = {}
      Object.keys(products || {}).forEach(id => {
        const product = { ...products[id], id }
        const categories = product.metadata.category.split(',')
        categories.forEach(category => {
          if (!productsWithCategories[`${category}`]) {
            productsWithCategories[`${category}`] = []
          }
          productsWithCategories[`${category}`].push(product)
        })
      })
      setProducts(productsWithCategories)
      setAllCategories(categories)
    })
    await Promise.all([menuPromise, productsPromise])
  }

  useEffect(() => {
    loadOffer()
  }, [url])

  const handleUpdateDailyOffer = async offer => {
    setUpdating(true)
    const values = { ...offer }
    delete values.allProducts
    if (!values.metadata) {
    }
    values.metadata = { ...values.metadata, products: selectedProducts }
    try {
      await editDailyOffer(customerId, values)
      await loadOffer()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <Link to='/'>{t('sidebar.menuItem.home')}</Link>
        <Link to='/daily-offers'>{t('sidebar.menuItem.dailyOffers')}</Link>
        <Typography color='text.primary'>{offer?.label || ''}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: 'flex'
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Div
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <Typography
                  variant={'h4'}
                  mb={0}
                  sx={{
                    minWidth: 245,
                    [theme.breakpoints.down('md')]: {
                      minWidth: '100%',
                      marginBottom: 2
                    }
                  }}
                >
                  {offer?.label || ''}
                </Typography>
                <Stack direction='row' spacing={2}>
                  <Link to={`/menus`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: '100%'
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    startIcon={<BrowserUpdatedIcon />}
                    sx={{
                      boxShadow: 'none'
                    }}
                    size='small'
                    disabled={updating}
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.handleSubmit()
                      }
                    }}
                  >
                    {t('buttons.update')}
                  </Button>
                </Stack>
              </Div>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            width: '100%'
          }}
          wrapperSx={{
            p: 0,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          <>
            <Stack
              direction={'row'}
              spacing={2}
              display='flex'
              sx={{ mb: 2, width: '100%' }}
              alignItems='center'
            >
              {offer && (
                <Formik
                  // validateOnChange={true}
                  innerRef={formRef}
                  initialValues={offer}
                  // validationSchema={ArticleCategorySchema}
                  onSubmit={async data => {
                    handleUpdateDailyOffer(data)
                  }}
                >
                  {props => (
                    <Form
                      style={{ textAlign: 'left', width: '100%' }}
                      noValidate
                    >
                      <Div
                        sx={{ mt: 2, mb: 1, mx: 2, display: 'flex', gap: 1 }}
                      >
                        <FormControlLabel
                          control={
                            <Field
                              name={'active'}
                              component={Switch}
                              onChange={e =>
                                props.setFieldValue('active', e.target.checked)
                              }
                            />
                          }
                          label={t('pages.products.active')}
                          checked={props.values.active}
                        />
                        <FormControlLabel
                          control={
                            <Field
                              name={'displayPrice'}
                              component={Switch}
                              onChange={e =>
                                props.setFieldValue(
                                  'displayPrice',
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={t('pages.menus.displayPrice')}
                          checked={props.values.displayPrice}
                        />
                        <Link
                          to={`/daily-offer/remote-control/${customerId}/${url}`}
                          style={{
                            textDecoration: 'none',
                            alignSelf: 'center'
                          }}
                          target='_blank'
                        >
                          <Button
                            variant='contained'
                            startIcon={<SettingsRemoteIcon />}
                            sx={{
                              boxShadow: 'none',
                              height: 36
                            }}
                            size='small'
                            disabled={updating}
                          >
                            {t('pages.menus.remoteControl')}
                          </Button>
                        </Link>

                        <JumboTextField
                          value={username}
                          defaultValue={username}
                          name={`password`}
                          label={t('pages.login.username')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <AccountCircleIcon />
                              </InputAdornment>
                            ),
                            readOnly: true
                          }}
                        />
                        <JumboTextField
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          defaultValue={password}
                          name={`password`}
                          label={t('pages.login.password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge='end'
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            readOnly: true
                          }}
                        />
                      </Div>
                      <Stack
                        direction='row'
                        alignItems='center'
                        width='100%'
                        spacing={1}
                        px={2}
                      >
                        <TabContext value={selectedCategory}>
                          <Stack width='100%'>
                            <Box
                              sx={{
                                borderBottom: 1,
                                borderColor: 'divider'
                              }}
                              display='flex'
                              flexDirection='row'
                              justifyContent='space-between'
                            >
                              <TabList
                                onChange={(_, val) => setSelectedCategory(val)}
                              >
                                {productCategories.map(item => {
                                  const category = allCategories[item]
                                  return (
                                    <Tab
                                      key={item}
                                      label={
                                        <Stack
                                          direction='row'
                                          sx={{ alignItems: 'center' }}
                                        >
                                          {category?.metadata?.label[
                                            selectedLanguage
                                          ] || item}
                                          <IconButton
                                            onClick={() =>
                                              showDialog({
                                                variant: 'confirm',
                                                title: t(
                                                  'widgets.confirmDialog.areYouSure'
                                                ),
                                                onYes: () =>
                                                  handleDeleteCategory(item),
                                                onNo: () => hideDialog()
                                              })
                                            }
                                          >
                                            <ClearIcon fontSize='small' />
                                          </IconButton>
                                        </Stack>
                                      }
                                      value={item}
                                    />
                                  )
                                })}
                              </TabList>
                              <IconButton onClick={handleAddCategory}>
                                <AddCircleIcon fontSize='small' />
                              </IconButton>
                            </Box>
                            {!productCategories?.length ? (
                              <JumboListNoDataPlaceholder />
                            ) : (
                              productCategories.map(category => {
                                return (
                                  <TabPanel
                                    key={category}
                                    value={category}
                                    sx={{
                                      pb: 1,
                                      pr: 0,
                                      pl: 0
                                    }}
                                  >
                                    <Stack display='flex' direction='column'>
                                      <FormControl
                                        sx={{
                                          mt: 1,
                                          mb: 1,
                                          mx: 0,
                                          pl: 0,
                                          pr: 0,
                                          width: '35%',
                                          minWidth: '280px'
                                        }}
                                      >
                                        <Autocomplete
                                          options={products[category] || []}
                                          autoHighlight
                                          getOptionLabel={option =>
                                            option.metadata?.name[
                                              selectedLanguage
                                            ] || option.id
                                          }
                                          value={null}
                                          blurOnSelect={true}
                                          onChange={e =>
                                            handleAddProduct(
                                              e.target.value,
                                              category
                                            )
                                          }
                                          renderOption={(props, option) => (
                                            <Box
                                              component='li'
                                              sx={{
                                                '& > img': {
                                                  mr: 2,
                                                  flexShrink: 0
                                                }
                                              }}
                                              value={option.id}
                                              {...props}
                                            >
                                              {option.metadata?.name[
                                                selectedLanguage
                                              ] || option.id}
                                            </Box>
                                          )}
                                          renderInput={params => (
                                            <TextField
                                              {...params}
                                              label={t(
                                                'pages.dailyOffers.selectProducts'
                                              )}
                                              inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password' // disable autocomplete and autofill
                                              }}
                                            />
                                          )}
                                        />
                                      </FormControl>
                                      <Field
                                        component={ProductItems}
                                        products={selectedProducts[category]}
                                        onSortEnd={({ oldIndex, newIndex }) =>
                                          handleSortEnd(
                                            category,
                                            'product',
                                            oldIndex,
                                            newIndex
                                          )
                                        }
                                        type='product'
                                        category={category}
                                        onUpdate={handleProductUpdate}
                                        onDelete={handleDeleteProduct}
                                        useDragHandle={true}
                                      />
                                    </Stack>
                                  </TabPanel>
                                )
                              })
                            )}
                          </Stack>
                        </TabContext>
                      </Stack>
                    </Form>
                  )}
                </Formik>
              )}
            </Stack>
          </>
        </JumboCardQuick>
      </Div>
    </>
  )
}

export default EditDailyOffer
