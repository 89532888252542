import { removeAuth } from '@jumbo/components/JumboAuthProvider/authHelpers'
import jwtAxios from 'axios'

const BE_URL = process.env.REACT_APP_KIOSK_URL || ''
const jwtAuthAxiosKiosk = jwtAxios.create({
  baseURL: `${BE_URL}`,
  headers: {
    'Content-Type': 'application/json'
  }
})

jwtAuthAxiosKiosk.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.message === 'Unauthorised access') {
      delete jwtAuthAxiosKiosk.defaults.headers.common['Authorization']
      removeAuth()
    }
    return Promise.reject(err)
  }
)

export const setAuthToken = token => {
  if (token) {
    jwtAuthAxiosKiosk.defaults.headers.common['Authorization'] =
      'Bearer ' + token
    sessionStorage.setItem('token', token)
  } else {
    delete jwtAuthAxiosKiosk.defaults.headers.common['Authorization']
    sessionStorage.removeItem('token')
  }
}

export const getAuthToken = () => {
  return sessionStorage.getItem('token')
}

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxiosKiosk
