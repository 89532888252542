import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Typography
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { PROD_URL } from 'app/services/config'
import DeleteIcon from '@mui/icons-material/Delete'
import Images from 'app/pages/media/images/Images'
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack'
import DndWrapper from '@jumbo/components/DropZone/DndWrapper'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DSOK_MENU_TYPES } from 'app/utils/constants/settings'

const ACCEPT_TYPES = {
  image: ['jpg', 'jpeg', 'png', 'svg']
}

const MAX_SIZES = {
  image: 5
}

const options = {
  accept: ACCEPT_TYPES.image.map(type => `image/${type}`).join(','),
  maxSize: MAX_SIZES.image * 1024 * 1024
}

const DSOKCategoryForm = ({
  category,
  onAddCategory,
  onEditCategory,
  onCancel,
  productLanguages,
  defaultLanguage,
  categories,
  title
}) => {
  const { t, i18n } = useTranslation()
  const DSOKCategorySchema = Yup.object().shape({
    name: Yup.object().required(t('pages.dsokCategories.nameRequired'))
  })
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)

  useEffect(() => {
    if (defaultLanguage) {
      setSelectedLanguage(defaultLanguage)
    }
  }, [defaultLanguage])

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        _id: category?._id || '',
        name: category?.name || '',
        description: category?.description || '',
        image: category?.image || '',
        include_in_menu: category?.include_in_menu,
        customCategoryId: category?.customCategoryId || '',
        categoryCode: category?.categoryCode || '',
        menuType: category?.menuType || '',
        parentCategory: category?.parentCategory || ''
      }}
      validationSchema={DSOKCategorySchema}
      onSubmit={async data => {
        const handler = category ? onEditCategory : onAddCategory
        handler({ ...data })
      }}
    >
      {({ setFieldValue, values }) => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Typography variant='h4' pl={2} pb={2} mt={0}>
            {title || ''}
          </Typography>
          <Stack direction='row' gap={4} width='100%' alignItems='center'>
            <UploadComponent
              name='image'
              setFieldValue={setFieldValue}
              value={values.image}
            />
            <Stack width='100%'>
              <Stack direction='row' gap={2} pl={2}>
                <Grid container columnSpacing={2}>
                  <Grid item md={6} xs={12}>
                    <FormControl
                      sx={{
                        mt: 2,
                        mb: 1,
                        pr: 0,
                        display: 'flex',
                        flexDirection: 'row'
                      }}
                      fullWidth
                    >
                      <JumboTextField
                        select
                        sx={{ width: '100%' }}
                        name='parentCategory'
                        label={t('pages.dsokCategories.parentCategory')}
                      >
                        {categories
                          ?.filter(item => item._id !== category?._id)
                          .map((category, index) => {
                            return (
                              <MenuItem key={index} value={category._id}>
                                {
                                  category.name[
                                    defaultLanguage || i18n.language
                                  ]
                                }
                              </MenuItem>
                            )
                          })}
                      </JumboTextField>
                      <IconButton
                        onClick={() => setFieldValue('parentCategory', '')}
                      >
                        <HighlightOffIcon fontSize='medium' />
                      </IconButton>
                    </FormControl>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                      <FormControlLabel
                        control={
                          <Field
                            name={`include_in_menu`}
                            component={Switch}
                            onChange={e =>
                              setFieldValue(
                                `include_in_menu`,
                                e.target.checked ? 1 : 0
                              )
                            }
                          />
                        }
                        label={t('pages.dsokCategories.includeInMenu')}
                        checked={`${values.include_in_menu}` === '1'}
                      />
                    </Div>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                      <JumboTextField
                        fullWidth
                        name={`customCategoryId`}
                        label={t('pages.dsokCategories.customCategoryId')}
                      />
                    </Div>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                      <JumboTextField
                        fullWidth
                        name={`categoryCode`}
                        label={t('pages.dsokCategories.categoryCode')}
                      />
                    </Div>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                      <JumboTextField
                        select
                        sx={{ width: '100%' }}
                        name={`menuType`}
                        label={t('pages.dsokCategories.menuType')}
                      >
                        {DSOK_MENU_TYPES.map((type, index) => {
                          return (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          )
                        })}
                      </JumboTextField>
                    </Div>
                  </Grid>
                </Grid>
              </Stack>
              <TabContext value={selectedLanguage}>
                <Stack sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider'
                    }}
                  >
                    <TabList
                      onChange={(_, val) => setSelectedLanguage(val)}
                      aria-label='lab API tabs example'
                    >
                      {productLanguages.map(lang => {
                        return (
                          <Tab
                            key={lang}
                            label={t(`languages.${lang}`)}
                            value={lang}
                          />
                        )
                      })}
                    </TabList>
                  </Box>
                  {productLanguages.map(lang => {
                    return (
                      <TabPanel key={lang} value={lang} sx={{ pb: 1, pr: 0 }}>
                        <Div sx={{ mb: 1, mx: 0 }}>
                          <JumboTextField
                            fullWidth
                            required
                            name={`name.${lang}`}
                            label={t('pages.blocks.moduleName')}
                          />
                        </Div>
                        <Div sx={{ mb: 1, mt: 2, mx: 0 }}>
                          <JumboTextField
                            fullWidth
                            required
                            name={`description.${lang}`}
                            label={t('pages.products.description')}
                          />
                        </Div>
                      </TabPanel>
                    )
                  })}
                </Stack>
              </TabContext>
            </Stack>
          </Stack>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: '4px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button size='normal' onClick={onCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='normal'>
              {t('buttons.ok')}
            </Button>
          </Div>
        </Form>
      )}
    </Formik>
  )
}

const UploadComponent = ({ name, setFieldValue, value }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const handleFileUpload = async (acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length) {
      const message = (
        <>
          {`${t('widgets.dropZone.supportedTypes')}: ${ACCEPT_TYPES.image.join(
            ', '
          )}`}
          <br />
          {`${t('widgets.dropZone.maxFileSize')}: ${MAX_SIZES.image} MB`}
        </>
      )
      enqueueSnackbar(message, {
        variant: 'error'
      })
      return
    }
    if (acceptedFiles && acceptedFiles.length) {
      setFieldValue(name, acceptedFiles[0])
      enqueueSnackbar(t('widgets.dropZone.filesUploaded'), {
        variant: 'success'
      })
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    disabled: false,
    ...options
  })
  return (
    <DndWrapper>
      <div
        {...getRootProps()}
        style={{
          width: '100%',
          minHeight: '180px',
          display: 'flex',
          alignItems: 'center',
          p: 2,
          mt: 1,
          mb: 3,
          padding: 8
        }}
      >
        <input name='image' {...getInputProps()} />
        {value ? (
          <Avatar
            src={
              value.includes && value.includes('http')
                ? value
                : URL.createObjectURL(value)
            }
            variant={'rounded'}
            sx={{
              height: 'auto',
              width: `100%`,
              objectFit: 'contain',
              cursor: 'pointer'
            }}
          />
        ) : (
          <Typography variant={'body1'} align='center'>
            {t('widgets.dropZone.dropFiles')}
          </Typography>
        )}
      </div>
    </DndWrapper>
  )
}

export default DSOKCategoryForm
