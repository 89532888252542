import Div from '@jumbo/shared/Div'
import Images from 'app/pages/media/images/Images'
import React, { useEffect, useState } from 'react'
import EditModule from '../EditModule'
import { useTranslation } from 'react-i18next'
import ModuleSettings from '../ModuleSettings'
import { Form, Formik } from 'formik'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { TextField, IconButton, Stack } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  arrayMove,
  SortableContainer,
  SortableElement
} from 'react-sortable-hoc'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import ClearIcon from '@mui/icons-material/Clear'
import FormController from '../form/FormController'

const LessonTab = ({ lesson: { start, end }, index, value, onChange }) => {
  const [lesson, setLesson] = useState({ start, end })
  useEffect(() => {
    if (onChange) {
      onChange(index, lesson)
    }
  }, [lesson])

  useEffect(() => {
    setLesson({ start, end })
  }, [start, end])

  const { t } = useTranslation()

  return (
    <Div
      sx={{
        visibility: index !== value && 'hidden',
        display: index !== value && 'none'
      }}
    >
      <Formik>
        <Form style={{ textAlign: 'left' }} noValidate>
          <Stack
            direction={'row'}
            spacing={{ sm: 2, lg: 8 }}
            display='flex'
            sx={{ py: 6, px: 4, mx: 1 }}
          >
            <TextField
              id='start'
              type='time'
              value={lesson.start}
              label={t('pages.modules.start')}
              onChange={e => setLesson({ ...lesson, start: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
              sx={{ width: 150 }}
            />
            <TextField
              id='end'
              type='time'
              value={lesson.end}
              label={t('pages.modules.end')}
              onChange={e => setLesson({ ...lesson, end: e.target.value })}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
              sx={{ width: 150 }}
            />
          </Stack>
        </Form>
      </Formik>
    </Div>
  )
}

const TabCell = SortableElement(
  ({ label, tabIndex, onSelect, handleDelete }) => {
    return (
      <Tab
        style={{ zIndex: 99999999 }}
        onClick={() => onSelect(tabIndex)}
        label={
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            {label}
            <IconButton onClick={() => handleDelete(tabIndex)}>
              <ClearIcon fontSize='small' />
            </IconButton>
          </Stack>
        }
        value={tabIndex}
        key={tabIndex}
      />
    )
  }
)

const SchoolLessonsModule = ({ module, presentation, loadModule }) => {
  const [settings, setSettings] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [errors, setErrors] = useState({})
  const { showDialog, hideDialog } = useJumboDialog()

  const [updatedLessons, setUpdatedLessons] = useState(
    module?.parameters?.json_data?.lessons || []
  )
  const { t } = useTranslation()

  useEffect(() => {
    setSettings(module?.parameters)
  }, [module])

  const addNewLesson = () => {
    const newLesson = {
      start: '07:30',
      end: '07:30'
    }
    setUpdatedLessons({
      ...updatedLessons,
      [Object.keys(updatedLessons).length]: newLesson
    })
  }

  const handleDelete = index => {
    const orderedLessons = Object.values(updatedLessons).filter(
      (_, i) => i !== index
    )
    const newUpdatedLessons = {}
    orderedLessons.forEach((lesson, i) => {
      newUpdatedLessons[i] = lesson
    })
    setUpdatedLessons(newUpdatedLessons)
    if (selectedTab === index) {
      setSelectedTab(0)
    }
    hideDialog()
  }

  const TabsSortable = SortableContainer(() => {
    return (
      <Tabs
        variant='scrollable'
        sx={{ flex: 1, mt: 1 }}
        scrollButtons={true}
        aria-label='scrollable auto tabs example'
        value={selectedTab}
      >
        {Object.values(updatedLessons).map((lesson, index) => (
          <TabCell
            label={`${t('pages.modules.lesson')} ${index + 1}`}
            index={index}
            tabIndex={index}
            key={index}
            handleDelete={index =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDelete(index),
                onNo: () => hideDialog()
              })
            }
            onSelect={val => val !== 'add' && setSelectedTab(val)}
          />
        ))}
      </Tabs>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const orderedLessons = Object.values(updatedLessons)
    const newOrderedLessons = arrayMove(orderedLessons, oldIndex, newIndex)

    const newUpdatedLessons = {}
    newOrderedLessons.forEach(
      (lesson, index) => (newUpdatedLessons[index] = lesson)
    )
    setUpdatedLessons(newUpdatedLessons)
  }

  return (
    <EditModule
      type='school-lessons'
      module={module}
      presentation={presentation}
      loadModule={loadModule}
      content={
        <Div sx={{ bgcolor: 'background.paper', mx: 2, width: '75%' }}>
          <Stack
            direction='row'
            sx={{ alignItems: 'center', justifyContent: 'center', mb: 0 }}
            display='flex'
          >
            <TabsSortable onSortEnd={onSortEnd} useDragHandle={true} axis='x' />
            <IconButton onClick={addNewLesson}>
              <AddCircleIcon fontSize='small' />
            </IconButton>
          </Stack>
          {Object.values(updatedLessons).map((lesson, index) => {
            return (
              <LessonTab
                lesson={lesson}
                key={index}
                index={index}
                value={selectedTab}
                onChange={(lessonIndex, lesson) =>
                  setUpdatedLessons({
                    ...updatedLessons,
                    [lessonIndex]: lesson
                  })
                }
              />
            )
          })}
        </Div>
      }
      settings={
        module &&
        module.parameters && (
          <ModuleSettings module={module}>
            <FormController setSettings={setSettings} setErrors={setErrors} />
          </ModuleSettings>
        )
      }
      updateValue={{
        settings: {
          url: module?.setting?.url,
          parameters: {
            ...settings,
            json_data: { lessons: Object.values(updatedLessons) }
          }
        },
        errors: errors
      }}
    />
  )
}

export default SchoolLessonsModule
