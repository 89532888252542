import baseAxios from "axios";

export const BE_URL = process.env.REACT_APP_BE_URL || ''
export const FE_URL = process.env.REACT_APP_FE_URL || ''
export const PROD_URL = process.env.REACT_APP_PROD_URL || ''
export const FONT_API_KEY = process.env.REACT_APP_FONT_API_KEY || ''
export const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY || ''
export const axios = baseAxios.create({
  baseURL: `${BE_URL}/V1/`,
  headers: {
    'Content-Type': 'application/json'
  }
});
