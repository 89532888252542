import React, { useEffect } from 'react'
import { io } from 'socket.io-client'

export const Socket = React.createContext(null)

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = React.useState(null)

  useEffect(() => {
    return () => {
      if (socket?.active) {
        socket.disconnect()
      }
    }
  }, [])

  const connect = path => {
    if (socket?.active) {
      return socket
    }
    const connection = io(path)
    setSocket(connection)
    return connection
  }

  const disconnect = () => {
    if (socket?.active) {
      socket.disconnect()
    }
  }

  const subscribe = (topic, handler) => {
    if (socket?.active) {
      socket.on(topic, handler)
    }
  }

  const unsubscribe = (topic, handler) => {
    if (socket?.active) {
      socket.off(topic, handler)
    }
  }
  const publish = (topic, message) => {
    socket.emit(topic, message)
  }

  return (
    <Socket.Provider
      value={{ socket, connect, disconnect, subscribe, unsubscribe, publish }}
    >
      {children}
    </Socket.Provider>
  )
}

const useSocket = () => React.useContext(Socket)

export { useSocket }
export default SocketProvider
