import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack,
  Chip,
  FormControlLabel,
  Switch,
  Grid,
  Box,
  Divider
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { Field, useFormikContext } from 'formik'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import EditIcon from '@mui/icons-material/Edit'
import ChecklistIcon from '@mui/icons-material/Checklist'

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />
))

const BindingItemSortable = ({
  binding,
  defaultLanguage,
  code,
  allProducts,
  onAddGroup,
  onAddBinding,
  onDelete
}) => {
  const name = binding?.name && binding.name[defaultLanguage]
  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const { setFieldValue, values } = useFormikContext()
  return (
    <Grid item xs={12} md={12} sx={{ zIndex: 99999 }}>
      <Box
        flexDirection='row'
        justifyContent='space-between'
        display='flex'
        width='100%'
      >
        <Div sx={{ display: 'flex', gap: 1, flex: 1 }}>
          <DragHandle />
          <Typography sx={{ width: '33%', flexShrink: 0 }}>{name}</Typography>
        </Div>
        <Div sx={{ display: 'flex', gap: 1 }}>
          <IconButton
            sx={{ p: 0.5 }}
            onClick={e => {
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => onDelete(code, values, setFieldValue),
                onNo: () => hideDialog()
              })
            }}
          >
            <Tooltip title={t('buttons.delete')} placement='top-start'>
              <DeleteForeverIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            sx={{ p: 0.5 }}
            onClick={e => {
              onAddGroup(code, values, setFieldValue)
            }}
          >
            <Tooltip title={t('buttons.edit')} placement='top-start'>
              <EditIcon />
            </Tooltip>
          </IconButton>
          <IconButton sx={{ p: 0.5 }} onClick={() => onAddBinding(code)}>
            <Tooltip
              title={t('pages.dsokProducts.listOfItems')}
              placement='top-start'
            >
              <ChecklistIcon />
            </Tooltip>
          </IconButton>
        </Div>
      </Box>
      <Divider sx={{ mb: 3 }} />
      {Object.keys(binding?.products || {}).map((product, index) => {
        if (
          !(binding?.products[product] && binding?.products[product].status)
        ) {
          return null
        }
        return (
          <Chip
            sx={{ mr: 1, mb: 1 }}
            key={index}
            code={index}
            label={allProducts[product]?.productName[defaultLanguage] || ''}
            color='primary'
          />
        )
      })}
    </Grid>
  )
}
/* Todo item prop define */
export default SortableElement(BindingItemSortable)
