import jwtAuthAxios from '../auth/jwtAuth'

export const getProduct = async (url, customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/products/get?url=${url}&customerId=${customerId}`
  )
  return data.product
}

export const getProducts = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/products/list?customerId=${customerId}`
  )
  return data.response
}

export const addProduct = async customerId => {
  const { data } = await jwtAuthAxios.post('/products/add', { customerId })
  return data.url
}

export const updateProduct = async product => {
  const { data } = await jwtAuthAxios.post('/products/update', product)
  return data
}

export const copyProduct = async url => {
  const bodyFormData = new FormData()
  bodyFormData.append('url', url)
  const { data } = await jwtAuthAxios.post('/presentation/copy', bodyFormData)
  return data
}

export const refreshPresentation = async url => {
  const bodyFormData = new FormData()
  bodyFormData.append('url', url)
  const { data } = await jwtAuthAxios.post(
    '/presentation/refresh',
    bodyFormData
  )
  return data
}

export const deleteProduct = async (url, customerId) => {
  const { data } = await jwtAuthAxios.delete(
    `/products/delete?url=${url}&customerId=${customerId}`
  )
  return data
}
