import React from 'react'
import HomeIcon from '@mui/icons-material/Home'
import ArticleIcon from '@mui/icons-material/Article'
import SlideshowIcon from '@mui/icons-material/Slideshow'
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor'
import ImageIcon from '@mui/icons-material/Image'
import CollectionsIcon from '@mui/icons-material/Collections'
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay'
import CategoryIcon from '@mui/icons-material/Category'
import InventoryIcon from '@mui/icons-material/Inventory'
import StyleIcon from '@mui/icons-material/Style'
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import SettingsIcon from '@mui/icons-material/Settings'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import BarChartIcon from '@mui/icons-material/BarChart'

export const getHomeMenus = ({ presentations, screens, templates }) => {
  const homeMenu = {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/',
        label: 'sidebar.menuItem.home',
        type: 'nav-item',
        icon: <HomeIcon sx={{ fontSize: 20 }} />
      }
    ]
  }
  if (templates) {
    homeMenu.children.push({
      uri: '/templates',
      label: 'sidebar.menuItem.templates',
      type: 'nav-item',
      icon: <AutoStoriesIcon sx={{ fontSize: 20 }} />
    })
  }
  if (presentations) {
    homeMenu.children.push({
      uri: '/presentations',
      label: 'sidebar.menuItem.presentations',
      type: 'nav-item',
      icon: <SlideshowIcon sx={{ fontSize: 20 }} />
    })
  }
  if (screens) {
    homeMenu.children.push({
      uri: '/screens',
      label: 'sidebar.menuItem.screens',
      type: 'nav-item',
      icon: <ScreenshotMonitorIcon sx={{ fontSize: 20 }} />
    })
  }
  return homeMenu
}

export const mediaMenus = {
  label: 'sidebar.menu.media',
  type: 'section',
  children: [
    {
      uri: '/media/images',
      label: 'sidebar.menuItem.images',
      type: 'nav-item',
      icon: <ImageIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/media/galleries',
      label: 'sidebar.menuItem.galleries',
      type: 'nav-item',
      icon: <CollectionsIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/media/videos',
      label: 'sidebar.menuItem.videos',
      type: 'nav-item',
      icon: <VideoCameraBackIcon sx={{ fontSize: 20 }} />
    }
    // {
    //   uri: '/media/documents',
    //   label: 'sidebar.menuItem.documents',
    //   type: 'nav-item',
    //   icon: <ArticleIcon sx={{ fontSize: 20 }} />
    // }
  ]
}

export const productMenus = {
  label: 'sidebar.menu.products',
  type: 'collapsible',
  children: [
    {
      uri: '/products',
      label: 'sidebar.menuItem.products',
      type: 'nav-item',
      icon: <InventoryIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/product-categories',
      label: 'sidebar.menuItem.productCategories',
      type: 'nav-item',
      icon: <CategoryIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/product-attributes',
      label: 'sidebar.menuItem.productAttributes',
      type: 'nav-item',
      icon: <StyleIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/menus',
      label: 'sidebar.menuItem.menus',
      type: 'nav-item',
      icon: <RestaurantMenuIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/daily-offers',
      label: 'sidebar.menuItem.dailyOffers',
      type: 'nav-item',
      icon: <LocalOfferIcon sx={{ fontSize: 20 }} />
    }
  ]
}

export const kioskMenus = {
  label: 'sidebar.menu.kiosk',
  type: 'collapsible',
  children: [
    {
      uri: '/kiosk/devices',
      label: 'sidebar.menuItem.devices',
      type: 'nav-item',
      icon: <SmartDisplayIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/kiosk/articles',
      label: 'sidebar.menuItem.articles',
      type: 'nav-item',
      icon: <ArticleIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/kiosk/article-categories',
      label: 'sidebar.menuItem.articleCategories',
      type: 'nav-item',
      icon: <CategoryIcon sx={{ fontSize: 20 }} />
    }
  ]
}

export const dsokMenus = {
  label: 'sidebar.menu.dsok',
  type: 'collapsible',
  children: [
    {
      uri: '/customer/dsok/categories',
      label: 'sidebar.menuItem.dsokCategories',
      type: 'nav-item',
      icon: <CategoryIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/customer/dsok/products',
      label: 'sidebar.menuItem.products',
      type: 'nav-item',
      icon: <InventoryIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/customer/dsok/orders',
      operatorAccess: true,
      label: 'sidebar.menuItem.dsokOrders',
      type: 'nav-item',
      icon: <CreditScoreIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/customer/dsok/product-attributes',
      operatorAccess: true,
      label: 'sidebar.menuItem.dsokProductAttributes',
      type: 'nav-item',
      icon: <StyleIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/customer/dsok/reports',
      operatorAccess: true,
      label: 'sidebar.menuItem.dsokReports',
      type: 'nav-item',
      icon: <BarChartIcon sx={{ fontSize: 20 }} />
    },
    {
      uri: '/customer/dsok',
      label: 'sidebar.menuItem.dsokSettings',
      type: 'nav-item',
      icon: <SettingsIcon sx={{ fontSize: 20 }} />
    }
  ]
}

const menus = [
  {
    label: 'sidebar.menu.home',
    type: 'section',
    children: [
      {
        uri: '/',
        label: 'sidebar.menuItem.home',
        type: 'nav-item',
        icon: <HomeIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/templates',
        label: 'sidebar.menuItem.templates',
        type: 'nav-item',
        icon: <AutoStoriesIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/presentations',
        label: 'sidebar.menuItem.presentations',
        type: 'nav-item',
        icon: <SlideshowIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/screens',
        label: 'sidebar.menuItem.screens',
        type: 'nav-item',
        icon: <ScreenshotMonitorIcon sx={{ fontSize: 20 }} />
      }
    ]
  },

  {
    label: 'sidebar.menu.media',
    type: 'section',
    children: [
      {
        uri: '/media/images',
        label: 'sidebar.menuItem.images',
        type: 'nav-item',
        icon: <ImageIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/media/galleries',
        label: 'sidebar.menuItem.galleries',
        type: 'nav-item',
        icon: <CollectionsIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/media/videos',
        label: 'sidebar.menuItem.videos',
        type: 'nav-item',
        icon: <VideoCameraBackIcon sx={{ fontSize: 20 }} />
      }
      // {
      //   uri: '/media/documents',
      //   label: 'sidebar.menuItem.documents',
      //   type: 'nav-item',
      //   icon: <ArticleIcon sx={{ fontSize: 20 }} />
      // }
    ]
  },
  {
    label: 'sidebar.menu.kiosk',
    type: 'section',
    children: [
      {
        uri: '/kiosk/devices',
        label: 'sidebar.menuItem.devices',
        type: 'nav-item',
        icon: <SmartDisplayIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/kiosk/articles',
        label: 'sidebar.menuItem.articles',
        type: 'nav-item',
        icon: <ArticleIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/kiosk/article-categories',
        label: 'sidebar.menuItem.articleCategories',
        type: 'nav-item',
        icon: <CategoryIcon sx={{ fontSize: 20 }} />
      }
    ]
  },
  {
    label: 'sidebar.menu.dsok',
    type: 'collapsible',
    children: [
      {
        uri: '/customer/dsok/categories',
        label: 'sidebar.menuItem.categories',
        type: 'nav-item',
        icon: <CategoryIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/customer/dsok/products',
        label: 'sidebar.menuItem.products',
        type: 'nav-item',
        icon: <InventoryIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/customer/dsok/orders',
        label: 'sidebar.menuItem.dsokOrders',
        type: 'nav-item',
        icon: <CreditScoreIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/customer/dsok/product-attributes',
        operatorAccess: true,
        label: 'sidebar.menuItem.dsokProductAttributes',
        type: 'nav-item',
        icon: <StyleIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/customer/dsok/reports',
        operatorAccess: true,
        label: 'sidebar.menuItem.dsokReports',
        type: 'nav-item',
        icon: <BarChartIcon sx={{ fontSize: 20 }} />
      },
      {
        uri: '/customer/dsok',
        label: 'sidebar.menuItem.dsokSettings',
        type: 'nav-item',
        icon: <SettingsIcon sx={{ fontSize: 20 }} />
      }
    ]
  }
]

export default menus
