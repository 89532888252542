import jwtAuthAxios from '../auth/jwtAuth'

export const getBlock = async url => {
  const options = {
    params: {
      url
    }
  }
  const { data } = await jwtAuthAxios.get('/block/getBlock', options)
  return data
}

export const getSharedBlocks = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/block/getSharedBlocks?customerId=${customerId}`
  )
  return data.sharedBlocks
}

export const addBlock = async (presentation, parentId) => {
  const { data } = await jwtAuthAxios.post('/block/add', {
    presentation,
    type: parentId ? 'shared' : 'normal',
    parentId: parentId || ''
  })
  return data
}

export const updateBlock = async block => {
  const { data } = await jwtAuthAxios.post('/block/update', block)
  return data
}

export const deleteBlock = async url => {
  const { data } = await jwtAuthAxios.delete(`/block/delete?blockUrl=${url}`)
  return data
}
