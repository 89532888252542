import React, { useEffect, useState } from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  Card,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { Field, useFormikContext } from 'formik'
import { alpha } from '@mui/material'

const DSOKCategoryCheckbox = ({ item, defaultLanguage, searchName }) => {
  const { t, i18n } = useTranslation()
  const { setFieldValue, values } = useFormikContext()

  const onChange = (id, checked) => {
    setFieldValue('categories', { ...values.categories, [id]: checked })
  }
  return (
    <Card
      sx={{
        mb: 1,
        ml: searchName ? 0 : item.level * 5,
        mr: 2,
        boxShadow: `0 0.5rem 0.25rem ${alpha('#7352C7', 0.175)}`
      }}
    >
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography variant={'body1'}>
              {(item?.name && item?.name[defaultLanguage || i18n.language]) ||
                ''}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 0 },
              px: 1
            }}
          >
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 70
              }}
            >
              <Div sx={{ mt: 0, mb: 0, mx: 0.5 }}>
                <FormControlLabel
                  control={
                    <Field
                      name={`include_in_menu`}
                      component={Switch}
                      onChange={e => onChange(item._id, e.target.checked)}
                    />
                  }
                  checked={values.categories[item._id] || false}
                />
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default DSOKCategoryCheckbox
