import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import {
  addScreen,
  deleteScreen,
  getScreens,
  refreshScreen
} from 'app/services/api/screen'
import { useTranslation } from 'react-i18next'
import ScreenItem from './ScreenItem'
import { useSnackbar } from 'notistack'
import { FE_URL } from 'app/services/config'
import AddBoxIcon from '@mui/icons-material/AddBox'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddPresentationForm from '../presentation/AddPresentationForm'
import { useNavigate } from 'react-router-dom'

const Screens = () => {
  const { getAuthUser } = useJumboAuth()
  const { customerId, customer, userId } = getAuthUser()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const maxScreens = customer?.maxScreens

  const [screens, setScreens] = useState([])
  const [maxScreensReached, setMaxScreensReached] = useState(false)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [searchName, setSearchName] = useState()
  const { showDialog, hideDialog } = useJumboDialog()

  const handlePreview = async url => {
    window.open(FE_URL + '/screen/' + url, '_blank', 'noreferrer')
  }

  const handleDelete = async url => {
    hideDialog()
    setLoading(true)
    try {
      await deleteScreen(url)
      await loadScreens()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleRefresh = async url => {
    setLoading(true)
    try {
      await refreshScreen(url)
      await loadScreens()
      const message = `${t('buttons.refresh')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.refresh')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setLoading(false)
  }

  const loadScreens = async () => {
    await getScreens(customerId).then(screens => {
      setMaxScreensReached(screens?.length >= maxScreens)
      const filteredScreens = searchName
        ? screens.filter(screen =>
            screen.label.toLowerCase().includes(searchName)
          )
        : screens
      setScreens(filteredScreens || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadScreens()
  }, [searchName])

  const handleAddScreen = async ({ label }) => {
    hideDialog()
    setLoading(true)
    try {
      const screenUrl = await addScreen({
        label,
        customerId,
        userId
      })
      await loadScreens()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/screen/${screenUrl}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = () => {
    showDialog({
      variant: 'default',
      title: t('pages.screens.addScreen'),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <AddPresentationForm
          onAddPresentation={handleAddScreen}
          onCancel={hideDialog}
        />
      )
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.screens')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='screens'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip
          title={
            maxScreensReached
              ? t('pages.screens.maxScreens')
              : t('pages.screens.addScreen')
          }
        >
          <span>
            <IconButton disabled={maxScreensReached} onClick={openModal}>
              <AddBoxIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Stack>
      {screens && screens.length ? (
        screens.map((screen, index) => (
          <ScreenItem
            key={index}
            item={screen}
            disabled={loading}
            handlePreview={handlePreview}
            handleRefresh={handleRefresh}
            handleDelete={url =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDelete(url),
                onNo: () => hideDialog()
              })
            }
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Screens
