import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  Card,
  Typography,
  FormControlLabel,
  Switch
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'

const RemoteControlProduct = ({ item, onUpdate, type, category, disabled }) => {
  const { t, i18n } = useTranslation()
  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.screens.status')}
            </Typography>
            <Div sx={{ mt: 0, mb: 0, mx: 0 }}>
              <FormControlLabel
                control={
                  <Field
                    name={'active'}
                    component={Switch}
                    disabled={disabled}
                    onChange={e =>
                      onUpdate(item, category, type, 'active', e.target.checked)
                    }
                  />
                }
                // label={t('pages.products.active')}
                checked={item.active}
              />
            </Div>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Div sx={{ mt: 1, mb: 2, ml: 2, mr: 0, width: '100%' }}>
              <Typography>{item.label}</Typography>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default RemoteControlProduct
