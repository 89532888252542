import React from 'react'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import EditIcon from '@mui/icons-material/Edit'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />
))

const BlockCell = ({
  block: { block_label, block_url },
  url,
  template,
  onDelete
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <TableRow>
      <TableCell sx={{ pl: 2 }}>
        <DragHandle />
      </TableCell>
      <TableCell sx={{ padding: '5px 0px' }}>
        <Typography variant={'h6'} mb={0}>
          {block_label}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: '5px 0px' }}>
        <Tooltip title={t('buttons.edit')} placement='top-start'>
          {url ? (
            <IconButton onClick={() => navigate(`${url}`)}>
              <EditIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          ) : (
            <></>
          )}
        </Tooltip>
        <Tooltip title={t('buttons.delete')} placement='top-start'>
          {!template ? (
            <IconButton onClick={() => onDelete(block_url)}>
              <DeleteIcon sx={{ cursor: 'pointer' }} />
            </IconButton>
          ) : (
            <></>
          )}
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default SortableElement(BlockCell)
