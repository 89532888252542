import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Pagination,
  Box
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import CustomerItem from './CustomerItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import CustomerForm from './CustomerForm'
import {
  getBuildConfigurations,
  getCustomer,
  getCustomers,
  triggerDSOKBuild,
  upsertBuildConfiguration
} from 'app/services/api/management'
import authServices from 'app/services/auth-services'
import BuildIcon from '@mui/icons-material/Build'
import { useNavigate } from 'react-router-dom'

const ITEM_PER_PAGE = 10

const BuildAndDeploy = () => {
  const { getAuthUser, setAuthToken } = useJumboAuth()
  const { customerId, customer } = getAuthUser() || {}
  const { t } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const [page, setPage] = useState(1)
  const superadmin = customer?.customerType === 1
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const customerUrl = customer?.url || ''
  const navigate = useNavigate()

  const loadCustomers = async () => {
    await getCustomer(customerUrl)
    const buildConfigurations = await getBuildConfigurations()
    const buildConfigurationMap = {}
    buildConfigurations?.forEach(
      config => (buildConfigurationMap[`${config.customerId}`] = config)
    )
    await getCustomers(customerId).then(customers => {
      const customerData = customers
        .map(customer => {
          let dsokSettings
          const config =
            buildConfigurationMap[`${customer.customerData.id}`] || {}
          try {
            dsokSettings = JSON.parse(customer.customerData.dsokSettings)
          } catch (error) {}
          return {
            ...customer.customerData,
            dsokSettings,
            users: customer.users,
            ...config
          }
        })
        .filter(customer => customer.dsok)
      const filteredCustomers = searchName
        ? customerData.filter(customer =>
            customer.companyName.toLowerCase().includes(searchName)
          )
        : customerData
      setCustomers(filteredCustomers || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadCustomers()
  }, [searchName])

  const openModal = (type, customer) => {
    showDialog({
      variant: 'default',
      title: t(`pages.customers.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <CustomerForm
          customer={customer}
          onEditCustomer={handleEditCustomer}
          onCancel={hideDialog}
        />
      )
    })
  }
  const handleBuild = async customer => {
    setLoading(true)
    try {
      await triggerDSOKBuild(customer)
      const message = `${t('pages.buildAndDeploy.buildAndDeployRequest')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      setLoading(false)
    } catch (error) {
      const message = `${t('pages.buildAndDeploy.buildAndDeployRequest')} ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
    hideDialog()
  }

  const handleBuildAll = async () => {
    for (const customer of customers) {
      await triggerDSOKBuild(customer)
    }
    setLoading(true)
    try {
      const message = `${t('pages.buildAndDeploy.buildAndDeployRequest')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      setLoading(false)
    } catch (error) {
      const message = `${t('pages.buildAndDeploy.buildAndDeployRequest')} ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
    hideDialog()
  }

  const handleEditCustomer = async customer => {
    setLoading(true)
    hideDialog()
    try {
      await upsertBuildConfiguration(customer)
      await loadCustomers()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const buildConfirmation = customer => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () =>
        customer === 'all' ? handleBuildAll() : handleBuild(customer),
      onNo: () => hideDialog()
    })
  }

  const handleLoginAsCustomer = async customer => {
    setLoading(true)
    try {
      const user = customer.users[0]
      const data = await authServices.signIn({
        username: user.username,
        password: user.password,
        type: 'hash'
      })
      const message = `${t('buttons.loginAsCustomer')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      setAuthToken(data, true)
      if (data?.data?.customer?.url) {
        await getCustomer(data?.data?.customer?.url) // Load customer data to fetch DSOK BE Domain
      }
      navigate('/')
      setLoading(false)
    } catch (error) {
      const message = `${t('buttons.loginAsCustomer')} ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.buildAndDeploy')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        mb={2}
        spacing={1}
      >
        <Box width='50%' display='flex' flexDirection='row' gap={1}>
          <Card sx={{ mb: 0, width: '100%' }}>
            <JumboSearch
              page='buildAndDeploy'
              onChange={setSearchName}
              sx={{
                width: '100%'
              }}
              placeholder={t('pages.presentation.searchPresentation')}
            />
          </Card>
          {superadmin && (
            <Tooltip title={t('pages.buildAndDeploy.buildAll')}>
              <IconButton onClick={() => buildConfirmation('all')}>
                <BuildIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Pagination
          count={Math.ceil(customers?.length / ITEM_PER_PAGE)}
          color='primary'
          page={page}
          onChange={(_, val) => setPage(val)}
        />
      </Stack>
      {customers?.length ? (
        customers.map((customer, index) =>
          index + 1 > ITEM_PER_PAGE * (page - 1) &&
          index + 1 <= ITEM_PER_PAGE * page ? (
            <CustomerItem
              key={index}
              item={customer}
              superadmin={superadmin}
              disabled={loading}
              onLogin={handleLoginAsCustomer}
              onEdit={() => openModal('editCustomer', customer)}
              onBuild={() => buildConfirmation(customer)}
            />
          ) : null
        )
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default BuildAndDeploy
