import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Tooltip, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { addMenu, deleteMenu, getMenus, editMenu } from 'app/services/api/menu'
import MenuItem from './MenuItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import MenuForm from './MenuForm'
import { useNavigate } from 'react-router-dom'
import { DAYS } from 'app/utils/constants/settings'
import { addDailyOffer, getDailyOffers } from 'app/services/api/dailyOffer'
import { getProducts } from 'app/services/api/product'

const DailyOffers = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const navigate = useNavigate()

  const [menus, setMenus] = useState([])
  const [productCategories, setProductCategories] = useState()
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadDailyOffers = async () => {
    const offerPromise = getDailyOffers(customerId).then(dailyOffers => {
      const filteredMenus = searchName
        ? dailyOffers.filter(menu =>
            menu.label.toLowerCase().includes(searchName)
          )
        : dailyOffers
      setMenus(filteredMenus || [])
    })
    const productsPromise = getProducts(customerId).then(response => {
      const { categories } = response || {}
      setProductCategories(categories)
    })
    await Promise.all([offerPromise, productsPromise])
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadDailyOffers()
  }, [searchName, i18n.language])

  const handleDelete = async menu => {
    hideDialog()
    setLoading(true)
    try {
      await deleteMenu(menu.url, customerId)
      await loadDailyOffers()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, menu) => {
    showDialog({
      variant: 'default',
      title: t(`pages.dailyOffers.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <MenuForm
          menu={menu}
          onAddMenu={handleAddDailyOffer}
          onEditMenu={handleEditMenu}
          onCancel={hideDialog}
          productLanguages={productLanguages}
        />
      )
    })
  }

  const handleAddDailyOffer = async menu => {
    setLoading(true)
    hideDialog()
    try {
      const url = await addDailyOffer(customerId, { ...menu, metadata: {} })
      await loadDailyOffers()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/daily-offer/${url}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditMenu = async menu => {
    setLoading(true)
    hideDialog()
    try {
      await editMenu(menu)
      await loadDailyOffers()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = menu => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(menu),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.dailyOffers')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='menus'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.dailyOffers.addDailyOffer')}>
          <IconButton onClick={() => openModal('addDailyOffer')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {menus?.length ? (
        menus.map((menu, index) => (
          <MenuItem
            key={index}
            item={menu}
            disabled={loading}
            categories={productCategories}
            onDelete={deleteConfirmation}
            onEdit={() => {
              navigate(`/daily-offer/${menu.url}`)
            }}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default DailyOffers
