import jwtAuthAxios from "../auth/jwtAuth";

export const getTemplates = async () => {
  const { data } = await jwtAuthAxios.get('/presentation/templates');
  return data.data
}

export const createPresentationFromTemplate = async (templateUrl,customerId) => {
  
  const bodyFormData = new FormData();
  bodyFormData.append('url', templateUrl);
  bodyFormData.append('customerId', customerId);
  const { data } = await jwtAuthAxios.post('/presentation/create-from-template', bodyFormData);
  return data.url
}
