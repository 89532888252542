import React from 'react'
import Avatar from '@mui/material/Avatar'
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography
} from '@mui/material'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import JumboDdPopover from '@jumbo/components/JumboDdPopover'
import Div from '@jumbo/shared/Div'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const AuthUserDropdown = ({ popover }) => {
  const navigate = useNavigate()
  const { theme } = useJumboTheme()
  const { setAuthToken, getAuthUser } = useJumboAuth()

  const authUser = getAuthUser() || {}

  const onLogout = () => {
    if (authUser.mainAuth) {
      try {
        const { user, token } = authUser.mainAuth
        const userObj = JSON.parse(user)
        const { customer, ...userRest } = userObj
        setAuthToken({ token: token, data: { user: userRest, customer } })
        window.location.reload()
      } catch (error) {
        setAuthToken(null)
        navigate('/user/login')
      }

      return
    }
    setAuthToken(null)
    navigate('/user/login')
  }

  const Wrapper = popover ? JumboDdPopover : Div

  return (
    <ThemeProvider theme={theme}>
      <Wrapper
        triggerButton={
          <Avatar
            src={authUser.profile_pic}
            sizes={'small'}
            sx={{ boxShadow: 25, cursor: 'pointer' }}
          />
        }
      >
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: theme => theme.spacing(1.0)
          }}
        >
          {popover && (
            <Avatar
              src={authUser.profile_pic}
              alt={authUser.username}
              sx={{ width: 60, height: 60, mb: 2 }}
            />
          )}
          <Typography
            variant={'h5'}
          >{`${authUser.firstName} ${authUser.lastName}`}</Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {authUser.username}
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {authUser.email}
          </Typography>
        </Div>
        <nav>
          <List disablePadding sx={{ pb: 1, px: '24%' }}>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary='Logout' sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </Wrapper>
    </ThemeProvider>
  )
}

export default AuthUserDropdown
