import React, { useState, useRef } from 'react'
import {
  alpha,
  Button,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'
import Divider from '@mui/material/Divider'
import styled from '@emotion/styled'
import Avatar from '@mui/material/Avatar'
import ListItemButton from '@mui/material/ListItemButton'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { useTranslation } from 'react-i18next'

const Item = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary
}))

const TemplateItem = ({ item, onPreview, onCreate, creating }) => {
  const { theme } = useJumboTheme()
  const { i18n, t } = useTranslation()
  const [imageRatio, setImageRatio] = useState(1)
  const containerRef = useRef()
  const imageStyle = {
    height: 'auto',
    width: `${imageRatio * 150}px`,
    objectFit: 'contain'
  }

  return (
    <React.Fragment>
      <ListItemButton
        disableRipple
        alignItems='flex-start'
        ref={containerRef}
        sx={{
          cursor: 'default',
          p: 3,
          maxWidth: '100%',
          minWidth: 290,
          transition: 'all 0.2s',
          borderBottom: 1,
          borderBottomColor: 'divider',
          justifyContent: 'center',
          flexWrap: 'wrap',
          [theme.breakpoints.up('md')]: {
            flexWrap: 'wrap',
            maxWidth: '50%',
            minWidth: '50%'
          },
          [theme.breakpoints.up('lg')]: {
            flexWrap: 'wrap',
            maxWidth: '33%',
            minWidth: '33%'
          },
          '&:hover': {
            boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
            transform: 'translateY(-4px)',
            borderBottomColor: 'transparent'
          }
        }}
      >
        <ListItemAvatar
          sx={{
            width: '100%',
            mt: 0,
            mr: 3
          }}
        >
          <Avatar
            src={item.image_thumb}
            onClick={onPreview}
            onLoad={e => {
              setImageRatio(e.target.offsetWidth / e.target.offsetHeight)
            }}
            variant={'rounded'}
            sx={{
              cursor: 'pointer',
              mx: 'auto',
              ...imageStyle
            }}
          />
        </ListItemAvatar>
        <ListItemText>
          <Typography component={'div'}>
            <Typography variant={'h4'}>{item.name}</Typography>
            <Stack
              direction={'row'}
              divider={<Divider orientation='vertical' flexItem />}
              spacing={1}
              sx={{ mb: 2 }}
            >
              <Item>
                <Typography variant={'h6'} color={'primary.main'} mb={0}>
                  ID: {item.id}
                </Typography>
              </Item>
              <Item>
                <Typography variant={'h6'} color={'text.secondary'} mb={0}>
                  {item.resolution}
                </Typography>
              </Item>
            </Stack>
            <Typography variant={'body1'} mb={2}>
              {item.description[i18n.language]}
            </Typography>
            <Stack direction={'row'} spacing={1}>
              <Button
                variant={'contained'}
                color={'inherit'}
                size={'small'}
                onClick={onPreview}
              >
                {t('pages.templates.preview')}
              </Button>
              <Button
                variant={'contained'}
                size={'small'}
                disabled={creating}
                onClick={onCreate}
              >
                {t('pages.templates.create')}
              </Button>
            </Stack>
          </Typography>
        </ListItemText>
      </ListItemButton>
    </React.Fragment>
  )
}
/* Todo item prop define */
export default TemplateItem
