import React, { useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import InputBase from '@mui/material/InputBase'
import Div from '@jumbo/shared/Div'
import { useDebouncedCallback } from 'beautiful-react-hooks'
import { IconButton, InputAdornment } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { useSearch } from './SearchContext'

const JumboSearch = ({ onChange, value, sx, placeholder, page }) => {
  const { search, query } = useSearch()
  const [searchKeywords, setSearchKeywords] = useState(
    (query && query[page]) || ''
  )

  const inputRef = React.useRef(null)
  const handleChange = useDebouncedCallback(
    event => {
      setSearchKeywords(event?.target.value || '')
    },
    [],
    500
  )

  useEffect(() => {
    if (query && query[page] && inputRef.current) {
      inputRef.current.value = query[page]
    }
  }, [])

  const handleClear = () => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    handleChange()
  }

  useEffect(() => {
    if (page) {
      search(page, searchKeywords)
    }
    onChange(searchKeywords)
  }, [searchKeywords])

  React.useEffect(() => {
    return () => handleChange.cancel()
  })

  return (
    <Div
      sx={{
        position: 'relative',
        width: '100%',
        ...sx
      }}
    >
      <Div
        sx={{
          padding: theme => theme.spacing(0, 2),
          height: '100%',
          position: 'absolute',
          pointerEvents: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 2
        }}
      >
        <SearchIcon />
      </Div>
      <InputBase
        sx={{
          color: 'inherit',
          display: 'flex',
          borderRadius: 24,

          '& .MuiInputBase-input': {
            padding: theme => theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: theme => `calc(1em + ${theme.spacing(4)})`,
            transition: theme => theme.transitions.create('width'),
            width: '100%',
            height: 24
          }
        }}
        inputRef={inputRef}
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
        value={value}
        endAdornment={
          <InputAdornment position='end' sx={{ mr: 2 }}>
            <IconButton onClick={handleClear} edge='end'>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </Div>
  )
}

export default React.memo(JumboSearch)
