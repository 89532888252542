import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Stack,
  TextField
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import DragHandleIcon from '@mui/icons-material/DragHandle'

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />
))

const ScreenPresentationItem = ({
  presentation,
  handleUpdate,
  handleDelete,
  disabled
}) => {
  const { t } = useTranslation()
  const { id, presentation_name, presentation_duration } = presentation

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <DragHandle />
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.presentation.id')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{id}</Typography>
            </Stack>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 1 auto', md: 2 },
              maxWidth: '750px',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.screens.presentationName')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{presentation_name}</Typography>
            </Stack>
          </Div>
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '150px',
              flexShrink: 0,
              pr: 3
            }}
          >
            <TextField
              id='time'
              label={t('pages.blocks.duration')}
              type='time'
              name='duration'
              value={presentation_duration || ''}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 1
              }}
              onChange={e =>
                handleUpdate({
                  ...presentation,
                  presentation_duration: e.target.value
                })
              }
              sx={{ width: '100%' }}
            />
          </Div>
          <Div
            sx={{
              px: 1,
              width: '80px'
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 70
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => handleDelete(presentation)}
                >
                  <Tooltip title={t('buttons.delete')} placement='top-start'>
                    <DeleteForeverIcon />
                  </Tooltip>
                </IconButton>
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
export default SortableElement(ScreenPresentationItem)
