import { config } from '../../../app/config/main'

export const storeToken = token => {
  localStorage.setItem('token', token)
  if (!config?.authSetting?.axiosObject)
    throw Error(
      'axiosObject need to be set under authSettings inside app/config/main.js'
    )
  else {
    config.authSetting.axiosObject.defaults.headers.common['Authorization'] =
      'Bearer ' + token
    config.authSetting.axiosKioskObject.defaults.headers.common[
      'Authorization'
    ] = 'Bearer ' + token
    config.authSetting.axiosKioskStagingObject.defaults.headers.common[
      'Authorization'
    ] = 'Bearer ' + token
  }
}

export const getMainAuth = () => {
  const user = localStorage.getItem('user')
  const token = localStorage.getItem('token')
  return { user, token }
}

export const storeVersion = version => {
  localStorage.setItem('DV_VERSION', version)
}

export const storeUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const removeUser = () => {
  localStorage.removeItem('user')
}

export const removeToken = () => {
  localStorage.removeItem('token')
  if (!config?.authSetting?.axiosObject)
    throw Error(
      'axiosObject need to be set under authSettings inside app/config/main.js'
    )
  else {
    delete config.authSetting.axiosObject.defaults.headers.common[
      'Authorization'
    ]
    delete config.authSetting.axiosKioskObject.defaults.headers.common[
      'Authorization'
    ]
    delete config.authSetting.axiosKioskStagingObject.defaults.headers.common[
      'Authorization'
    ]
  }
}

export const removeAuth = () => {
  removeToken()
  removeUser()
}
