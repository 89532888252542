import jwtAuthAxios from '../auth/jwtAuth'

export const getPresentation = async url => {
  const { data } = await jwtAuthAxios.get(
    `/presentation/getPresentation?url=${url}`
  )
  return data.data && data.data.presentation
}

export const getPresentations = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/presentation/all?customerId=${customerId}`
  )
  return data.presentations
}

export const addPresentation = async presentation => {
  const { userId, customerId, label } = presentation
  const bodyFormData = new FormData()
  bodyFormData.append('userId', userId)
  bodyFormData.append('customerId', customerId)
  bodyFormData.append('label', label)
  const { data } = await jwtAuthAxios.post('/presentation/create', bodyFormData)
  return data.presentationUrl
}

export const updatePresentation = async presentation => {
  const { data } = await jwtAuthAxios.post('/presentation/update', presentation)
  return data
}

export const copyPresentation = async url => {
  const bodyFormData = new FormData()
  bodyFormData.append('url', url)
  const { data } = await jwtAuthAxios.post('/presentation/copy', bodyFormData)
  return data
}

export const refreshPresentation = async url => {
  const bodyFormData = new FormData()
  bodyFormData.append('url', url)
  const { data } = await jwtAuthAxios.post(
    '/presentation/refresh',
    bodyFormData
  )
  return data
}

export const deletePresentation = async url => {
  const { data } = await jwtAuthAxios.delete(`/presentation/delete?url=${url}`)
  return data
}
