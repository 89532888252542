import i18n from 'app/config/i18n'
import jwtAuthAxios from './auth/jwtAuth'

const authServices = {}

authServices.getCurrentUser = async () => {
  const { data } = await jwtAuthAxios.get('/auth')
  return data
}

authServices.signIn = async loginCreds => {
  const { username, password, type } = loginCreds
  console.log('loginCreds:: ', loginCreds)
  const bodyFormData = new FormData()
  bodyFormData.append('username', username)
  bodyFormData.append('password', password)
  if (type === 'hash') {
    bodyFormData.append('type', type)
  }
  const { data } = await jwtAuthAxios.post('/auth/login', bodyFormData)
  return data
}

authServices.forgotPassword = async email => {
  const bodyFormData = new FormData()
  bodyFormData.append('email', email)
  bodyFormData.append('lang', i18n.language)
  const { data } = await jwtAuthAxios.post(
    '/auth/forgot-password',
    bodyFormData
  )
  return data
}

export default authServices
