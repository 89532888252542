import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import {
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  Stack,
  Tooltip,
  Modal
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useFormikContext } from 'formik'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import { useSnackbar } from 'notistack'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ScreenSettings from './ScreenSettings'
import {
  addPresentationToScreen,
  deletePresentationFromScreen,
  getScreenPresentations,
  refreshScreen,
  updateScreen
} from 'app/services/api/screen'
import { arrayMove } from 'react-sortable-hoc'
import ScreenPresentationList from './ScreenPresentationList'
import Presentations from '../presentation/Presentations'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { FE_URL } from 'app/services/config'
import CachedIcon from '@mui/icons-material/Cached'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  backgroundColor: 'background.default',
  boxShadow: 24,
  p: 2
}

const ScreenPresentations = () => {
  const { url } = useParams()
  const { t } = useTranslation()
  const { theme } = useJumboTheme()
  const [settings, setSettings] = useState()
  const [openPresentationModal, setOpenPresentationModal] = useState(false)
  const [screenPresentations, setScreenPresentations] = useState()
  const [defaultScreenPresentations, setDefaultScreenPresentations] = useState()
  const [screenSettings, setScreenSettings] = useState()
  const [unsaved, setUnsaved] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { showDialog, hideDialog } = useJumboDialog()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (screenPresentations.length <= 1) {
      return
    }
    const newArray = arrayMove(screenPresentations, oldIndex, newIndex)
    setScreenPresentations(
      newArray.map((presentation, index) => ({
        ...presentation,
        presentation_order: index + 1
      }))
    )
  }

  const handleUpdate = presentation => {
    const updatedPresentations = screenPresentations.map(item =>
      item.id === presentation.id ? presentation : item
    )
    setScreenPresentations(updatedPresentations)
  }

  const FormikController = () => {
    const { values } = useFormikContext()
    useEffect(() => {
      if (values) {
        setSettings(values)
      }
    }, [values])
    return null
  }

  const loadScreenPresentations = async () => {
    await getScreenPresentations(url).then(
      ({ presentations, screenSettings }) => {
        const sorted =
          presentations?.sort(
            (a, b) => a.presentation_order - b.presentation_order
          ) || []
        setScreenPresentations(sorted)
        setDefaultScreenPresentations(sorted)
        setScreenSettings(screenSettings)
      }
    )
  }

  useEffect(() => {
    loadScreenPresentations()
  }, [])

  const handleAddPresentation = async presentation => {
    setOpenPresentationModal(false)
    setUpdating(true)
    try {
      await addPresentationToScreen(url, presentation.url)
      await loadScreenPresentations()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const handleDeletePresentation = async presentation => {
    hideDialog()
    setUpdating(true)
    try {
      await deletePresentationFromScreen(url, presentation.presentation_url)
      await loadScreenPresentations()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const handleRefresh = async url => {
    setUpdating(true)
    try {
      await refreshScreen(url)
      await loadScreenPresentations()
      const message = `${t('buttons.refresh')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.refresh')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  useEffect(() => {
    if (
      settings &&
      screenPresentations &&
      defaultScreenPresentations &&
      screenSettings
    ) {
      const sortedSettings = Object.keys(settings)
        .sort()
        .reduce((obj, key) => {
          obj[key] = settings[key]
          return obj
        }, {})
      const sortedScreenSettings = Object.keys({
        screenHeight: screenSettings.screenHeight,
        screenName: screenSettings.screenName,
        screenWidth: screenSettings.screenWidth
      })
        .sort()
        .reduce((obj, key) => {
          obj[key] = screenSettings[key]
          return obj
        }, {})

      setUnsaved(
        JSON.stringify(sortedSettings) !==
          JSON.stringify(sortedScreenSettings) ||
          JSON.stringify(screenPresentations) !==
            JSON.stringify(defaultScreenPresentations)
      )
    }
  }, [
    settings,
    screenPresentations,
    defaultScreenPresentations,
    screenSettings
  ])

  const handleUpdateScreen = async () => {
    setUpdating(true)
    try {
      await updateScreen({
        url,
        presentations: screenPresentations,
        screenSettings: settings
      })
      await loadScreenPresentations()
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }

    setUpdating(false)
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <Link to='/'>{t('sidebar.menuItem.home')}</Link>
        <Link to='/screens'>{t('sidebar.menuItem.screens')}</Link>
        <Typography color='text.primary'>
          {screenSettings?.screenName || ''}
        </Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: 'flex'
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 29,
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                {screenSettings?.screenName}
              </Typography>
              <Stack direction='row'>
                <Tooltip title={t('buttons.preview')} placement='top-start'>
                  <IconButton
                    onClick={() =>
                      window.open(
                        FE_URL + '/screen/' + url,
                        '_blank',
                        'noreferrer'
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('pages.presentation.addPresentation')}>
                  <IconButton onClick={() => setOpenPresentationModal(true)}>
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('buttons.refresh')}>
                  <IconButton onClick={() => handleRefresh(url)}>
                    <CachedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            flex: 1,
            mr: 2
          }}
          wrapperSx={{
            p: 1,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          <ScreenPresentationList
            screenPresentations={screenPresentations}
            useDragHandle={true}
            axis='y'
            onSortEnd={onSortEnd}
            onUpdate={handleUpdate}
            onDelete={presentation =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDeletePresentation(presentation),
                onNo: () => hideDialog()
              })
            }
            screenUrl={url}
          />
        </JumboCardQuick>
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                <Div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Link to={`/screens`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: '100%'
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: unsaved && '#F39711'
                    }}
                    startIcon={<BrowserUpdatedIcon />}
                    disabled={updating}
                    onClick={handleUpdateScreen}
                  >
                    {t('buttons.update')}
                  </Button>
                </Div>
              </Typography>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            maxWidth: 300,
            flex: 1
          }}
          wrapperSx={{
            p: 0,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          {screenSettings ? (
            <ScreenSettings settings={screenSettings}>
              <FormikController />
            </ScreenSettings>
          ) : (
            <CircularProgress sx={{ m: '5px 50%' }} />
          )}
        </JumboCardQuick>
      </Div>
      <Modal
        open={openPresentationModal}
        onClose={() => setOpenPresentationModal(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Div sx={modalStyle}>
          <Presentations
            selectorMode
            onSelect={handleAddPresentation}
            sx={{ maxHeight: '90vh', overflowY: 'scroll', p: 2 }}
          />
        </Div>
      </Modal>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity='info'>
          <AlertTitle>{t('messages.unsavedChanges.title')}</AlertTitle>
          {t('messages.unsavedChanges.description')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ScreenPresentations
