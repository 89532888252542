import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Modal,
  Pagination,
  Box,
  FormControlLabel,
  Switch
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import Div from '@jumbo/shared/Div/Div'
import {
  deleteDSOKProduct,
  getCustomer,
  getDSOKCategories,
  getDSOKProducts,
  upsertDSOKProduct
} from 'app/services/api/management'
import DSOKProductForm from './DSOKProductForm'
import ProductItem from './ProductItem'
import jwtAuthAxiosKiosk from 'app/services/auth/jwtAuthKioskStaging'
import { prepareCategoryLevels } from 'app/utils/arrayHelpers'
import { Field } from 'formik'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80vw',
  maxHeight: '90vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const ITEM_PER_PAGE = 10

const DSOKProducts = ({ selectMode, onItemUpdate, selectedProducts }) => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { url }
  } = getAuthUser()

  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const [productModal, setProductModal] = useState()
  const [page, setPage] = useState(1)

  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [defaultProducts, setDefaultProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [onlyActiveItems, setOnlyActiveItems] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [customer, setCustomer] = useState()
  const defaultLanguage =
    customer?.dsokSettings?.customer?.defaultLanguage || i18n.language
  const languages = customer?.dsokSettings?.customer?.languages || []
  const customerUrl = url || ''

  const loadProducts = async () => {
    await getDSOKProducts().then(products => {
      const productArr = Object.keys(products || {}).map(id => ({
        ...products[id]
      }))

      let filteredProducts = searchName
        ? productArr.filter(
            product =>
              product?.productName &&
              product.productName[defaultLanguage]
                .toLowerCase()
                .includes(searchName)
          )
        : productArr
      if (onlyActiveItems) {
        filteredProducts = filteredProducts.filter(item =>
          selectedProducts ? selectedProducts[item._id]?.status : false
        )
      }

      setProducts(filteredProducts || [])
      setDefaultProducts(productArr || [])
    })
    setLoading(false)
  }

  const searchByName = async searchName => {
    setLoading(true)
    if (!jwtAuthAxiosKiosk.defaults.baseURL) {
      await loadCustomer()
    }
    searchName !== undefined && loadProducts()
  }

  useEffect(() => {
    searchByName(searchName)
    setPage(1)
  }, [searchName, onlyActiveItems])

  const loadCustomer = async () => {
    await getCustomer(customerUrl).then(customer => setCustomer(customer))
  }

  const loadCategories = async () => {
    await getDSOKCategories().then(categories => {
      const categoryArr = Object.keys(categories || {}).map(id => ({
        ...categories[id]
      }))
      const leveledCategories = prepareCategoryLevels(categoryArr || [])
      setCategories(leveledCategories || [])
    })
    setLoading(false)
  }

  // const loadCategories = async () => {
  //   await getDSOKCategories().then(categories => {
  //     // const { categories } = response || {}
  //     const categoryArr = Object.keys(categories || {}).map(id => ({
  //       ...categories[id]
  //     }))

  //     setCategories(categoryArr || [])
  //   })
  // }

  const loadData = async () => {
    await loadCustomer()
    await loadCategories()
  }

  useEffect(() => {
    loadData()
  }, [])

  const handleDelete = async product => {
    hideDialog()
    setLoading(true)
    try {
      await deleteDSOKProduct(product._id)
      await loadProducts()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleAddProduct = async product => {
    setLoading(true)
    setProductModal()
    try {
      await upsertDSOKProduct(product)
      await loadProducts()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditProduct = async product => {
    setLoading(true)
    setProductModal()
    try {
      await upsertDSOKProduct(product)
      await loadProducts()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = product => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(product),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('pages.dsokProducts.title')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        justifyContent={'space-between'}
        mb={2}
        spacing={1}
      >
        <Box
          width={selectMode ? '60%' : '50%'}
          display='flex'
          flexDirection='row'
        >
          <Card sx={{ mb: 0, width: '100%', flex: 5 }}>
            <JumboSearch
              page='DSOKProducts'
              onChange={setSearchName}
              sx={{
                width: '100%'
              }}
              placeholder={t('pages.presentation.searchPresentation')}
            />
          </Card>
          {!selectMode && (
            <Tooltip title={t('pages.products.addProduct')}>
              <IconButton
                onClick={() => setProductModal({ type: 'addProduct' })}
              >
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          )}
          {selectMode && (
            <Div sx={{ mt: 0, mb: 0, ml: 2, flex: 2 }}>
              <FormControlLabel
                control={
                  <Field
                    name={`filter_only_active`}
                    component={Switch}
                    onChange={e => setOnlyActiveItems(e.target.checked)}
                  />
                }
                sx={{ width: '100%' }}
                label={t('pages.dsokProducts.showActiveItems')}
                checked={onlyActiveItems || false}
              />
            </Div>
          )}
        </Box>
        <Pagination
          count={Math.ceil(products?.length / ITEM_PER_PAGE)}
          color='primary'
          page={page}
          onChange={(_, val) => setPage(val)}
        />
      </Stack>
      {products?.length ? (
        <>
          {products.map((product, index) => {
            if (
              index + 1 > ITEM_PER_PAGE * (page - 1) &&
              index + 1 <= ITEM_PER_PAGE * page
            ) {
              return (
                <ProductItem
                  key={index}
                  item={product}
                  disabled={loading}
                  onDelete={deleteConfirmation}
                  onEdit={() =>
                    setProductModal({ type: 'editProduct', product })
                  }
                  productLanguages={languages}
                  defaultLanguage={defaultLanguage}
                  products={defaultProducts}
                  searchName={searchName}
                  selectMode={selectMode}
                  onItemUpdate={onItemUpdate}
                  selectedProducts={selectedProducts}
                />
              )
            }
            return null
          })}
        </>
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
      <Modal
        open={productModal !== undefined}
        onClose={() => setProductModal()}
      >
        <Div sx={modalStyle}>
          <DSOKProductForm
            title={t(`pages.products.${productModal?.type}`)}
            product={productModal?.product}
            onAddProduct={handleAddProduct}
            productLanguages={languages}
            defaultLanguage={defaultLanguage}
            onEditProduct={handleEditProduct}
            onCancel={() => setProductModal()}
            customerId={customerId}
            products={defaultProducts}
            categories={categories}
            locations={customer?.dsokSettings?.customer?.locations}
          />
        </Div>
      </Modal>
    </React.Fragment>
  )
}

export default DSOKProducts
