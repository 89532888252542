import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import { Alert, Button, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TreeView from '@mui/lab/TreeView'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import TreeItem from '@mui/lab/TreeItem'
import AddIcon from '@mui/icons-material/Add'
import Div from '@jumbo/shared/Div'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { addFolder, deleteFolder, renameFolder } from 'app/services/api/media'
import { useSnackbar } from 'notistack'
import DzBasic from '@jumbo/components/DropZone/DzBasic'

const FileStructure = ({
  fileStructure,
  onFolderSelect,
  sx,
  mediaType,
  defaultExpanded,
  reload,
  customerId,
  uploadEnabled
}) => {
  const { theme } = useJumboTheme()
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [openModal, setOpenModal] = useState()
  const [folderName, setFolderName] = useState('')
  const [selectedFolder, setSelectedFolder] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setOpenModal()
    setFolderName('')
  }

  const handleOpenModalAdd = () => {
    if (selectedFolder) {
      setOpenModal('add')
    }
  }

  const handleOpenModalRename = () => {
    if (selectedFolder) {
      setFolderName(selectedFolder.label)
      setOpenModal('rename')
    }
  }

  const handleAddFolder = async () => {
    if (folderName) {
      handleClose()
      const result = await addFolder(selectedFolder.id, folderName, mediaType)
      if (!result) {
        const message = t('widgets.fileStructure.addFolderError')
        enqueueSnackbar(message, {
          variant: 'error'
        })
      } else {
        const message = t('widgets.fileStructure.addFolderSuccess')
        enqueueSnackbar(message, {
          variant: 'success'
        })
      }
      await reload()
    }
  }

  const handleRenameFolder = async () => {
    if (folderName) {
      handleClose()
      const result = await renameFolder(
        selectedFolder.id,
        customerId,
        folderName
      )
      if (!result) {
        const message = t('widgets.fileStructure.renameFolderError')
        enqueueSnackbar(message, {
          variant: 'error'
        })
      } else {
        const message = t('widgets.fileStructure.renameFolderSuccess')
        enqueueSnackbar(message, {
          variant: 'success'
        })
      }
      await reload()
    }
  }

  const handleDeleteFolder = async () => {
    if (selectedFolder) {
      const result = await deleteFolder(selectedFolder.id, customerId)
      if (result.status === 'FALSE') {
        const message = t(
          `widgets.fileStructure.deleteFolderMessages.${result.message}`
        )
        enqueueSnackbar(message, {
          variant: 'error'
        })
      } else {
        const message = t('widgets.fileStructure.deleteFolderMessages.success')
        enqueueSnackbar(message, {
          variant: 'success'
        })
      }
      await reload()
      setSelectedFolder()
    }
  }

  const loadFileStructure = fileStructure => {
    const fileMap = {}
    let rootId
    fileStructure.reverse().forEach(file => {
      file.name = file.label
      file.id = `${file.id}`
      if (file.mediaRoot !== mediaType) {
        return
      }
      if (file.position === 1) {
        rootId = file.id
      }
      fileMap[file.id] = { ...fileMap[file.id], ...file }
      if (file.parent) {
        if (!fileMap[file.parent] || !fileMap[file.parent].children) {
          fileMap[file.parent] = { ...fileMap[file.parent], children: [] }
        }
        fileMap[file.parent].children.push(fileMap[file.id])
      }
    })
    const rootFolder = fileMap[rootId] || {}
    setData(rootFolder)
  }

  useEffect(() => {
    if (fileStructure) {
      loadFileStructure(fileStructure)
    }
  }, [fileStructure])

  const handleClick = node => {
    setSelectedFolder(node)
    if (onFolderSelect) {
      onFolderSelect(node)
    }
  }

  const renderTree = nodes => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={nodes.name}
      onClick={() => handleClick(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(node => renderTree(node))
        : null}
    </TreeItem>
  )

  return (
    <JumboCardQuick
      sx={sx}
      title={
        <Typography
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              flexWrap: 'wrap'
            }
          }}
        >
          {t('widgets.fileStructure.title')}
        </Typography>
      }
      headerSx={{
        borderBottom: 1,
        borderBottomColor: 'divider',
        '& .MuiCardHeader-action': {
          my: -0.75
        }
      }}
      wrapperSx={{
        p: 0,
        '&:last-child': {
          pb: 2
        },
        '& .MuiCollapse-entered:last-child': {
          '& .MuiListItemButton-root': {
            borderBottom: 0,
            borderBottomColor: 'transparent'
          }
        }
      }}
    >
      <Div sx={{ p: 2 }}>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          size='small'
          sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}
          onClick={handleOpenModalAdd}
        >
          <Typography variant={'body3'}>{t('buttons.add')}</Typography>
        </Button>
        <Button
          variant='contained'
          startIcon={<DriveFileRenameOutlineIcon />}
          size='small'
          sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}
          onClick={handleOpenModalRename}
        >
          <Typography variant={'body3'}>{t('buttons.rename')}</Typography>
        </Button>
        <Button
          variant='contained'
          startIcon={<DeleteForeverIcon />}
          size='small'
          sx={{ mr: 1, mb: { xs: 1, sm: 0 } }}
          onClick={handleDeleteFolder}
        >
          <Typography variant={'body3'}>{t('buttons.delete')}</Typography>
        </Button>
      </Div>
      <Stack direction='row' spacing={3} pr={1}>
        <TreeView
          aria-label='rich object'
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={[defaultExpanded]}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: '100%', flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
          {data && data.id && renderTree(data)}
        </TreeView>
        {uploadEnabled && (
          <DzBasic
            type={mediaType}
            reload={reload}
            catalogTreeId={selectedFolder && selectedFolder.id}
          />
        )}
      </Stack>

      <Dialog open={openModal !== undefined} onClose={handleClose} fullWidth>
        <DialogTitle>
          {openModal === 'add'
            ? t('widgets.fileStructure.addFolder')
            : t('widgets.fileStructure.renameFolder')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('widgets.fileStructure.pleaseEnterFolderName')}
          </DialogContentText>
          <TextField
            margin='dense'
            id='folderName'
            label={t('widgets.fileStructure.folderName')}
            type='text'
            fullWidth
            variant='standard'
            value={folderName}
            onChange={e => setFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('buttons.cancel')}</Button>
          <Button
            onClick={openModal === 'add' ? handleAddFolder : handleRenameFolder}
          >
            {t('buttons.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </JumboCardQuick>
  )
}

export default FileStructure
