import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboTheme } from '@jumbo/hooks'
import Div from '@jumbo/shared/Div'
import {
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  AlertTitle,
  IconButton,
  Stack,
  Tooltip,
  Modal
} from '@mui/material'
import { getPresentation } from 'app/services/api/presentation'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { useFormikContext } from 'formik'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import { useSnackbar } from 'notistack'
import AddBoxIcon from '@mui/icons-material/AddBox'
import BlockSettings from './BlockSettings'
import { getBlock, updateBlock } from 'app/services/api/block'
import { arrayMove } from 'react-sortable-hoc'
import ModuleItemList from './ModuleItemList'
import ModuleList from '../module/ModuleList'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import {
  addModuleToBlock,
  deleteModuleFromBlock,
  getModuleList
} from 'app/services/api/module'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65vw',
  backgroundColor: 'background.default',
  boxShadow: 24,
  p: 2
}

const EditBlock = () => {
  const { blockUrl, url } = useParams()
  const { t } = useTranslation()
  const { theme } = useJumboTheme()
  const [settings, setSettings] = useState()
  const [openModuleModal, setOpenModuleModal] = useState(false)
  const [moduleList, setModuleList] = useState({})
  const [block, setBlock] = useState()
  const [defaultBlock, setDefaultBlock] = useState()
  const [unsaved, setUnsaved] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { showDialog, hideDialog } = useJumboDialog()
  const navigate = useNavigate()

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (block.modules.length <= 1) {
      return
    }
    const newArray = arrayMove(block.modules, oldIndex, newIndex)
    setBlock({
      ...block,
      modules: newArray.map((module, index) => ({
        ...module,
        moduleOrder: index
      }))
    })
  }

  const handleModuleUpdate = modules => {
    setBlock({
      ...block,
      modules
    })
  }

  const FormikController = () => {
    const { values } = useFormikContext()
    useEffect(() => {
      if (values) {
        setSettings(values)
      }
    }, [values])
    return null
  }

  const [presentation, setPresentation] = useState({})

  const loadPresentation = async () => {
    await getPresentation(url).then(presentation => {
      setPresentation(presentation)
    })
  }

  const loadBlock = async () => {
    await getBlock(blockUrl).then(block => {
      const modules =
        block.modules
          ?.sort((a, b) => a.moduleOrder - b.moduleOrder)
          .map((module, index) => ({ ...module, moduleOrder: index })) || []
      setBlock({ ...block, modules })
      setDefaultBlock({ ...block, modules })
    })
  }

  const loadModuleList = async () => {
    await getModuleList().then(moduleList => {
      if (moduleList?.data) {
        const { types, modules } = moduleList?.data
        const groupedModules = {}
        types.forEach(type => (groupedModules[type.id] = {}))
        Object.keys(modules).forEach(scope => {
          Object.keys(modules[scope]).forEach(moduleKey => {
            const module = modules[scope][moduleKey]
            groupedModules[module.categoryId][moduleKey] = module
          })
        })
        setModuleList({ types, modules: groupedModules } || {})
      }
    })
  }

  const loadData = async () => {
    await loadModuleList()
    await loadPresentation()
    await loadBlock()
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (settings && block && defaultBlock) {
      const updated = {
        ...block,
        parameters: { ...block.parameters, ...settings }
      }

      updated.parameters = Object.keys(updated.parameters)
        .sort()
        .reduce((obj, key) => {
          obj[key] = updated.parameters[key]
          return obj
        }, {})
      const updatedOrdered = Object.keys(updated)
        .sort()
        .reduce((obj, key) => {
          obj[key] = updated[key]
          return obj
        }, {})
      const defaultBlockCloned = structuredClone(defaultBlock)
      defaultBlockCloned.parameters = Object.keys(defaultBlockCloned.parameters)
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultBlockCloned.parameters[key]
          return obj
        }, {})
      const updatedDefault = Object.keys(defaultBlockCloned)
        .sort()
        .reduce((obj, key) => {
          obj[key] = defaultBlockCloned[key]
          return obj
        }, {})

      setUnsaved(
        JSON.stringify(updatedOrdered) !== JSON.stringify(updatedDefault)
      )
    }
  }, [settings, block, defaultBlock])

  const handleUpdateBlock = async () => {
    setUpdating(true)
    const updatedBlock = {
      ...block,
      parameters: { ...block.parameters, ...settings }
    }
    try {
      await updateBlock(updatedBlock)
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      await loadPresentation()
      await loadBlock()
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }

    setUpdating(false)
  }

  const handleDeleteModule = async url => {
    hideDialog()
    setUpdating(true)
    try {
      await deleteModuleFromBlock(url)
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      await loadPresentation()
      await loadBlock()
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const handleAddModule = async moduleCode => {
    setOpenModuleModal(false)
    setUpdating(true)
    const updatedBlock = {
      block: {
        url: blockUrl,
        moduleCode
      }
    }
    try {
      const res = await addModuleToBlock(updatedBlock)
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      await loadPresentation()
      await loadBlock()
      navigate(`/presentation/${url}/block/${blockUrl}/module/${res.blockUrl}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 1 }}>
        <Link to='/'>{t('sidebar.menuItem.home')}</Link>
        <Link to='/presentations'>{t('sidebar.menuItem.presentations')}</Link>
        <Link to={`/presentation/${url}`}>{presentation?.name}</Link>
        <Typography color='text.primary'>{block?.parameters?.name}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: 'flex'
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 29,
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                {block?.parameters?.name}
              </Typography>
              <Stack direction='row'>
                <Tooltip title={t('pages.blocks.addModule')}>
                  <IconButton
                    disabled={!(moduleList?.types && moduleList?.modules)}
                    onClick={() => setOpenModuleModal(true)}
                  >
                    <AddBoxIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            flex: 1,
            mr: 2
          }}
          wrapperSx={{
            p: 1,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          <ModuleItemList
            block={block}
            useDragHandle={true}
            axis='y'
            onSortEnd={onSortEnd}
            onUpdate={handleModuleUpdate}
            onDelete={url =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDeleteModule(url),
                onNo: () => hideDialog()
              })
            }
            blockUrl={blockUrl}
            presentationUrl={url}
          />
        </JumboCardQuick>
        <JumboCardQuick
          title={
            <Typography
              component={'div'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('md')]: {
                  flexWrap: 'wrap'
                }
              }}
            >
              <Typography
                variant={'h4'}
                mb={0}
                sx={{
                  minWidth: 245,
                  [theme.breakpoints.down('md')]: {
                    minWidth: '100%',
                    marginBottom: 2
                  }
                }}
              >
                <Div sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Link
                    to={`/presentation/${url}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant='outlined'
                      size='small'
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: '100%'
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  </Link>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{
                      boxShadow: 'none',
                      backgroundColor: unsaved && '#F39711'
                    }}
                    startIcon={<BrowserUpdatedIcon />}
                    disabled={updating}
                    onClick={handleUpdateBlock}
                  >
                    {t('buttons.update')}
                  </Button>
                </Div>
              </Typography>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: 'divider',
            '& .MuiCardHeader-action': {
              my: -0.75
            }
          }}
          sx={{
            maxWidth: 300,
            flex: 1
          }}
          wrapperSx={{
            p: 0,
            '&:last-child': {
              pb: 2
            },
            '& .MuiCollapse-entered:last-child': {
              '& .MuiListItemButton-root': {
                borderBottom: 0,
                borderBottomColor: 'transparent'
              }
            }
          }}
        >
          {block && Object.values(block).length ? (
            <BlockSettings block={block}>
              <FormikController />
            </BlockSettings>
          ) : (
            <CircularProgress sx={{ m: '5px 50%' }} />
          )}
        </JumboCardQuick>
      </Div>
      <Modal
        open={openModuleModal}
        onClose={() => setOpenModuleModal(false)}
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      >
        <Div sx={modalStyle}>
          <Typography variant={'h2'} mb={3} id='modal-title'>
            {t('pages.blocks.modules')}
          </Typography>
          {moduleList?.types && moduleList?.modules ? (
            <ModuleList
              moduleList={moduleList}
              onCancel={() => setOpenModuleModal(false)}
              onOk={handleAddModule}
            />
          ) : (
            <JumboListNoDataPlaceHolder />
          )}
        </Div>
      </Modal>
      <Snackbar
        open={unsaved}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity='info'>
          <AlertTitle>{t('messages.unsavedChanges.title')}</AlertTitle>
          {t('messages.unsavedChanges.description')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default EditBlock
