import React, { useState } from 'react';
import { Alert, Card, CardContent, Snackbar, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import { alpha } from "@mui/material/styles";
import * as yup from "yup";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { useTranslation } from 'react-i18next';
import authServices from 'app/services/auth-services';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import { LoadingButton } from '@mui/lab';

const ForgotPassword = () => {
  const { t } = useTranslation()
  const [alert, setAlert] = useState(false)

  const handleAlertClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false)
  }

  const validationSchema = yup.object({
    email: yup
      .string('Enter your username')
      .email(t('pages.forgotPassword.enterValidEmail'))
      .required(t('pages.forgotPassword.emailRequired')),
  });


  const onReset = async (email) => {
    if (email) {
      await authServices.forgotPassword(email)
        .then((data) => {
          setAlert(data)
        }).catch(err => {
          const response = err.response?.data
          setAlert(response)
        });
    }
  }

  return (
    <Div sx={{
      flex: 1,
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: theme => theme.spacing(4),
    }}>
      <Div sx={{ mb: 3, display: 'inline-flex' }}>
        <Link href="/" underline="none" sx={{ display: 'inline-flex' }}>
          <img src={`${ASSET_IMAGES}/logo.png`} alt="Jumbo React" />
        </Link>
      </Div>
      <Card sx={{ maxWidth: '100%', width: 360, mb: 4 }}>
        <Div sx={{ position: 'relative', height: '200px' }}>
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={getAssetPath(`${ASSET_IMAGES}/colin-watts.jpg`)}
          />
          <Div sx={{
            flex: 1,
            inset: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme => alpha(theme.palette.common.black, .5),
            p: theme => theme.spacing(3),
          }}>
            <Typography
              variant={"h2"}
              sx={{
                color: 'common.white',
                fontSize: '1.5rem',
                mb: 0
              }}>
              {t('pages.forgotPassword.forgotPassword')}
            </Typography>
          </Div>
        </Div>
        <CardContent>
          <Div sx={{ mb: 3, mt: 1 }}>
            <Formik
              validateOnChange={true}
              validationSchema={validationSchema}
              initialValues={{ email: '' }}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
                await onReset(data.email);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form style={{ textAlign: 'left' }} noValidate >
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name="email"
                      label={t('pages.forgotPassword.email')}
                    />
                  </Div>
                  <LoadingButton
                    fullWidth
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >{t('pages.forgotPassword.resetPassword')}</LoadingButton>
                </Form>
              )}
            </Formik>
          </Div>
          <Typography textAlign={"center"} variant={"body1"} mb={1}>{t('pages.forgotPassword.dontRememberEmail')} <Link
            underline="none" href="mailto:info@datavision-online.eu">{t('pages.forgotPassword.contactSupport')}</Link></Typography>
        </CardContent>
      </Card>
      <Snackbar open={alert !== false} autoHideDuration={6000} onClose={handleAlertClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert onClose={handleAlertClose} severity={alert?.code === 400 ? 'error' : 'success'} sx={{ width: '100%' }} variant='filled'>
          {alert?.message}
        </Alert>
      </Snackbar>
    </Div >
  );
};

export default ForgotPassword;
