import { getModule } from 'app/services/api/module'
import React, { useEffect, useState } from 'react'
import ImagesPreview from './ImagesPreview'
import { useNavigate } from 'react-router-dom'
import Div from '@jumbo/shared/Div'
import TextArrayPreview from './TextArrayPreview'

const ModulePreview = ({ moduleUrl, blockPath, is_shared }) => {
  const [module, setModule] = useState({})
  const navigate = useNavigate()

  const PREVIEWS = {
    images: <ImagesPreview module={module} />,
    'text-array': <TextArrayPreview module={module} />
  }

  const loadModule = async () => {
    moduleUrl &&
      (await getModule(moduleUrl).then(response => setModule(response)))
  }

  useEffect(() => {
    loadModule()
  }, [])

  return (
    <Div
      sx={{ width: '100%', height: '100%' }}
      onClick={() => !is_shared && navigate(blockPath)}
    >
      {PREVIEWS[module?.setting?.moduleCode] || null}
    </Div>
  )
}

export default ModulePreview
