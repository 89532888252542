import Div from '@jumbo/shared/Div'
import React, { useEffect, useState } from 'react'
import EditModule from '../EditModule'
import ModuleSettings from '../ModuleSettings'
import FormController from '../form/FormController'

const DateModule = ({ module, presentation, loadModule }) => {
  const [settings, setSettings] = useState({})
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setSettings(module?.parameters)
  }, [module])

  return (
    <EditModule
      type='clock'
      module={module}
      presentation={presentation}
      loadModule={loadModule}
      content={<Div sx={{ px: 1 }}></Div>}
      settings={
        module &&
        module.parameters && (
          <ModuleSettings module={module}>
            <FormController setSettings={setSettings} setErrors={setErrors} />
          </ModuleSettings>
        )
      }
      updateValue={{
        settings: {
          url: module?.setting?.url,
          parameters: { ...settings }
        },
        errors: errors
      }}
    />
  )
}

export default DateModule
