import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Stack
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import AddBoxIcon from '@mui/icons-material/AddBox'

const BlockItem = ({ item, handleAdd, disabled }) => {
  const { t } = useTranslation()

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.presentation.id')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{item.id}</Typography>
            </Stack>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.blockName')}
            </Typography>
            <Typography variant={'body1'}>{item.name}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              px: 1,
              maxWidth: { sm: 260, xs: 80 },
              minWidth: { sm: 160, xs: 80 }
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 70
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={disabled}
                  onClick={() => handleAdd(item.id)}
                >
                  <Tooltip title={t('buttons.add')} placement='top-start'>
                    <AddBoxIcon />
                  </Tooltip>
                </IconButton>
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default BlockItem
