import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import {
  Chip,
  Divider,
  Typography,
  Stack,
  Modal,
  Avatar,
  ImageListItem,
  ImageListItemBar,
  CardMedia
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useJumboTheme } from '@jumbo/hooks'
import JumboList from '@jumbo/components/JumboList'
import Div from '@jumbo/shared/Div'
import { deleteFile, getVideos } from 'app/services/api/media'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import FileStructure from 'app/shared/FileStructure/FileStructure'
import { BE_URL } from 'app/services/config'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import DeleteIcon from '@mui/icons-material/Delete'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'

const Videos = () => {
  const { t } = useTranslation()
  const { getAuthUser } = useJumboAuth()

  const [fileStructure, setFileStructure] = useState([])
  const [videos, setVideos] = useState()
  const [selectedVideos, setSelectedVideos] = useState([])
  const [selectedFolder, setSelectedFolder] = useState()
  const [previewVideoIndex, setPreviewVideoIndex] = useState()
  const [videoDimensions, setVideoDimensions] = useState({
    width: 100,
    height: 100
  })
  const { theme } = useJumboTheme()
  const { customerId } = getAuthUser()
  const { showDialog, hideDialog } = useJumboDialog()
  const { enqueueSnackbar } = useSnackbar()

  const handleNextVideo = () => {
    setPreviewVideoIndex(previewVideoIndex + 1)
  }
  const handlePrevVideo = () => {
    setPreviewVideoIndex(previewVideoIndex - 1)
  }

  const handleDelete = async id => {
    await deleteFile(id, customerId)
      .then(res => {
        const message = `${t('buttons.delete')} ${t(
          'notifications.wasSuccessful'
        )}`
        enqueueSnackbar(message, {
          variant: 'success'
        })
      })
      .catch(() => {
        const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
        enqueueSnackbar(message, {
          variant: 'error'
        })
      })
    hideDialog()
    await loadVideos()
  }

  const renderVideo = React.useCallback(video => {
    const label = video.label.split('.')[0]
    const index = selectedVideos.findIndex(
      selectedVideo => video.id === selectedVideo.id
    )
    return (
      <ImageListItem
        key={video.id}
        sx={{
          borderRadius: 2,
          overflow: 'hidden',
          width: 'auto',
          marginRight: 1,
          marginBottom: 1,
          img: {
            height: '140px !important',
            width: 'auto !important'
          }
        }}
      >
        <OndemandVideoIcon
          sx={{
            fontSize: 80,
            m: {
              xs: '0 15px 25px 15px',
              sm: '0 30px 55px 30px',
              md: '0 40px 60px 40px'
            }
          }}
        />
        <ImageListItemBar
          subtitle={
            <Stack
              direction='column'
              spacing={1}
              sx={{
                mb: 0
              }}
            >
              <Typography variant={'p'} noWrap mb={0}>
                {label}
              </Typography>
              <Stack
                direction='row'
                spacing={0}
                justifyContent='space-between'
                sx={{
                  mb: 0
                }}
              >
                <ZoomInIcon onClick={() => setPreviewVideoIndex(index)} />
                <DeleteIcon
                  onClick={() =>
                    showDialog({
                      variant: 'confirm',
                      title: t('widgets.confirmDialog.areYouSure'),
                      onYes: () => handleDelete(video.id),
                      onNo: () => hideDialog()
                    })
                  }
                  sx={{ cursor: 'pointer' }}
                />
              </Stack>
            </Stack>
          }
        />
      </ImageListItem>
    )
  })

  const loadVideos = async () => {
    await getVideos(customerId).then(({ media, catalogTree }) => {
      setVideos(media)
      setFileStructure(catalogTree)
    })
  }

  useEffect(() => {
    loadVideos()
  }, [])

  useEffect(() => {
    if (selectedFolder) {
      setSelectedVideos(
        videos
          .filter(video => `${video.catalogTree}` === selectedFolder)
          .sort((a, b) => a.label.localeCompare(b.label))
      )
    }
  }, [selectedFolder, videos])

  const handleFolderSelect = folder => {
    setSelectedFolder(folder.id)
  }
  const previewVideo = selectedVideos[previewVideoIndex]

  const videoStyle =
    videoDimensions.width > videoDimensions.height
      ? {
          width: '60vw',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: 'auto'
        }
      : {
          width: 'auto',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: '60vh'
        }
  return (
    <Stack
      direction='column'
      spacing={2}
      sx={{
        mb: 2
      }}
    >
      <JumboCardQuick
        title={
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap'
              }
            }}
          >
            <Typography
              variant={'h4'}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down('md')]: {
                  minWidth: '100%',
                  marginBottom: 2
                }
              }}
            >
              {t('sidebar.menuItem.videos')}{' '}
              <Chip
                size={'small'}
                label={selectedVideos.length}
                color='primary'
              />
            </Typography>
          </Typography>
        }
        headerSx={{
          borderBottom: 1,
          borderBottomColor: 'divider',
          '& .MuiCardHeader-action': {
            my: -0.75
          }
        }}
        wrapperSx={{
          p: 0,
          '&:last-child': {
            pb: 2
          },
          '& .MuiCollapse-entered:last-child': {
            '& .MuiListItemButton-root': {
              borderBottom: 0,
              borderBottomColor: 'transparent'
            }
          }
        }}
      >
        <JumboScrollbar
          autoHeight
          autoHeightMin={'40vh'}
          autoHide
          autoHideDuration={200}
          autoHideTimeout={500}
        >
          <JumboList
            primaryKey={'id'}
            primary={'id'}
            view='grid'
            data={selectedVideos}
            renderItem={renderVideo}
            isLoading={!videos}
            componentElement={'div'}
            wrapperSx={{ pb: 1, paddingLeft: 5, paddingTop: 5 }}
          />
        </JumboScrollbar>
        <Modal
          open={previewVideo !== undefined}
          onClose={() => setPreviewVideoIndex()}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Div sx={modalStyle}>
            <Stack
              direction='column'
              divider={<Divider orientation='horizontal' flexItem />}
              spacing={2}
              sx={{
                mb: 2
              }}
            >
              <Typography id='modal-modal-title' variant='h4' component='h2'>
                {previewVideo && previewVideo.label}
              </Typography>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={0}
              >
                <NavigateBeforeIcon
                  fontSize='large'
                  onClick={handlePrevVideo}
                  sx={{
                    opacity: previewVideoIndex === 0 ? 0.5 : 1.0,
                    cursor: previewVideoIndex === 0 ? 'default' : 'pointer',
                    pointerEvents: previewVideoIndex === 0 ? 'none' : 'all'
                  }}
                />
                <CardMedia
                  component='video'
                  height='140'
                  sx={videoStyle}
                  autoPlay
                  controls
                  src={
                    previewVideo &&
                    `${BE_URL}/uploads/files/${customerId}/Videos/${previewVideo.filename}`
                  }
                />
                <NavigateNextIcon
                  fontSize='large'
                  onClick={handleNextVideo}
                  sx={{
                    opacity:
                      selectedVideos.length <= previewVideoIndex + 1
                        ? 0.5
                        : 1.0,
                    cursor:
                      selectedVideos.length <= previewVideoIndex + 1
                        ? 'default'
                        : 'pointer',
                    pointerEvents:
                      selectedVideos.length <= previewVideoIndex + 1
                        ? 'none'
                        : 'all'
                  }}
                />
              </Stack>
              <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                {/* {previewTemplate && previewTemplate.description[i18n.language]} */}
              </Typography>
            </Stack>
          </Div>
        </Modal>
      </JumboCardQuick>
      <FileStructure
        fileStructure={fileStructure}
        mediaType='video'
        onFolderSelect={handleFolderSelect}
        sx={{ minHeight: '40vh' }}
        reload={loadVideos}
        defaultExpanded={'3'}
        customerId={customerId}
        uploadEnabled
      />
    </Stack>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  maxWidth: '90vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

export default Videos
