import jwtAuthAxios from '../auth/jwtAuth'

export const getModule = async url => {
  const { data } = await jwtAuthAxios.get(`/module/getModule?url=${url}`)
  return data
}

export const getModuleList = async () => {
  const { data } = await jwtAuthAxios.get(`/module/list`)
  return data
}

export const addModuleToBlock = async block => {
  const { data } = await jwtAuthAxios.post('/block/module/add', block)
  return data
}

export const deleteModuleFromBlock = async module => {
  const { data } = await jwtAuthAxios.delete(
    `block/module/delete?blockModuleUrl=${module}`
  )
  return data
}

export const updateModule = async module => {
  const { data } = await jwtAuthAxios.post('/module/update', module)
  return data
}
