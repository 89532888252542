import Div from '@jumbo/shared/Div/Div.js'
import { Tooltip } from '@mui/material'
import { memo, useEffect, useRef, useState } from 'react'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import ModulePreview from '../module/previews/ModulePreview.js'
import useResizeObserver from '@react-hook/resize-observer'
import { BE_URL, PROD_URL } from 'app/services/config'

export const DraggableBox = memo(function DraggableBox (props) {
  const {
    id,
    title,
    x,
    y,
    selectedBorderColor,
    width,
    height,
    zIndex,
    block_background_color,
    block_background_image,
    label,
    moduleUrl,
    onResize,
    block_url,
    presentationUrl,
    template,
    is_shared,
    presentationSize,
    moveBox
  } = props
  const [bgBlob, setBgBlob] = useState('')

  const blockPath = template
    ? `/presentation/${presentationUrl}/block/${block_url}/module/${moduleUrl}`
    : `/presentation/${presentationUrl}/block/${block_url}`

  const useSize = (target, initialSize) => {
    const [size, setSize] = useState(initialSize)

    // Where the magic happens
    useResizeObserver(target, entry => {
      const diff =
        entry.borderBoxSize[0].inlineSize - entry.contentBoxSize[0].inlineSize
      setSize({
        width: entry.contentRect.width + diff,
        height: entry.contentRect.height + diff
      })
    })
    return size
  }

  const ref = useRef()

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: 'BOX',
      item: { id, x, y, title, width, height },
      canDrag: !template,
      collect: monitor => {
        return { isDragging: monitor.isDragging() }
      },
      end: (item, monitor) => {
        const didDrop = monitor.didDrop()
        if (!didDrop) {
          moveBox()
        }
      }
    }),
    [id, x, y, title, width, height, moveBox]
  )
  drag(ref)
  const size = useSize(ref, { width, height })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (block_background_image && block_background_image !== '0') {
        const blob = await fetch(
          block_background_image.replace(PROD_URL, BE_URL)
        ).then(r => r.blob())
        setBgBlob(URL.createObjectURL(blob))
      }
    }
    fetchData()
  }, [block_background_image])

  useEffect(() => {
    if (size) {
      onResize(id, {
        width: size.width,
        height: size.height
      })
    }
  }, [size])

  return (
    <Tooltip title={!isDragging && label}>
      <Div
        ref={ref}
        role='DraggableBox'
        // onMouseDown={e => setOrder(99999999)}
        sx={{
          resize: !template && !is_shared && 'both',
          overflow: 'hidden',
          border: 'solid 1px',
          borderColor: selectedBorderColor || 'primary',
          position: 'absolute',
          opacity: isDragging ? 0 : 1,
          cursor: !template && 'move',
          width,
          height,
          maxWidth: presentationSize.width - x,
          maxHeight: presentationSize.height - y,
          top: y,
          left: x,
          zIndex: 1000 + (zIndex || 0),
          backgroundColor: block_background_color,
          backgroundImage: bgBlob && `url("${bgBlob}")`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <ModulePreview
          moduleUrl={moduleUrl}
          blockPath={blockPath}
          is_shared={is_shared}
        />
      </Div>
    </Tooltip>
  )
})
