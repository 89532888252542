import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import {
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const MenuForm = ({
  menu,
  onAddMenu,
  onEditMenu,
  onCancel,
  productLanguages
}) => {
  const { t } = useTranslation()
  const MenuSchema = Yup.object().shape({
    label: Yup.string().required(t('pages.articleCategories.labelRequired')),
    languageCode: Yup.string().required(t('pages.articles.languageRequired'))
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={
        menu || { label: '', languageCode: '', active: '', displayPrice: '' }
      }
      validationSchema={MenuSchema}
      onSubmit={async data => {
        const handler = menu ? onEditMenu : onAddMenu
        handler(data)
      }}
    >
      {props => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              required
              name='label'
              label={t('pages.presentation.label')}
            />
          </Div>
          <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
            <JumboTextField
              select
              name='languageCode'
              required
              label={t('pages.articles.language')}
            >
              {productLanguages.map((language, index) => {
                return (
                  <MenuItem key={index} value={language.code}>
                    {language.label}
                  </MenuItem>
                )
              })}
            </JumboTextField>
          </FormControl>
          <Div sx={{ mt: 0, mb: 0, mx: 2 }}>
            <FormControlLabel
              control={
                <Field
                  name={'active'}
                  component={Switch}
                  onChange={e =>
                    props.setFieldValue('active', e.target.checked)
                  }
                />
              }
              label={t('pages.products.active')}
              checked={props.values.active}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 0, mx: 2 }}>
            <FormControlLabel
              control={
                <Field
                  name={'displayPrice'}
                  component={Switch}
                  onChange={e =>
                    props.setFieldValue('displayPrice', e.target.checked)
                  }
                />
              }
              label={t('pages.menus.displayPrice')}
              checked={props.values.displayPrice}
            />
          </Div>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: '4px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button size='normal' onClick={onCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='normal'>
              {t('buttons.ok')}
            </Button>
          </Div>
        </Form>
      )}
    </Formik>
  )
}

export default MenuForm
