import React, { useState } from 'react'
import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Snackbar,
  Typography
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { alpha } from '@mui/material/styles'
import { ASSET_IMAGES } from '../../../utils/constants/paths'
import { getAssetPath } from '../../../utils/appHelpers'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import LoadingButton from '@mui/lab/LoadingButton'
import authServices from '../../../services/auth-services'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { useTranslation } from 'react-i18next'

const Login = () => {
  const { setAuthToken } = useJumboAuth()
  const [openError, setOpenError] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const validationSchema = yup.object({
    username: yup
      .string('Enter your username')
      .required(t('pages.login.usernameRequired')),
    password: yup
      .string('Enter your password')
      .required(t('pages.login.passwordRequired'))
  })

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenError(false)
  }

  const onSignIn = (username, password) => {
    authServices
      .signIn({ username, password })
      .then(data => {
        setAuthToken(data)
        navigate('/')
      })
      .catch(err => {
        setOpenError(true)
      })
  }

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: '100%',
        margin: 'auto',
        p: 4
      }}
    >
      <Card
        sx={{
          display: 'flex',
          minWidth: 0,
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <CardContent
          sx={{
            flex: '0 1 300px',
            position: 'relative',
            background: `#0267a0`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/images/widgets/keith-luke.jpg)`,
            backgroundSize: 'cover',
            '&::after': {
              display: 'inline-block',
              position: 'absolute',
              content: `''`,
              inset: 0,
              backgroundColor: alpha('#0267a0', 0.65)
            }
          }}
        >
          <Div
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column',
              color: 'common.white',
              position: 'relative',
              zIndex: 1,
              height: '100%'
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={'h3'}
                color={'inherit'}
                fontWeight={500}
                mb={3}
              >
                {t('pages.login.signIn')}
              </Typography>
              <Typography variant={'body1'}>
                <Link
                  component={RouterLink}
                  to='/user/forgotPassword'
                  color={'inherit'}
                  underline={'none'}
                >
                  {t('pages.login.forgotPassword')}
                </Link>
              </Typography>
            </Div>

            <Div sx={{ mt: 'auto' }}>
              <Link href='#' underline='none' sx={{ display: 'inline-flex' }}>
                <img
                  src={`${ASSET_IMAGES}/logo.png`}
                  style={{ width: '75%' }}
                  alt='Jumbo React'
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            validationSchema={validationSchema}
            initialValues={{ username: '', password: '' }}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true)
              onSignIn(data.username, data.password)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: 'left' }} noValidate>
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField
                    fullWidth
                    name='username'
                    label={t('pages.login.username')}
                  />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboTextField
                    fullWidth
                    name='password'
                    label={t('pages.login.password')}
                    type='password'
                  />
                </Div>
                <Div sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t('pages.login.rememberMe')}
                  />
                </Div>
                <LoadingButton
                  fullWidth
                  type='submit'
                  variant='contained'
                  size='large'
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  {t('pages.login.signIn')}
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
      >
        <Alert
          onClose={handleErrorClose}
          severity='error'
          sx={{ width: '100%' }}
          variant='filled'
        >
          {t('pages.login.error')}
        </Alert>
      </Snackbar>
    </Div>
  )
}

export default Login
