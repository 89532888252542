import jwtAuthAxios from '../auth/jwtAuthKiosk'

// ---------------- Articles ----------------

export const getArticles = async () => {
  const { data } = await jwtAuthAxios.get('/kiosk/article')
  return data?.result
}

export const addArticle = async article => {
  const { data } = await jwtAuthAxios.post('/kiosk/article', article)
  return data?.result
}

export const editArticle = async article => {
  const { data } = await jwtAuthAxios.put(
    `/kiosk/article/${article.url}`,
    article
  )
  return data?.result
}

export const deleteArticle = async url => {
  const { data } = await jwtAuthAxios.delete(`/kiosk/article/${url}`)
  return data?.result
}

// ---------------- Article Categories ----------------

export const getArticleCategories = async () => {
  const { data } = await jwtAuthAxios.get('/kiosk/articleCategory')
  return data?.result
}

export const deleteArticleCategory = async url => {
  const { data } = await jwtAuthAxios.delete(`/kiosk/articleCategory/${url}`)
  return data?.result
}

export const addArticleCategory = async category => {
  const { data } = await jwtAuthAxios.post('/kiosk/articleCategory', category)
  return data?.result
}

export const editArticleCategory = async category => {
  const { data } = await jwtAuthAxios.put(
    `/kiosk/articleCategory/${category.url}`,
    category
  )
  return data?.result
}

// ---------------- Devices ----------------

export const getDevices = async () => {
  const { data } = await jwtAuthAxios.get('/kiosk/device')
  return data?.result
}

export const getDevice = async url => {
  const { data } = await jwtAuthAxios.get(`/kiosk/device/${url}`)
  return data?.result
}

export const deleteDevice = async url => {
  const { data } = await jwtAuthAxios.delete(`/kiosk/device/${url}`)
  return data?.result
}

export const addDevice = async device => {
  const { data } = await jwtAuthAxios.post('/kiosk/device', device)
  return data?.result
}

export const copyDevice = async url => {
  const {
    data: {
      result: { settings }
    }
  } = await jwtAuthAxios.get(`/kiosk/device/${url}`)
  settings.main.label += ' copy'
  const {
    data: {
      result: { id }
    }
  } = await jwtAuthAxios.post('/kiosk/device', { settings })
  const { data } = await jwtAuthAxios.put(`/kiosk/device/${id}`, { settings })
  return id
}

export const editDevice = async device => {
  const { data } = await jwtAuthAxios.put(`/kiosk/device/${device.url}`, device)
  return data?.result
}
