import jwtAuthAxios from '../auth/jwtAuth'

export const getWidgets = async customerId => {
  const options = {
    params: {
      customerId
    }
  }
  const { data } = await jwtAuthAxios.get('/homepage/widgets', options)
  return data?.response
}
