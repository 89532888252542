import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  IconButton,
  Tooltip,
  Typography,
  Stack,
  Modal
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import {
  addArticle,
  deleteArticle,
  editArticle,
  getArticleCategories,
  getArticles
} from 'app/services/api/kiosk'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import ArticleForm from './ArticleForm'
import ArticleItem from './ArticleItem'
import Div from '@jumbo/shared/Div'

const Articles = () => {
  const { getAuthUser } = useJumboAuth()
  const { customerId, userId } = getAuthUser()
  const { t } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [openArticleModal, setOpenArticleModal] = useState()
  const [searchName, setSearchName] = useState()

  const [articles, setArticles] = useState([])
  const [articleCategories, setArticleCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadArticles = async () => {
    const categories = await loadCategories()
    await getArticles(customerId).then(articles => {
      const filteredArticles = searchName
        ? articles.filter(article =>
            article.label.toLowerCase().includes(searchName)
          )
        : articles
      const returnArticles = filteredArticles.map(article => {
        const category = categories.find(
          category => `${category.url}` === `${article.category}`
        )
        return { ...article, category: category }
      })
      setArticles(returnArticles || [])
    })
    setLoading(false)
  }

  const loadCategories = async () => {
    const categories = await getArticleCategories(customerId)
    setArticleCategories(categories || [])
    setLoading(false)
    return categories
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadArticles()
  }, [searchName])

  const handleDelete = async article => {
    hideDialog()
    setLoading(true)
    try {
      await deleteArticle(article.url)
      await loadArticles()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, article) => {
    const title = t(`pages.articles.${type}`)
    setOpenArticleModal({ article, title })
    // showDialog({
    //   variant: 'default',
    //   title: t(`pages.articles.${type}`),
    //   maxWidth: 'lg',
    //   sx: {
    //     '& .MuiDialog-container': {
    //       alignItems: 'flex-start'
    //     }
    //   },
    //   content: (

    //   )
    // })
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

  const handleAddArticle = async article => {
    setLoading(true)
    setOpenArticleModal()
    try {
      await addArticle(article)
      await loadArticles()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditArticle = async article => {
    setLoading(true)
    setOpenArticleModal()
    try {
      await editArticle(article)
      await loadArticles()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = category => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(category),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.articles')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='articles'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.articles.addArticle')}>
          <IconButton onClick={() => openModal('addArticle')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Modal
        open={openArticleModal !== undefined}
        onClose={() => setOpenArticleModal()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Div sx={modalStyle}>
          <Typography variant='h3' pl={2} pb={2}>
            {openArticleModal?.title || ''}
          </Typography>
          <ArticleForm
            article={openArticleModal?.article}
            onAddArticle={handleAddArticle}
            onEditArticle={handleEditArticle}
            categories={articleCategories}
            onCancel={hideDialog}
            userId={userId}
            customerId={customerId}
          />
        </Div>
      </Modal>
      {articles?.length ? (
        articles.map((article, index) => (
          <ArticleItem
            key={index}
            item={article}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => openModal('editArticle', article)}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Articles
