import React from 'react';
import { Button, Stack } from "@mui/material";

const JumboTabs = ({ tabs, onChange, sx, id }) => {
  const [activeTab, setActiveTab] = React.useState();

  const handleTabClick = (item) => {
    const tab = id ? item[id] : item
    const active = id && activeTab ? activeTab[id] : activeTab
    setActiveTab(tab === active ? undefined : item);
  };

  React.useEffect(() => {
    onChange(activeTab);
  }, [activeTab]);

  return (
    <Stack direction={"row"} spacing={0.5} sx={sx}>
      {
        tabs.map((item, index) => {
          const tab = id ? item[id] : item
          const active = id && activeTab ? activeTab[id] : activeTab
          return (
            <Button
              key={index}
              size={"small"}
              variant={(active && tab === active) ? "contained" : "outlined"}
              onClick={() => handleTabClick(item)}
            >
              {tab}
            </Button>
          )
        })
      }
    </Stack>
  );
};

export default JumboTabs;
