import Div from '@jumbo/shared/Div'
import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import ScreenPresentationItem from './ScreenPresentationItem'

const ScreenPresentationList = SortableContainer(
  ({ screenPresentations, onUpdate, screenUrl, onDelete }) => {
    return (
      <Div>
        {screenPresentations?.length ? (
          screenPresentations.map((presentation, index) => (
            <ScreenPresentationItem
              key={index}
              presentation={presentation}
              screenUrl={screenUrl}
              index={index}
              handleUpdate={onUpdate}
              handleDelete={onDelete}
            />
          ))
        ) : (
          <JumboListNoDataPlaceHolder />
        )}
      </Div>
    )
  }
)

export default ScreenPresentationList
