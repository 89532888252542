import React from 'react'

const ImagesPreview = ({ module }) => {
  return (
    <img
      width='100%'
      height='100%'
      src={
        module?.parameters?.json_data?.images &&
        module?.parameters?.json_data?.images[0].path
      }
    />
  )
}

export default ImagesPreview
