import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Tab,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Images from "../media/images/Images";
import { PROD_URL } from "app/services/config";
import DeleteIcon from "@mui/icons-material/Delete";
import { CKEditor } from "ckeditor4-react";
import { CK_FONT_SIZE_OPTIONS } from "../presentation/module/form/types";

const FLAGS = ["bio", "vegan", "vegetarian"];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProductCategoryForm = ({
  category,
  onAddCategory,
  onEditCategory,
  onCancel,
  productLanguages,
  customerId,
  title,
}) => {
  const { t, i18n } = useTranslation();
  const ProductCategorySchema = Yup.object().shape({
    label: Yup.object().required(t("pages.articleCategories.labelRequired")),
  });
  const [openImageSelector, setOpenImageSelector] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [initialized, setInitialized] = useState(false);
  const [imageRatio, setImageRatio] = useState(1);

  const imageStyle = {
    height: "auto",
    width: `${(imageRatio || 1) * 200}px`,
    objectFit: "contain",
    cursor: "pointer",
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        label: category?.metadata?.label || "",
        description: category?.metadata?.description || "",
        image: category?.metadata?.image || "",
        price: category?.metadata?.price || "",
        flags: category?.metadata?.flags || [],
      }}
      validationSchema={ProductCategorySchema}
      onSubmit={async (data) => {
        const handler = category ? onEditCategory : onAddCategory;
        handler({ metadata: data, id: category?.id, url: category?.url });
      }}
    >
      {({ setFieldValue, values }) => (
        <Form style={{ textAlign: "left" }} noValidate>
          <Typography variant="h4" pl={2} pb={2} mt={0}>
            {title || ""}
          </Typography>
          <Stack direction="row" gap={4} width="100%" alignItems="center">
            <ImageListItem
              sx={{
                "& .MuiImageListItemBar-root": {
                  transition: "visibility 0s, opacity 0.3s linear",
                  opacity: 0,
                },

                "&:hover .MuiImageListItemBar-root": {
                  visibility: "visible",
                  opacity: 1,
                },
                width: "fit-content",
                height: "min-content !important",
              }}
            >
              <Avatar
                src={values.image || "/images/pages/not_found.svg"}
                variant={"rounded"}
                sx={imageStyle}
                onLoad={(e) => {
                  !initialized &&
                    setImageRatio(e.target.offsetWidth / e.target.offsetHeight);
                  setInitialized(true);
                }}
                onClick={() => setOpenImageSelector(true)}
              />
              {values?.image && (
                <ImageListItemBar
                  subtitle={
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{
                        mb: 0,
                      }}
                    >
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => setFieldValue("image", "")}
                      />
                    </Stack>
                  }
                />
              )}
            </ImageListItem>
            <Stack width="100%">
              <Stack direction="row" gap={2} pl={2}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    type="number"
                    fullWidth
                    name="price"
                    label={t("pages.products.price")}
                    InputProps={{
                      value: values.price,
                      inputProps: {
                        min: 0,
                      },
                      onChange: (e) => setFieldValue("price", e.target.value),
                    }}
                  />
                </FormControl>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    select
                    name="flags"
                    required
                    SelectProps={{
                      multiple: true,
                    }}
                    label={t("pages.productCategories.flags")}
                  >
                    {FLAGS.map((flag, index) => {
                      return (
                        <MenuItem key={index} value={flag}>
                          {t(`pages.productCategories.${flag}`)}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Stack>
              <TabContext value={selectedLanguage}>
                <Stack sx={{ mb: 2 }}>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <TabList
                      onChange={(_, val) => setSelectedLanguage(val)}
                      aria-label="lab API tabs example"
                    >
                      {productLanguages.map((lang) => {
                        return (
                          <Tab
                            key={lang.code}
                            label={lang.label}
                            value={lang.code}
                          />
                        );
                      })}
                    </TabList>
                  </Box>
                  {productLanguages.map((lang) => {
                    return (
                      <TabPanel
                        key={lang.code}
                        value={lang.code}
                        sx={{ pb: 1, pr: 0, minHeight: 350 }}
                      >
                        <Div sx={{ mb: 1, mx: 0 }}>
                          <JumboTextField
                            fullWidth
                            required
                            name={`label.${lang.code}`}
                            label={t("pages.presentation.label")}
                          />
                        </Div>
                        <Typography variant="caption" my={1}>
                          {t("pages.products.description")}
                        </Typography>
                        <CKEditor
                          onChange={(event) => {
                            const data = event.editor.getData();
                            setFieldValue(`description.${lang.code}`, data);
                          }}
                          initData={
                            (values.description &&
                              values.description[lang.code]) ||
                            ""
                          }
                          config={{
                            contentsCss: [
                              `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
                              `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
                            ],
                            height: 75,
                            extraPlugins:
                              "font,colorbutton,justify,colordialog",
                            fontSize_sizes: CK_FONT_SIZE_OPTIONS,
                            colorButton_enableMore: true,
                            versionCheck: false,
                          }}
                        />
                      </TabPanel>
                    );
                  })}
                </Stack>
              </TabContext>
            </Stack>
          </Stack>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: "4px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button size="normal" onClick={onCancel}>
              {t("buttons.cancel")}
            </Button>
            <Button type="submit" variant="contained" size="normal">
              {t("buttons.ok")}
            </Button>
          </Div>
          <Modal
            open={openImageSelector}
            onClose={() => setOpenImageSelector(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Div sx={modalStyle}>
              <Images
                layout={"row-reverse"}
                selectorMode
                onCancel={() => setOpenImageSelector(false)}
                onSelect={(image) => {
                  const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`;
                  setFieldValue("image", imageUrl);
                  setOpenImageSelector(false);
                }}
              />
            </Div>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

export default ProductCategoryForm;
