import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BlockItem from './BlockItem'
import { getSharedBlocks } from 'app/services/api/block'

const SharedBlocks = ({ onAdd }) => {
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()
  const { t } = useTranslation()

  const [sharedBlocks, setSharedBlocks] = useState({})
  const [loading, setLoading] = useState(false)

  const loadBlocks = async () => {
    await getSharedBlocks(customerId).then(blocks => {
      setSharedBlocks(blocks || {})
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    loadBlocks()
  }, [])

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('pages.blocks.sharedBlocks')}
      </Typography>
      {sharedBlocks ? (
        Object.keys(sharedBlocks).map((sharedBlockKey, index) => {
          const sharedBlock = sharedBlocks[sharedBlockKey]
          return (
            <BlockItem
              key={index}
              item={sharedBlock.block}
              disabled={loading}
              handleAdd={onAdd}
            />
          )
        })
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default SharedBlocks
