import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Typography from '@mui/material/Typography'
import DndWrapper from './DndWrapper'
import List from '@mui/material/List'
import { CircularProgress, ListItem } from '@mui/material'
import JumboDemoCard from '@jumbo/components/JumboDemoCard'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { addFile } from 'app/services/api/media'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const getDZOptions = (type, license) => {
  const ACCEPT_TYPES = {
    image: ['jpg', 'jpeg', 'png', 'svg'],
    video: ['mp4']
  }

  const MAX_SIZES = {
    image: license ? 5 : 1,
    video: 25
  }

  const options = {
    image: {
      accept: ACCEPT_TYPES.image.map(type => `image/${type}`).join(','),
      maxSize: MAX_SIZES.image * 1024 * 1024
    },
    video: {
      accept: ACCEPT_TYPES.video.map(type => `video/${type}`).join(','),
      maxSize: MAX_SIZES.video * 1024 * 1024
    }
  }
  return { options: options[type], MAX_SIZES, ACCEPT_TYPES }
}

const DzBasic = ({ type, catalogTreeId, reload }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { getAuthUser } = useJumboAuth()
  const { customerId, customer } = getAuthUser()
  const { kiosk, dsok } = customer
  const { t } = useTranslation()
  const [uploading, setUploading] = useState(false)
  const { options, ACCEPT_TYPES, MAX_SIZES } = getDZOptions(type, dsok || kiosk)
  const handleFileUpload = async (acceptedFiles, fileRejections) => {
    if (!catalogTreeId) {
      return
    }
    setUploading(true)
    if (fileRejections && fileRejections.length) {
      const message = (
        <>
          {`${t('widgets.dropZone.supportedTypes')}: ${ACCEPT_TYPES[type].join(
            ', '
          )}`}
          <br />
          {`${t('widgets.dropZone.maxFileSize')}: ${MAX_SIZES[type]} MB`}
        </>
      )
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setUploading(false)
      return
    }
    if (acceptedFiles && acceptedFiles.length) {
      await addFile(customerId, acceptedFiles, catalogTreeId)
      enqueueSnackbar(t('widgets.dropZone.filesUploaded'), {
        variant: 'success'
      })
      await reload()
    }
    setUploading(false)
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    disabled: !catalogTreeId,
    ...options
  })

  return (
    <Div>
      <DndWrapper>
        {!uploading ? (
          <div
            {...getRootProps()}
            style={{
              width: '100%',
              height: '180px',
              display: 'flex',
              alignItems: 'center',
              p: 2,
              mt: 1,
              mb: 3,
              padding: 8
            }}
          >
            <input {...getInputProps()} />
            <Typography variant={'body1'} align='center'>
              {catalogTreeId
                ? t('widgets.dropZone.dropFiles')
                : t('widgets.dropZone.noFolderSelected')}
            </Typography>
          </div>
        ) : (
          <CircularProgress />
        )}
      </DndWrapper>
    </Div>
  )
}

export default DzBasic
