import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Tooltip, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import {
  addDevice,
  copyDevice,
  deleteDevice,
  getDevices
} from 'app/services/api/kiosk'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import DeviceItem from './DeviceItem'
import DeviceForm from './DeviceForm'
import { useNavigate } from 'react-router-dom'

const Devices = () => {
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()
  const { t } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const navigate = useNavigate()

  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadDevices = async () => {
    await getDevices(customerId).then(devices => {
      const filteredDevices = searchName
        ? devices.filter(device =>
            device.label.toLowerCase().includes(searchName)
          )
        : devices
      setDevices(filteredDevices || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadDevices()
  }, [searchName])

  const handleDelete = async device => {
    hideDialog()
    setLoading(true)
    try {
      await deleteDevice(device.url)
      await loadDevices()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleCopy = async device => {
    hideDialog()
    setLoading(true)
    try {
      const id = await copyDevice(device.url)
      await loadDevices()
      const message = `${t('buttons.copy')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/kiosk/device/${id}`)
    } catch (error) {
      const message = `${t('buttons.copy')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, device) => {
    showDialog({
      variant: 'default',
      title: t(`pages.devices.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <DeviceForm
          device={device}
          onAddDevice={handleAddDevice}
          onCancel={hideDialog}
        />
      )
    })
  }

  const handleAddDevice = async device => {
    setLoading(true)
    hideDialog()
    try {
      const { id } = await addDevice({ settings: { main: device } })
      await loadDevices()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/kiosk/device/${id}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = device => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(device),
      onNo: () => hideDialog()
    })
  }

  const copyConfirmation = device => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSureCopy'),
      onYes: () => handleCopy(device),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.devices')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='devices'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.devices.addDevice')}>
          <IconButton onClick={() => openModal('addDevice')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {devices?.length ? (
        devices.map((device, index) => (
          <DeviceItem
            key={index}
            item={device}
            disabled={loading}
            onDelete={deleteConfirmation}
            onCopy={copyConfirmation}
            onEdit={() => navigate(`/kiosk/device/${device.id}`)}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Devices
