import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useCallback, useContext, useEffect, useState } from 'react'
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom'

function useConfirmExit (confirmExit, when = true) {
  const { navigator } = useContext(NavigationContext)

  useEffect(() => {
    if (!when) {
      return
    }

    const push = navigator.push

    navigator.push = (...args) => {
      confirmExit().then(result => {
        if (result !== false) {
          push(...args)
        }
      })
    }

    return () => {
      navigator.push = push
    }
  }, [navigator, confirmExit, when])
}

export function usePrompt (message, when = true) {
  const { showDialog, hideDialog } = useJumboDialog()
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [message, when])

  const confirmExit = async () => {
    const promise = new Promise(resolve =>
      showDialog({
        variant: 'confirm',
        title: message,
        onYes: () => {
          hideDialog()
          resolve(true)
        },
        onNo: () => resolve(false)
      })
    )

    // const confirm = window.confirm(message)
    const confirm = await promise

    return confirm
  }
  useConfirmExit(confirmExit, when)
}
