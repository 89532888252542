import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import mapValues from "lodash/mapValues";
import { Label } from "app/pages/presentation/module/form/types";

const FIELD_NAME = "MAINTENANCE_FIELD";

export const getMaintenanceFieldSchema = (languages) => {
  const schema = {
    status: "",
    bottomMessage: "",
    dineInSentence: "",
    dineInButtonTitle: "",
    takeAwaySentence: "",
    takeAwayInformation: "",
    takeAwayHelp: "",
    tajeAwayButtonTitle: "",
    pickupOrderText: "",
    message: {},
    bottomMessageStatus: {},
  };
  languages?.forEach((lang) => (schema.message[lang] = ""));
  return;
};

export const getMaintenanceFieldValidationSchema = (t) => ({
  message: Yup.lazy((lazyObj) =>
    Yup.object(
      mapValues(lazyObj, () =>
        Yup.string().required(t("messages.checkRequiredFields"))
      )
    )
  ),
});

const MaintenanceField = ({ handleChange, expanded }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    values.customer.languages &&
      setSelectedLanguage(values.customer.languages[0]);
  }, [values.customer.languages]);

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.maintenance")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name="maintenance.status"
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue("maintenance.status", e.target.checked)
                    }
                  />
                }
                label={
                  <Label
                    text={t("pages.dsokDetails.enableMaintenance")}
                    helpText={t("pages.dsokDetails.enableMaintenanceHelp")}
                    sx={{ color: "text.primary" }}
                    required={false}
                  />
                }
                checked={values.maintenance?.status}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={2}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name="maintenance.bottomMessageStatus"
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue(
                        "maintenance.bottomMessageStatus",
                        e.target.checked
                      )
                    }
                  />
                }
                label={
                  <Label
                    text={t("pages.dsokDetails.bottomMessageStatus")}
                    helpText={t("pages.dsokDetails.bottomMessageStatus")}
                    sx={{ color: "text.primary" }}
                    required={false}
                  />
                }
                checked={values.maintenance?.bottomMessageStatus}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={12}>
            <TabContext value={selectedLanguage}>
              <Stack sx={{ mb: 2 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                    {values.customer.languages?.map((lang) => {
                      return (
                        <Tab
                          key={lang}
                          label={t(`languages.${lang}`)}
                          value={lang}
                        />
                      );
                    })}
                  </TabList>
                </Box>
                {values.customer.languages?.map((lang) => {
                  return (
                    <TabPanel
                      key={`panel_${lang}`}
                      value={lang}
                      sx={{ pb: 1, pr: 0, minHeight: 350, pl: 1 }}
                    >
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.message.${lang}`}
                          label={t(`pages.dsokDetails.message`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.bottomMessage.${lang}`}
                          label={t(`pages.dsokDetails.bottomMessage`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.locationOrderText.${lang}`}
                          label={t(`pages.dsokDetails.locationOrderText`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.pickupOrderText.${lang}`}
                          label={t(`pages.dsokDetails.pickupOrderText`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.dineInSentence.${lang}`}
                          label={t(`pages.dsokDetails.dineInSentence`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.dineInButtonTitle.${lang}`}
                          label={t(`pages.dsokDetails.dineInButtonTitle`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.takeAwaySentence.${lang}`}
                          label={t(`pages.dsokDetails.takeAwaySentence`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.takeAwayButtonTitle.${lang}`}
                          label={t(`pages.dsokDetails.takeAwayButtonTitle`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.takeAwayInformation.${lang}`}
                          label={t(`pages.dsokDetails.takeAwayInformation`)}
                        />
                      </Div>
                      <Div sx={{ mb: 1, mx: 0 }}>
                        <JumboTextField
                          fullWidth
                          required
                          name={`maintenance.takeAwayHelp.${lang}`}
                          label={t(`pages.dsokDetails.takeAwayHelp`)}
                        />
                      </Div>
                    </TabPanel>
                  );
                })}
              </Stack>
            </TabContext>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default MaintenanceField;
