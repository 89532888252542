import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  getRemoteControlMenu,
  updateRemoteControlMenu
} from 'app/services/api/menu'

import { useParams } from 'react-router-dom'
import RemoteControlProduct from './RemoteControlProduct'
import { Form, Formik } from 'formik'
import Div from '@jumbo/shared/Div/Div'
import Logo from 'app/shared/Logo/Logo'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { useSnackbar } from 'notistack'
import moment from 'moment'

const localStorageItemName = 'DV_REMOTE_LOGIN'

function sameDay (d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

const RemoteControlMenu = () => {
  const { customerId, url } = useParams()

  const { t } = useTranslation()
  const [menu, setMenu] = useState()
  const [updating, setUpdating] = useState()
  const [credentials, setCredentials] = useState()
  const [loaded, setLoaded] = useState()
  const [today, setToday] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogin = async (username, password) => {
    const { menu, today } = await loadMenu(username, password)
    if (!menu) {
      enqueueSnackbar(t('pages.login.error'), {
        variant: 'error'
      })
      return
    }
    const todayMoment = moment()
    const oneMonthLater = todayMoment.clone().add(1, 'months')
    const credentials = { username, password, expiration: oneMonthLater }
    localStorage.setItem(localStorageItemName, JSON.stringify(credentials))
    setCredentials(credentials)
    setToday(today)
    setMenu(menu)
    setLoaded(true)
  }

  const loadMenu = async (username, password) => {
    const menu = await getRemoteControlMenu(url, customerId, username, password)
    const today = Object.keys(menu?.metadata || {}).find(day =>
      sameDay(new Date(menu?.metadata[day].date), new Date())
    )
    return { menu, today }
  }

  useEffect(() => {
    try {
      const item = JSON.parse(localStorage.getItem(localStorageItemName))
      setCredentials(item)
      if (moment() < moment(item.expiration)) {
        loadMenu(item.username, item.password).then(({ menu, today }) => {
          setToday(today)
          setMenu(menu)
          setLoaded(true)
        })
      } else {
        setLoaded(true)
      }
    } catch (error) {
      setLoaded(true)
    }
  }, [])

  const handleProductUpdate = async (product, day, type, field, value) => {
    const products =
      type === 'soup' ? menu?.metadata[today].soup : menu?.metadata[today].meal
    const selectedProduct = products.find(
      item => item.number === product.number
    )
    selectedProduct[field] = value
    const newMenu = { ...menu }
    newMenu.metadata[today][type] = products
    setMenu(newMenu)
  }

  const handleMenuUpdate = async () => {
    setUpdating(true)
    try {
      await updateRemoteControlMenu(customerId, menu)
      await loadMenu(credentials.username, credentials.password)
      const message = `${t('buttons.update')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.update')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
    }
    setUpdating(false)
  }

  const Control = () => {
    return credentials &&
      moment() < moment(credentials.expiration) &&
      !today ? (
      <NoMenuWarning />
    ) : (
      <>
        <Typography variant='h1' textAlign='center' py={2}>
          {menu && today && new Date(menu?.metadata[today].date).toDateString()}
        </Typography>
        <Formik
          initialValues={{ username: '', password: '' }}
          onSubmit={() => {}}
        >
          {props => (
            <Form style={{ textAlign: 'left', width: '100%' }} noValidate>
              {!menu ? (
                <>
                  <Div sx={{ mt: 1, mb: 3 }}>
                    <JumboTextField
                      fullWidth
                      name='username'
                      label={t('pages.login.username')}
                    />
                  </Div>
                  <Div sx={{ mt: 1, mb: 2 }}>
                    <JumboTextField
                      fullWidth
                      name='password'
                      label={t('pages.login.password')}
                      type='password'
                    />
                  </Div>
                  <Button
                    fullWidth
                    type='submit'
                    variant='contained'
                    size='large'
                    sx={{ mb: 3 }}
                    loading={updating}
                    onClick={() =>
                      handleLogin(props.values.username, props.values.password)
                    }
                  >
                    {t('pages.login.signIn')}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant='h2' textAlign='center' pb={1} pt={3}>
                    {t('pages.menus.soups')}
                  </Typography>
                  {menu?.metadata[today].soup.map((item, index) => (
                    <RemoteControlProduct
                      item={item}
                      key={index}
                      type='soup'
                      disabled={updating}
                      onUpdate={handleProductUpdate}
                    />
                  ))}
                  <Typography variant='h2' textAlign='center' pb={1} pt={3}>
                    {t('pages.menus.meals')}
                  </Typography>
                  {menu?.metadata[today].meal.map((item, index) => (
                    <RemoteControlProduct
                      item={item}
                      key={index}
                      type='meal'
                      disabled={updating}
                      onUpdate={handleProductUpdate}
                    />
                  ))}
                </>
              )}
            </Form>
          )}
        </Formik>
        {menu && (
          <Button
            variant='contained'
            type='button'
            startIcon={<BrowserUpdatedIcon />}
            sx={{
              mt: 2
            }}
            fullWidth
            size='large'
            disabled={updating}
            onClick={handleMenuUpdate}
          >
            {t('buttons.update')}
          </Button>
        )}
      </>
    )
  }

  const NoMenuWarning = () => (
    <Typography variant='h3' mt={3}>
      {t('pages.menus.noMenuToday')}
    </Typography>
  )

  return (
    <Div
      sx={{
        pt: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Div sx={{ width: 300 }}>
        <Logo />
        {!loaded ? <CircularProgress sx={{ m: '5px 50%' }} /> : <Control />}
      </Div>
    </Div>
  )
}

export default RemoteControlMenu
