import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import {
  Card,
  Typography,
  Modal,
  Pagination,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid,
  CircularProgress,
  MenuItem,
  TextField,
  FormControlLabel,
  Switch,
  InputAdornment
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import JumboSearch from '@jumbo/components/JumboSearch'
import Div from '@jumbo/shared/Div/Div'
import {
  deleteDSOKOrder,
  getCustomer,
  getDSOKOrders,
  sendEkasaRegister
} from 'app/services/api/management'
import DSOKOrderForm from './DSOKOrderForm'
import OrderItem from './OrderItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { useSnackbar } from 'notistack'
import ClearIcon from '@mui/icons-material/Clear'
import jwtAuthAxiosKiosk from 'app/services/auth/jwtAuthKioskStaging'
import { useSocket } from 'app/providers/SocketProvider'
import CloseIcon from '@mui/icons-material/Close'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DSOK_ORDER_STATUSSES } from 'app/utils/constants/settings'
import { isAfterDate, isBeforeDate } from 'app/utils/timeHelpers'

const defaultFilter = {
  device: '',
  dateFrom: null,
  dateTo: null,
  excludeDemoOrders: false,
  orderStatus: ''
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: { md: '70vw', xs: '95vw' },
  maxHeight: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: { md: 4, xs: 1 }
}

const ITEM_PER_PAGE = 20

const DSOKOrders = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customer: { url, customerId }
  } = getAuthUser()

  const { socket, connect, unsubscribe, subscribe } = useSocket()
  const { t } = useTranslation()
  const [searchName, setSearchName] = useState()
  const [orderModal, setOrderModal] = useState()
  const [page, setPage] = useState(1)
  const { showDialog, hideDialog } = useJumboDialog()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [orders, setOrders] = useState([])
  const [defaultOrders, setDefaultOrders] = useState([])
  const [devices, setDevices] = useState([])
  const [filter, setFilter] = useState(defaultFilter)
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState()
  const customerUrl = url || ''

  const loadOrders = async () => {
    setLoading(true)
    await getDSOKOrders().then(orders => {
      const orderArr = Object.keys(orders || {})
        .map(id => ({
          ...orders[id]
        }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

      const filteredOrders = orderArr.filter(order => {
        const conditions = [
          !order.isDeleted,
          filter.device ? order.kioskUUID === filter.device : true,
          filter.orderStatus ? order.orderStatus === filter.orderStatus : true,
          filter.excludeDemoOrders ? !order.demo : true,
          filter.dateFrom
            ? isAfterDate(order.createdAt, filter.dateFrom)
            : true,
          filter.dateTo ? isBeforeDate(order.createdAt, filter.dateTo) : true
        ]
        return conditions.every(Boolean)
      })
      const searchedOrders = searchName
        ? filteredOrders.filter(order => {
            const conditions = [
              `${order?.pickupCode}`?.includes(searchName),
              `${order?.grandTotal}`?.includes(searchName),
              `${order?.createdAt}`?.includes(searchName),
              `${order?.orderStatus}`?.includes(searchName),
              `${order?.locator}`?.includes(searchName)
            ]
            return conditions.some(Boolean)
          })
        : filteredOrders
      setOrders(searchedOrders || [])
      setDefaultOrders(orderArr || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    if (jwtAuthAxiosKiosk.defaults.baseURL) {
      connect(jwtAuthAxiosKiosk.defaults.baseURL)
    }
  }, [jwtAuthAxiosKiosk.defaults.baseURL])

  const handleUpdate = message => {
    if (message === 'refresh') {
      loadOrders()
    }
  }

  useEffect(() => {
    const devices = Object.values(
      customer?.dsokSettings?.customer?.devices || {}
    )
    if (devices.length) {
      setDevices(devices)
      devices.forEach(device => {
        const state = device?.ekasa?.print.paperState || ''
        if (state === 'NearEnd') {
          const message = t('pages.dsokOrders.paperNearEnd', {
            deviceName: device?.deviceName
          })
          enqueueSnackbar(message, {
            variant: 'warning',
            key: device?.uuid || '',
            preventDuplicate: true,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            persist: true,
            action: (
              <IconButton onClick={() => closeSnackbar(device?.uuid)}>
                <CloseIcon />
              </IconButton>
            )
          })
        } else if (state === 'Empty') {
          const message = t('pages.dsokOrders.paperEmpty', {
            deviceName: device?.deviceName
          })
          enqueueSnackbar(message, {
            variant: 'error',
            key: device?.uuid || '',
            preventDuplicate: true,
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            persist: true,
            action: (
              <IconButton onClick={() => closeSnackbar(device?.uuid)}>
                <CloseIcon />
              </IconButton>
            )
          })
        } else {
          device?.uuid && closeSnackbar(device?.uuid)
        }
      })
    }
  }, [customer?.dsokSettings?.customer?.devices])

  useEffect(() => {
    if (socket?.active) {
      subscribe(`admin/dsokOrder/${customerId}`, handleUpdate)
    }
    return () => {
      if (socket?.active) {
        unsubscribe(`admin/dsokOrder/${customerId}`, handleUpdate)
      }
    }
  }, [socket?.active])

  const searchByName = async searchName => {
    setLoading(true)
    if (!jwtAuthAxiosKiosk.defaults.baseURL) {
      await loadCustomer()
    }
    searchName !== undefined && loadOrders()
  }

  useEffect(() => {
    searchByName(searchName)
    setPage(1)
  }, [searchName])

  const loadCustomer = async () => {
    await getCustomer(customerUrl).then(customer => setCustomer(customer))
  }

  const updateFilter = newFilter => {
    setFilter({ ...filter, ...newFilter })
  }

  useEffect(() => {
    setPage(1)
    loadOrders()
  }, [filter])

  useEffect(() => {
    loadCustomer()
  }, [])

  const handleEkasaRegister = async id => {
    setLoading(true)
    hideDialog()
    try {
      await sendEkasaRegister(id)
      await loadOrders()
      const message = `${t('pages.dsokOrders.eKasaRegistration')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('pages.dsokOrders.eKasaRegistration')}  ${t(
        'notifications.wasFailed'
      )}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const handleOrderDelete = async id => {
    setLoading(true)
    hideDialog()
    try {
      await deleteDSOKOrder(id)
      await loadOrders()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')}  ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('pages.dsokOrders.title')}
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} mb={2}>
        <Grid item md={6} xs={12}>
          <Card sx={{ mb: 0, width: '100%' }}>
            <JumboSearch
              page='DSOKOrders'
              onChange={setSearchName}
              sx={{
                width: '100%'
              }}
              placeholder={t('pages.presentation.searchPresentation')}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { md: 'flex-end', xs: 'center' }
            }}
          >
            <Pagination
              count={Math.ceil(orders?.length / ITEM_PER_PAGE)}
              color='primary'
              size={isMobile ? 'small' : 'medium'}
              siblingCount={0}
              boundaryCount={1}
              page={page}
              onChange={(_, val) => setPage(val)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <Card sx={{ px: 3, mb: 3 }}>
          <Grid
            container
            columnSpacing={2}
            rowGap={2}
            mb={2}
            justifyContent='center'
          >
            <Grid item md={2} xs={12}>
              <Div sx={{ mb: 0, mt: 2, mx: 0 }}>
                <TextField
                  select
                  sx={{ width: '100%' }}
                  name={`device`}
                  value={filter.device}
                  onChange={e => updateFilter({ device: e.target.value })}
                  label={t('pages.dsokOrders.device')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          sx={{ p: 0.5 }}
                          onClick={e => updateFilter({ device: '' })}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                >
                  {devices?.map((device, index) => {
                    return (
                      <MenuItem key={index} value={device.uuid}>
                        {device?.deviceName || device?.uuid}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Div>
            </Grid>
            <Grid item md={2} xs={12}>
              <Div sx={{ mt: 2, mb: 0, mx: 0 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t('pages.menus.dateFrom')}
                    sx={{ width: '100%' }}
                    format='dd/MM/yy'
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                        placeholder: ''
                      },
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    onChange={newValue => updateFilter({ dateFrom: newValue })}
                    value={filter.dateFrom}
                  />
                </LocalizationProvider>
              </Div>
            </Grid>
            <Grid item md={2} xs={12}>
              <Div sx={{ mt: 2, mb: 0, mx: 0 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t('pages.menus.dateTo')}
                    sx={{ width: '100%' }}
                    format='dd/MM/yy'
                    slotProps={{
                      textField: {
                        InputLabelProps: { shrink: true },
                        placeholder: ''
                      },
                      actionBar: {
                        actions: ['clear']
                      }
                    }}
                    onChange={newValue => updateFilter({ dateTo: newValue })}
                    value={filter.dateTo}
                  />
                </LocalizationProvider>
              </Div>
            </Grid>
            <Grid item md={2} xs={12}>
              <Div sx={{ mb: 0, mt: 2, mx: 0 }}>
                <TextField
                  select
                  sx={{ width: '100%' }}
                  name={`orderStatus`}
                  value={filter.orderStatus}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <IconButton
                          sx={{ p: 0.5 }}
                          onClick={e => updateFilter({ orderStatus: '' })}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  onChange={e => updateFilter({ orderStatus: e.target.value })}
                  label={t('pages.dsokOrders.orderStatus')}
                >
                  {DSOK_ORDER_STATUSSES?.map((status, index) => {
                    return (
                      <MenuItem key={index} value={status}>
                        {t(`pages.dsokOrders.${status}`)}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </Div>
            </Grid>
            <Grid item md={2} xs={12}>
              <Div sx={{ mt: 3, mb: 1, textAlign: 'start' }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter.excludeDemoOrders}
                      onChange={e =>
                        updateFilter({ excludeDemoOrders: e.target.checked })
                      }
                      name='excludeDemoOrders'
                    />
                  }
                  label={t('pages.dsokOrders.excludeDemoOrders')}
                />
              </Div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {loading ? (
        <CircularProgress sx={{ m: '5px 50%' }} />
      ) : (
        <>
          {orders?.length ? (
            <>
              {orders.map((order, index) => {
                if (
                  index + 1 > ITEM_PER_PAGE * (page - 1) &&
                  index + 1 <= ITEM_PER_PAGE * page
                ) {
                  return (
                    <OrderItem
                      customer={customer}
                      key={index}
                      item={order}
                      currencyCode={
                        customer?.dsokSettings?.customer?.currencyCode
                      }
                      onPreview={() =>
                        setOrderModal({ type: 'previewOrder', order })
                      }
                      onEkasaRegister={id =>
                        showDialog({
                          variant: 'confirm',
                          title: t('pages.dsokOrders.areYouSureToSend'),
                          onYes: () => handleEkasaRegister(id),
                          onNo: () => hideDialog()
                        })
                      }
                      onDelete={id =>
                        showDialog({
                          variant: 'confirm',
                          title: t('widgets.confirmDialog.areYouSureToDelete'),
                          onYes: () => handleOrderDelete(id),
                          onNo: () => hideDialog()
                        })
                      }
                      searchName={searchName}
                    />
                  )
                }
                return null
              })}
            </>
          ) : (
            <JumboListNoDataPlaceHolder />
          )}
        </>
      )}
      <Modal open={orderModal !== undefined} onClose={() => setOrderModal()}>
        <Div sx={modalStyle}>
          <DSOKOrderForm
            title={`#${orderModal?.order?.orderCode || ''} ${
              orderModal?.order?.demo ? ' - DEMO' : ''
            }`}
            order={orderModal?.order}
            onCancel={() => setOrderModal()}
            orders={defaultOrders}
            currencyCode={customer?.dsokSettings?.customer?.currencyCode}
          />
          <IconButton
            onClick={() => setOrderModal()}
            sx={{ position: 'absolute', right: 10, top: 15 }}
          >
            <ClearIcon />
          </IconButton>
        </Div>
      </Modal>
    </React.Fragment>
  )
}

export default DSOKOrders
