import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack,
  Chip,
  FormControlLabel,
  Switch
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { Field } from 'formik'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />
))

const ProductItemSortable = ({ item, onUpdate, type, day, onDelete }) => {
  const { t, i18n } = useTranslation()
  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <DragHandle />
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.screens.status')}
            </Typography>
            <Div sx={{ mt: 0, mb: 0, mx: 0 }}>
              <FormControlLabel
                control={
                  <Field
                    name={'active'}
                    component={Switch}
                    onChange={e =>
                      onUpdate(item, day, type, 'active', e.target.checked)
                    }
                  />
                }
                // label={t('pages.products.active')}
                checked={item.active}
              />
            </Div>
          </Div>
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{item.number}</Typography>
            </Stack>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 1 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.products.image')}
            </Typography>
            <Typography variant={'body1'}>
              <img src={item.image} width={75} />
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Div sx={{ mt: 1, mb: 2, ml: 2, mr: 0, width: '100%' }}>
              <JumboTextField
                fullWidth
                name='productLabel'
                multiline
                InputProps={{
                  value: item.label,
                  onChange: e =>
                    onUpdate(item, day, type, 'label', e.target.value)
                }}
                label={t('pages.presentation.label')}
              />
            </Div>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Div sx={{ mt: 1, mb: 2, ml: 2, mr: 2, width: '100%' }}>
              <JumboTextField
                type='number'
                fullWidth
                name='price'
                label={t('pages.products.price')}
                InputLabelProps={{ required: false }}
                InputProps={{
                  value: item.price,
                  inputProps: {
                    min: 0
                  },
                  onChange: e =>
                    onUpdate(item, day, type, 'price', e.target.value)
                }}
              />
            </Div>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              flexShrink: 0,
              px: 1
            }}
          >
            <Div sx={{ mt: 1, mb: 2, ml: 2, mr: 2, width: '100%' }}>
              <JumboTextField
                type='number'
                fullWidth
                name='salePrice'
                label={t('pages.products.salePrice')}
                InputLabelProps={{ required: false }}
                InputProps={{
                  value: item.salePrice,
                  inputProps: {
                    min: 0
                  },
                  onChange: e =>
                    onUpdate(item, day, type, 'salePrice', e.target.value)
                }}
              />
            </Div>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              flexShrink: 0,
              px: 1,
              display: 'flex',
              pb: 1,
              justifyContent: 'center'
            }}
          >
            <IconButton>
              <DeleteForeverIcon onClick={() => onDelete(item, day, type)} />
            </IconButton>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default SortableElement(ProductItemSortable)
