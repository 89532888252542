import jwtAuthAxios from '../auth/jwtAuth'

export const getProductCategory = async (url, customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/product-category/get?url=${url}&customerId=${customerId}`
  )
  return data.product
}

export const addProductCategory = async (customerId, category) => {
  const { data } = await jwtAuthAxios.post('/product-category/add', {
    customerId,
    ...category,
    code: 'allergens'
  })
  return data.url
}

export const editProductCategory = async (customerId, category) => {
  const { data } = await jwtAuthAxios.post('/product-category/update', {
    customerId,
    ...category,
    code: 'allergens'
  })
  return data
}

export const deleteProductCategory = async (url, customerId) => {
  const { data } = await jwtAuthAxios.delete(
    `/product-category/delete?url=${url}&customerId=${customerId}`
  )
  return data
}
