import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  Table,
  TableBody,
  Typography
} from '@mui/material'
import JumboColorPickerField from '@jumbo/components/JumboFormik/JumboColorPickerField'
import CollectionsIcon from '@mui/icons-material/Collections'
import CancelIcon from '@mui/icons-material/Cancel'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import Images from 'app/pages/media/images/Images'
import { PROD_URL } from 'app/services/config'

const BlockSettings = ({
  block: {
    modules,
    parameters: {
      name,
      block_background_color,
      block_background_image,
      block_width,
      block_height,
      block_position_x,
      block_position_y,
      block_background_opacity,
      can_share
    }
  },
  children
}) => {
  const { t } = useTranslation()
  const [openImageSelector, setOpenImageSelector] = useState(false)
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        name,
        block_background_color,
        block_background_image,
        block_width,
        block_height,
        block_position_x,
        block_position_y,
        block_background_opacity,
        can_share
      }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        // await onReset(data.email);
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              name='name'
              label={t('pages.presentation.label')}
            />
          </Div>
          {values.resolution === 'custom' && (
            <>
              <Div sx={{ mt: 1, mb: 1, mx: 2 }}>
                <JumboTextField
                  fullWidth
                  type='number'
                  name='presentation_width'
                  label={t('pages.presentation.width')}
                />
              </Div>
              <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
                <JumboTextField
                  fullWidth
                  type='number'
                  name='presentation_height'
                  label={t('pages.presentation.height')}
                />
              </Div>
            </>
          )}
          <Div sx={{ mt: -0.5, mb: 1, mx: 2 }}>
            <Typography
              variant='caption'
              color='text.secondary'
              sx={{
                margin: '0 0 0 10px',
                background: 'white',
                color: 'text.secondary',
                width: 'fit-content',
                paddingX: 1
              }}
            >
              {t('pages.presentation.backgroundColor')}
            </Typography>
            <JumboColorPickerField
              sx={{ height: '51px', borderRadius: '4px', mt: '-10px', p: 1 }}
              name={'block_background_color'}
            />
          </Div>
          <Div sx={{ mt: 2, mb: 2, mx: 2, gap: '4px', display: 'flex' }}>
            <Button
              variant='contained'
              size='small'
              startIcon={<CollectionsIcon />}
              onClick={() => setOpenImageSelector(true)}
            >
              {t('pages.presentation.selectBackgroundImage')}
            </Button>
            <IconButton
              size='small'
              onClick={() => setFieldValue('block_background_image', 'removed')}
            >
              <CancelIcon />
            </IconButton>
          </Div>
          <Div sx={{ mt: 3, mb: 2, mx: 2 }}>
            <JumboTextField
              type='number'
              fullWidth
              value={values['block_width']}
              name='block_width'
              label={t('pages.blocks.width')}
              InputProps={{
                onChange: e => setFieldValue('block_width', `${e.target.value}`)
              }}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            <JumboTextField
              type='number'
              fullWidth
              value={values['block_height']}
              name='block_height'
              label={t('pages.blocks.height')}
              InputProps={{
                onChange: e =>
                  setFieldValue('block_height', `${e.target.value}`)
              }}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            <JumboTextField
              type='number'
              fullWidth
              value={values['block_background_opacity']}
              name='block_background_opacity'
              label={t('pages.blocks.opacity')}
              InputProps={{
                inputProps: {
                  max: 10,
                  min: 1
                },
                onChange: e =>
                  setFieldValue('block_background_opacity', `${e.target.value}`)
              }}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            <JumboTextField
              type='number'
              fullWidth
              value={values['block_position_x']}
              name='block_position_x'
              label={t('pages.blocks.positionX')}
              InputProps={{
                onChange: e =>
                  setFieldValue('block_position_x', `${e.target.value}`)
              }}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            <JumboTextField
              type='number'
              fullWidth
              value={values['block_position_y']}
              name='block_position_y'
              label={t('pages.blocks.positionY')}
              InputProps={{
                onChange: e =>
                  setFieldValue('block_position_y', `${e.target.value}`)
              }}
            />
          </Div>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            <FormControlLabel
              control={
                <Field
                  name={'can_share'}
                  component={Switch}
                  onChange={e => setFieldValue('can_share', e.target.checked)}
                />
              }
              label={<FormLabel>{t('pages.blocks.shareBlock')}</FormLabel>}
              checked={values['can_share']}
            />
          </Div>
          <Modal
            open={openImageSelector}
            onClose={() => setOpenImageSelector(false)}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Div sx={modalStyle}>
              <Images
                layout={'row-reverse'}
                selectorMode
                onCancel={() => setOpenImageSelector(false)}
                onSelect={image => {
                  const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`
                  setFieldValue('block_background_image', imageUrl)
                  setOpenImageSelector(false)
                }}
              />
            </Div>
          </Modal>
          {children}
        </Form>
      )}
    </Formik>
  )
}

export default BlockSettings
