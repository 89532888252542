import { SortableContainer } from 'react-sortable-hoc'
import BindingItemSortable from './BindingItemSortable'
import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'

const BindingItems = SortableContainer(
  ({
    bindings,
    onAddGroup,
    onAddBinding,
    defaultLanguage,
    onDelete,
    allProducts
  }) => {
    const [bindingList, setBindingList] = useState([])
    useEffect(() => {
      const bindingList = Object.keys(bindings || {})
        .map((key, i) => {
          if (['name', 'min', 'max'].includes(key)) {
            return null
          }
          const binding = bindings[key]
          const index = binding.index === undefined ? i : binding.index

          return { ...binding, code: key, index }
        })
        .filter(binding => binding)
        .sort((b1, b2) => b1.index - b2.index)
      setBindingList(bindingList)
    }, [bindings])
    return (
      <Grid container columnSpacing={2} rowSpacing={2}>
        {bindingList.map(binding => {
          return (
            <BindingItemSortable
              key={`${new Date().getTime()}_${binding.index}`}
              index={binding.index}
              code={binding.code}
              binding={binding}
              defaultLanguage={defaultLanguage}
              allProducts={allProducts}
              onAddGroup={onAddGroup}
              onAddBinding={onAddBinding}
              onDelete={onDelete}
            />
          )
        })}
      </Grid>
    )
  }
)

export default BindingItems
