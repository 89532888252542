import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import { BooleanField } from 'app/pages/presentation/module/form/types'
import { useEffect } from 'react'
import { getGalleries } from 'app/services/api/media'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import Div from '@jumbo/shared/Div/Div'
import { Field, useFormikContext } from 'formik'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'

const FIELD_NAME = 'TRANSLATIONS_FIELD'

const translatedFields = [
  { name: 'welcome', limit: { max: 10 } },
  { name: 'clickHere' },
  { name: 'phone' },
  { name: 'email' },
  { name: 'today' },
  { name: 'theMenu' },
  { name: 'monday' },
  { name: 'tuesday' },
  { name: 'wednesday' },
  { name: 'thursday' },
  { name: 'friday' },
  { name: 'saturday' },
  { name: 'sunday' }
]

export const getTranslationsFieldSchema = kioskLanguages => {
  const schema = {}
  if (!kioskLanguages?.length) {
    return schema
  }
  kioskLanguages.forEach(language => {
    schema[language.code] = {}
    translatedFields.forEach(field => (schema[language.code][field.name] = ''))
  })
  return schema
}

const TranslationsField = ({ handleChange, expanded }) => {
  const { t, i18n } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { kioskSettings }
  } = getAuthUser()
  const { values, setFieldValue } = useFormikContext()

  const [selectedLanguage, setSelectedLanguage] = useState(
    values.main?.languages?.[0]
  )

  useEffect(() => {
    if (
      !selectedLanguage ||
      !values.main?.languages?.includes(selectedLanguage)
    ) {
      setSelectedLanguage(values.main?.languages?.[0])
    }
  }, [values.main?.languages])

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.editDevice.translations')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {!values.main?.languages?.length ? (
          <Typography
            variant='caption'
            color='text.secondary'
            sx={{
              margin: '0 0 0 10px',
              background: 'white',
              color: 'text.secondary',
              width: 'fit-content'
            }}
          >
            {t('pages.editDevice.selectLanguage')}
          </Typography>
        ) : (
          selectedLanguage && (
            <TabContext value={selectedLanguage}>
              <Stack sx={{ mb: 2 }}>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: 'divider'
                  }}
                >
                  <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                    {values.main?.languages?.map(lang => {
                      return (
                        <Tab
                          key={lang}
                          label={t(`languages.${lang}`)}
                          value={lang}
                        />
                      )
                    })}
                  </TabList>
                </Box>
                {values.main?.languages.map(lang => {
                  return (
                    <TabPanel
                      key={lang}
                      value={lang}
                      sx={{ pb: 1, pr: 0, minHeight: 350 }}
                    >
                      <Grid container spacing={2}>
                        {translatedFields.map(({ name, limit }) => (
                          <Grid item xs={12} md={4} key={name}>
                            <Div sx={{ mb: 1, mx: 0 }}>
                              <JumboTextField
                                fullWidth
                                name={`translations.${lang}.${name}`}
                                label={t(`pages.editDevice.${name}`)}
                                inputProps={{ maxLength: limit?.max }}
                              />
                            </Div>
                          </Grid>
                        ))}
                      </Grid>
                    </TabPanel>
                  )
                })}
              </Stack>
            </TabContext>
          )
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default TranslationsField
