import jwtAuthAxios from '../auth/jwtAuth'

export const getDailyOffer = async (url, customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/restaurant-menu/get?url=${url}&customerId=${customerId}`
  )
  return data.response
}

export const getDailyOffers = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/restaurant-menu/list?customerId=${customerId}`
  )
  return data.response && data.response.filter(({ type }) => type === 'offer')
}

export const addDailyOffer = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post('/restaurant-menu/add', {
    customerId,
    ...menu,
    type: 'offer'
  })
  return data.url
}

export const editDailyOffer = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post('/restaurant-menu/update', {
    ...menu,
    customerId,
    type: 'offer'
  })
  return data
}

export const deleteDailyOffer = async (url, customerId) => {
  const { data } = await jwtAuthAxios.delete(
    `/restaurant-menu/delete?url=${url}&customerId=${customerId}`
  )
  return data
}
