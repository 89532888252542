import jwtAuthAxios from '../auth/jwtAuth'

export const getScreens = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/screen/all?customerId=${customerId}`
  )
  return data.screens
}

export const getScreenPresentations = async url => {
  const { data } = await jwtAuthAxios.get(`/screen/presentations?url=${url}`)
  return data
}

export const updateScreen = async screen => {
  const { data } = await jwtAuthAxios.put(`/screen/update`, screen)
  return data
}

export const addScreen = async screen => {
  const { data } = await jwtAuthAxios.post(`/screen/add`, screen)
  return data.screenUrl
}

export const addPresentationToScreen = async (screenUrl, presentationUrl) => {
  const { data } = await jwtAuthAxios.post(`/screen/presentation/add`, {
    screenUrl,
    presentationUrl
  })
  return data
}
export const deletePresentationFromScreen = async (
  screenUrl,
  presentationUrl
) => {
  const { data } = await jwtAuthAxios.delete(
    `/screen/presentation/delete?screenUrl=${screenUrl}&presentationUrl=${presentationUrl}`
  )
  return data
}

export const refreshScreen = async url => {
  const { data } = await jwtAuthAxios.post('/screen/refresh', { url })
  return data
}

export const deleteScreen = async url => {
  const { data } = await jwtAuthAxios.delete(`/screen/delete?screenUrl=${url}`)
  return data
}
