import React from 'react'
import Div from '@jumbo/shared/Div'

const DndWrapper = ({ children, sx }) => {
  return (
    <Div
      className='dropzone'
      sx={{
        display: 'flex',
        width: { sm: '100px', lg: '250px', md: '150px' },
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 120,
        borderRadius: 1,
        border: '2px dashed #BBB',
        bgcolor: theme => theme.palette.action.hover,
        ...sx
      }}
    >
      {children}
    </Div>
  )
}

export default DndWrapper
