import React from 'react'
import AccordionSummary from '@mui/material/AccordionSummary'
import {
  Accordion,
  IconButton,
  Card,
  Typography,
  Tooltip,
  Link,
  Stack
} from '@mui/material'
import Div from '@jumbo/shared/Div'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import LoginIcon from '@mui/icons-material/Login'
import GradingIcon from '@mui/icons-material/Grading'
import { useNavigate } from 'react-router-dom'

const CustomerItem = ({ item, disabled, onLogin, onEdit }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const user = item.users[0]

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            px: 3,
            flexDirection: 'row-reverse',
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'space-between',

              '&.Mui-expanded': {
                margin: '12px 0'
              }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
              color: 'text.secondary',
              transform: 'none',
              height: 28,
              width: 28,
              alignItems: 'center',
              justifyContent: 'center',
              mr: 1,

              '&.Mui-expanded': {
                transform: 'none',
                color: 'primary.main',
                borderColor: 'primary.main'
              },

              '& svg': {
                fontSize: '1.25rem'
              }
            }
          }}
        >
          <Div
            sx={{
              display: { xs: 'none', md: 'block' },
              width: '10%',
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
            >
              {t('pages.presentation.id')}
            </Typography>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography variant={'body1'}>{item.id}</Typography>
            </Stack>
          </Div>
          <Div sx={{ flex: { xs: '0 1 auto', md: 2 }, flexShrink: 0, px: 1 }}>
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.customers.companyName')}
            </Typography>
            <Typography variant={'body1'}>{item.companyName}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              display: { xs: 'none', md: 'block' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.screens.status')}
            </Typography>
            <Typography variant={'body1'}>{item.status}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              display: { xs: 'none', md: 'block' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.customers.city')}
            </Typography>
            <Typography variant={'body1'}>{item.city}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              display: { xs: 'none', md: 'block' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.customers.country')}
            </Typography>
            <Typography variant={'body1'}>{item.country}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 2 },
              display: { xs: 'none', md: 'block' },
              flexShrink: 0,
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.customers.maxScreens')}
            </Typography>
            <Typography variant={'body1'}>{item.maxScreens}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: '0 2 auto', md: 1 },
              px: 1
            }}
          >
            <Typography
              fontSize={'12px'}
              variant={'h6'}
              color={'text.secondary'}
              mb={0.25}
              sx={{
                display: { xs: 'none', md: 'block' }
              }}
            >
              {t('pages.presentation.actions')}
            </Typography>
            <Div
              sx={{
                display: 'flex',
                flex: { direction: 'row', flexWrap: 'wrap' },
                minWidth: 110
              }}
            >
              <Div>
                <IconButton
                  size='small'
                  variant={'contained'}
                  disabled={true}
                  onClick={onEdit}
                >
                  <Tooltip title={t('buttons.edit')} placement='top-start'>
                    <EditIcon />
                  </Tooltip>
                </IconButton>
                {item.dsok && (
                  <IconButton
                    size='small'
                    variant={'contained'}
                    onClick={() => navigate(`/customer/${item.url}/dsok`)}
                  >
                    <Tooltip
                      title={t('pages.customers.dsokDetails')}
                      placement='top-start'
                    >
                      <GradingIcon />
                    </Tooltip>
                  </IconButton>
                )}

                {user && (
                  <IconButton
                    size='small'
                    variant={'contained'}
                    disabled={disabled}
                    onClick={() => onLogin(item)}
                  >
                    <Tooltip
                      title={t('buttons.loginAsCustomer')}
                      placement='top-start'
                    >
                      <LoginIcon />
                    </Tooltip>
                  </IconButton>
                )}
              </Div>
            </Div>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  )
}
/* Todo item prop define */
export default CustomerItem
