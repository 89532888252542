import Div from '@jumbo/shared/Div'
import React, { useEffect, useState } from 'react'
import EditModule from '../EditModule'
import ModuleSettings from '../ModuleSettings'
import { TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormController from '../form/FormController'

const InfoTextLineModule = ({ module, presentation, loadModule }) => {
  const { parameters } = module
  const { parameters_settings } = parameters
  const { fields } = parameters_settings
  const [settings, setSettings] = useState({})
  const [errors, setErrors] = useState({})
  const [newTextAreaData, setNewTextAreaData] = useState()

  const currentLanguage = window.localStorage.i18nextLng

  useEffect(() => {
    setSettings(module?.parameters)
    setNewTextAreaData(settings?.text_area_simple)
  }, [module])

  const handleTextChange = text => {
    setNewTextAreaData(text.currentTarget.value)
  }

  return (
    <EditModule
      type='infoTextLine'
      module={module}
      presentation={presentation}
      loadModule={loadModule}
      content={
        <Div sx={{ px: 1, my: 2 }}>
          <FormControl fullWidth>
            <TextField
              name='text_area_simple'
              multiline
              rows={15}
              label={fields?.text_area_simple?.helpText[currentLanguage]}
              defaultValue={settings.text_area_simple}
              onChange={handleTextChange}
            />
          </FormControl>
        </Div>
      }
      settings={
        module &&
        module.parameters && (
          <ModuleSettings module={module}>
            <FormController setSettings={setSettings} setErrors={setErrors} />
          </ModuleSettings>
        )
      }
      updateValue={{
        settings: {
          url: module?.setting?.url,
          parameters: { ...settings, text_area_simple: newTextAreaData }
        },
        errors: errors
      }}
    />
  )
}

export default InfoTextLineModule
