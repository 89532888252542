import Div from '@jumbo/shared/Div/Div.js'
import { useContainerDimensions } from 'app/hooks/useContainerDimensions.js'
import { BE_URL, PROD_URL } from 'app/services/config.js'
import update from 'immutability-helper'
import { useCallback, useState, useRef, useEffect } from 'react'
import { useDragLayer, useDrop } from 'react-dnd'
import ModulePreview from '../module/previews/ModulePreview.js'
import { DraggableBox } from './DraggableBox.js'

export const PresentationPreview = ({
  presentation: { blocks, url, presentation_parameters, sourceId },
  backgroundColor,
  borderColor,
  backgroundImage,
  onChange,
  setBoxUpdater
}) => {
  const {
    presentation_width,
    presentation_height,
    background_color,
    border_color
  } = presentation_parameters
  const selectedBackgroundColor = backgroundColor || background_color
  const selectedBorderColor = borderColor || border_color
  const ref = useRef(null)
  const { width, height } = useContainerDimensions(ref)
  const widthRatio = width / presentation_width
  const heightRatio = height / presentation_height
  const selectedBackgroundImage =
    backgroundImage || presentation_parameters?.background_image
  const [bgBlob, setBgBlob] = useState('')
  const calculateBlockCoords = (
    posX,
    posY,
    width,
    height,
    widthRatio,
    heightRatio
  ) => {
    return {
      x: posX * widthRatio,
      y: posY * heightRatio,
      width: width * widthRatio,
      height: height * heightRatio
    }
  }

  const [boxes, setBoxes] = useState({})
  const { item, currentOffset } = useDragLayer(monitor => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging()
  }))
  const movingBox = item ? boxes[item.id] : { width: 0, height: 0, x: 0, y: 0 }

  const updateBoxes = (block, boxes) => {
    if (block) {
      const {
        block_settings: {
          block_position_x,
          block_position_y,
          block_width,
          block_height,
          block_label,
          block_url,
          block_background_color,
          block_id,
          is_shared
        },
        css,
        modules
      } = block
      const { x, y, width, height } = calculateBlockCoords(
        block_position_x,
        block_position_y,
        block_width,
        block_height,
        widthRatio,
        heightRatio
      )
      const moduleUrl = modules && Object.values(modules)[0].module_settings.url
      const box = {
        x,
        y,
        width,
        height,
        moduleUrl,
        label: block_label,
        block_url,
        block_background_color,
        zIndex: css.zIndex,
        is_shared: is_shared
      }
      setBoxes({ ...boxes, [block_id]: box })
    }
  }

  useEffect(() => {
    if (setBoxUpdater) {
      setBoxUpdater(() => block => updateBoxes(block, boxes))
    }
  }, [setBoxUpdater, boxes])

  useEffect(() => {
    if (blocks) {
      const boxes = {}
      Object.keys(blocks).forEach(key => {
        const {
          block_settings: {
            block_position_x,
            block_position_y,
            block_width,
            block_height,
            block_label,
            block_url,
            block_background_color,
            block_background_image,
            is_shared
          },
          css,
          modules
        } = blocks[key]
        const { x, y, width, height } = calculateBlockCoords(
          block_position_x,
          block_position_y,
          block_width,
          block_height,
          widthRatio,
          heightRatio
        )
        const moduleUrl =
          modules && Object.values(modules)[0].module_settings.url
        boxes[key] = {
          x,
          y,
          width,
          height,
          moduleUrl,
          label: block_label,
          block_url,
          block_background_color,
          block_background_image,
          zIndex: css.zIndex,
          is_shared: is_shared
        }
      })
      setBoxes(boxes)
    }
  }, [blocks, widthRatio, heightRatio])

  useEffect(() => {
    onChange({ boxes, widthRatio, heightRatio })
  }, [boxes])

  useEffect(() => {
    const fetchData = async () => {
      const blob = await fetch(
        selectedBackgroundImage.replace(PROD_URL, BE_URL)
      ).then(r => r.blob())
      setBgBlob(URL.createObjectURL(blob))
    }
    if (selectedBackgroundImage) {
      fetchData()
    }
  }, [selectedBackgroundImage])

  const moveBox = useCallback(
    (id, x, y) => {
      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { x, y }
          }
        })
      )
    },
    [boxes]
  )
  const [, drop] = useDrop(
    () => ({
      accept: 'BOX',
      drop (item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset()
        let left = Math.round(item.x + delta.x)
        let top = Math.round(item.y + delta.y)
        moveBox(item.id, left, top)
        return undefined
      }
    }),
    [moveBox]
  )

  const handleResize = (url, { width, height }) =>
    setBoxes({
      ...boxes,
      [url]: {
        ...boxes[url],
        width: width,
        height: height
      }
    })
  const truncate = (val, lowerLimit, upperLimit) =>
    val > upperLimit ? upperLimit : val < lowerLimit ? lowerLimit : val

  const x = currentOffset ? currentOffset?.x - 297 : movingBox?.x
  const y = currentOffset ? currentOffset?.y - 147 : movingBox?.y
  const limitX = presentation_width * widthRatio - movingBox?.width
  const limitY = presentation_height * heightRatio - movingBox?.height
  const limitedX = truncate(x, 0, limitX)
  const limitedY = truncate(y, 0, limitY)
  return (
    <Div
      sx={{
        width: presentation_width > presentation_height ? '100%' : 'initial',
        height: presentation_height > presentation_width ? '75vh' : 'initial',
        aspectRatio: `${presentation_width} / ${presentation_height}`,
        border: 'solid 1px',
        borderColor: selectedBorderColor || 'divider',
        position: 'relative',
        backgroundColor: selectedBackgroundColor,
        backgroundSize: `${width}px 100%`,
        backgroundImage: bgBlob && `url("${bgBlob}")`
      }}
      ref={ref}
    >
      <Div
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
        ref={drop}
      >
        {Object.keys(boxes)?.map(key => {
          const box = boxes[key]
          return (
            <DraggableBox
              key={key}
              id={key}
              presentationSize={{
                width: presentation_width * widthRatio,
                height: presentation_height * heightRatio
              }}
              presentationUrl={url}
              selectedBorderColor={selectedBorderColor || 'divider'}
              onResize={handleResize}
              template={sourceId}
              moveBox={() => moveBox(key, limitedX, limitedY)}
              {...box}
            />
          )
        })}
        {movingBox && (
          <Div
            sx={{
              border: 'solid 1px',
              borderColor: selectedBorderColor || 'primary',
              position: 'absolute',
              cursor: 'move',
              width: movingBox.width,
              height: movingBox.height,
              opacity: movingBox.width ? 1 : 0,
              top: limitedY,
              left: limitedX,
              zIndex: movingBox.zIndex,
              backgroundColor: movingBox.block_background_color,
              pointerEvents: 'none'
            }}
          >
            <ModulePreview moduleUrl={movingBox.moduleUrl} />
          </Div>
        )}
      </Div>
    </Div>
  )
}
