import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Button, Stack, Tab } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const ProductAttributeForm = ({
  attribute,
  onAddAttribute,
  onEditAttribute,
  onCancel,
  productLanguages
}) => {
  const { t, i18n } = useTranslation()
  const ProductAttributeSchema = Yup.object().shape({
    label: Yup.object().required(t('pages.articleCategories.labelRequired'))
  })
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  return (
    <Formik
      validateOnChange={true}
      initialValues={
        { label: attribute?.metadata, number: attribute?.metadata?.number } || {
          label: '',
          number: ''
        }
      }
      validationSchema={ProductAttributeSchema}
      onSubmit={async data => {
        const handler = attribute ? onEditAttribute : onAddAttribute
        handler(data, attribute?.url)
      }}
    >
      {props => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 0, mb: 1, mx: 0, pr: 0, pl: 0, width: '100%' }}>
            <JumboTextField
              type='number'
              fullWidth
              name='number'
              value={props.values.number}
              label={t('pages.productAttributes.number') + '*'}
              InputLabelProps={{ required: false }}
              InputProps={{
                inputProps: {
                  min: 0
                },
                onChange: e => props.setFieldValue('number', e.target.value)
              }}
            />
          </Div>
          <TabContext value={selectedLanguage}>
            <Stack width='100%'>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider'
                }}
              >
                <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                  {productLanguages.map(lang => {
                    return (
                      <Tab
                        key={lang.code}
                        label={lang.label}
                        value={lang.code}
                      />
                    )
                  })}
                </TabList>
              </Box>
              {productLanguages.map(lang => {
                return (
                  <TabPanel
                    key={lang.code}
                    value={lang.code}
                    sx={{ pb: 1, pr: 0, px: 0 }}
                  >
                    <Div sx={{ mt: 2, mb: 1, mx: 0 }}>
                      <JumboTextField
                        fullWidth
                        required
                        name={`label.${lang.code}`}
                        label={t('pages.presentation.label')}
                      />
                    </Div>
                  </TabPanel>
                )
              })}
            </Stack>
          </TabContext>
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: '4px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button size='normal' onClick={onCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='normal'>
              {t('buttons.ok')}
            </Button>
          </Div>
        </Form>
      )}
    </Formik>
  )
}

export default ProductAttributeForm
