import React from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import { SketchPicker } from 'react-color'
import { useField, useFormikContext } from 'formik'
import Div from '@jumbo/shared/Div'
import { IconButton } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { getNestedFormValue } from 'app/utils/objectHelpers'

function isColorBright (color) {
  if (color) {
    const hex = color.replace('#', '')
    const c_r = parseInt(hex.substring(0, 0 + 2), 16)
    const c_g = parseInt(hex.substring(2, 2 + 2), 16)
    const c_b = parseInt(hex.substring(4, 4 + 2), 16)
    const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
    return brightness > 155
  }
}

const JumboColorPickerField = ({ onSelect, name, ...restProps }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { setFieldValue, values } = useFormikContext()
  const value = getNestedFormValue(values, name)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onColorSelect = color => {
    if (onSelect) {
      onSelect(color.hex)
    }
    setFieldValue(name, color.hex)
  }

  const id = open ? 'simple-popover' : undefined

  const textColor = isColorBright(value) ? 'text.primary' : '#FFFFFF'

  return (
    <Div
      sx={{
        border: 1,
        borderColor: 'text.secondary',
        p: 0.5,
        display: 'flex',
        flex: 1,
        minWidth: 40,
        minHeight: 38,
        ...restProps.sx
      }}
    >
      <Div
        onClick={handleClick}
        sx={{
          flex: 1,
          p: 1,
          pr: 0,
          bgcolor: value,
          color: textColor,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <span>{value?.toUpperCase() || ''}</span>
      </Div>
      {value && (
        <Div sx={{ bgcolor: value }}>
          <IconButton
            size='small'
            onClick={() => onColorSelect({ hex: 'null' })}
          >
            <CancelIcon sx={{ color: textColor }} />
          </IconButton>
        </Div>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SketchPicker color={value} onChange={onColorSelect} />
      </Popover>
    </Div>
  )
}

JumboColorPickerField.propTypes = {
  onSelect: PropTypes.func
}

export default JumboColorPickerField
