import Div from '@jumbo/shared/Div'
import Images from 'app/pages/media/images/Images'
import React, { useEffect, useState } from 'react'
import EditModule from '../EditModule'
import { useTranslation } from 'react-i18next'
import ModuleSettings from '../ModuleSettings'
import { Form, Formik } from 'formik'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import {
  Avatar,
  Grid,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Stack
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { TextField } from '@mui/material'
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import DragHandleIcon from '@mui/icons-material/DragHandle'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import { BE_URL } from 'app/services/config'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import FormController from '../form/FormController'

const ArticleTab = ({
  article: { title1, title2, text, image, visible_from, visible_to },
  index,
  value,
  onChange
}) => {
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()
  const [imageRatio, setImageRatio] = useState(1)
  const [initialized, setInitialized] = useState(false)
  const [openImageSelector, setOpenImageSelector] = useState()
  const [article, setArticle] = useState({
    title1,
    title2,
    text,
    image,
    visible_from,
    visible_to
  })
  const imageStyle = {
    height: 'auto',
    width: `${(imageRatio || 1) * 150}px`,
    objectFit: 'contain',
    cursor: 'pointer'
  }
  useEffect(() => {
    if (onChange) {
      onChange(index, article)
    }
  }, [article])

  useEffect(() => {
    setArticle({ title1, title2, text, image, visible_from, visible_to })
  }, [title1, title2, text, image, visible_from, visible_to])

  const { t } = useTranslation()
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }
  const handleImageSelect = image => {
    const imageUrl = `${BE_URL}/uploads/files/${customerId}/Images/${image.filename}`
    setArticle({ ...article, image: imageUrl })
    setOpenImageSelector()
  }
  return (
    <Div
      sx={{
        visibility: index !== value && 'hidden',
        display: index !== value && initialized && 'none'
      }}
    >
      <Formik>
        <Form style={{ textAlign: 'left' }} noValidate>
          <Stack direction={'row'} spacing={2} display='flex' sx={{ mb: 2 }}>
            <ImageListItem
              sx={{
                '& .MuiImageListItemBar-root': {
                  transition: 'visibility 0s, opacity 0.3s linear',
                  opacity: 0
                },

                '&:hover .MuiImageListItemBar-root': {
                  visibility: 'visible',
                  opacity: 1
                },
                width: 'fit-content',
                height: 'auto'
              }}
            >
              <Avatar
                src={image || '/images/pages/not_found.svg'}
                variant={'rounded'}
                sx={imageStyle}
                onLoad={e => {
                  !initialized &&
                    setImageRatio(e.target.offsetWidth / e.target.offsetHeight)
                  setInitialized(true)
                }}
                onClick={() => setOpenImageSelector(true)}
              />
              {article.image && (
                <ImageListItemBar
                  subtitle={
                    <Stack
                      direction='column'
                      alignItems='end'
                      sx={{
                        mb: 0
                      }}
                    >
                      <DeleteIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => setArticle({ ...article, image: '' })}
                      />
                    </Stack>
                  }
                />
              )}
            </ImageListItem>
            <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
              <TextField
                fullWidth
                margin='dense'
                name='title1'
                value={article.title1}
                label={t('pages.modules.title1label')}
                onChange={e =>
                  setArticle({ ...article, title1: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                fullWidth
                margin='dense'
                value={article.title2}
                name='title2'
                label={t('pages.modules.title2label')}
                onChange={e =>
                  setArticle({ ...article, title2: e.target.value })
                }
                InputLabelProps={{ shrink: true }}
              />
              <Grid container rowSpacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    id='visible_from'
                    label={t('pages.modules.visibleFrom')}
                    type='datetime-local'
                    name='visible_from'
                    fullWidth
                    value={article.visible_from || ''}
                    onChange={e =>
                      setArticle({ ...article, visible_from: e.target.value })
                    }
                    sx={{ input: { px: 1 }, maxWidth: { lg: '160px' } }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  textAlign={{ xs: 'start', lg: 'end' }}
                >
                  <TextField
                    id='visible_to'
                    label={t('pages.modules.visibleTo')}
                    type='datetime-local'
                    name='visible_to'
                    fullWidth
                    sx={{ input: { px: 1 }, maxWidth: { lg: '160px' } }}
                    value={article.visible_to || ''}
                    onChange={e =>
                      setArticle({ ...article, visible_to: e.target.value })
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>
          <TextField
            fullWidth
            margin='dense'
            rows={10}
            value={article.text}
            multiline
            name='text'
            label={t('pages.modules.textLabel')}
            onChange={e => setArticle({ ...article, text: e.target.value })}
            InputLabelProps={{ shrink: true }}
          />
        </Form>
      </Formik>
      <Modal
        open={openImageSelector !== undefined}
        onClose={() => setOpenImageSelector()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Div sx={modalStyle}>
          <Images
            layout={'row-reverse'}
            selectorMode
            onCancel={() => setOpenImageSelector()}
            onSelect={image => handleImageSelect(image)}
          />
        </Div>
      </Modal>
    </Div>
  )
}

const DragHandle = SortableHandle(() => (
  <DragHandleIcon sx={{ cursor: 'grab', verticalAlign: 'middle' }} />
))

const TabCell = SortableElement(
  ({ label, tabIndex, onSelect, handleDelete }) => {
    return (
      <Tab
        style={{ zIndex: 99999999 }}
        onClick={() => onSelect(tabIndex)}
        label={
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <DragHandle />
            {label}
            <IconButton onClick={() => handleDelete(tabIndex)}>
              <ClearIcon fontSize='small' />
            </IconButton>
          </Stack>
        }
        value={tabIndex}
        key={tabIndex}
      />
    )
  }
)

const ArticlesModule = ({ module, presentation, loadModule }) => {
  const [settings, setSettings] = useState({})
  const [selectedTab, setSelectedTab] = useState(0)
  const [errors, setErrors] = useState({})
  const { showDialog, hideDialog } = useJumboDialog()
  const { t } = useTranslation()

  const [updatedArticles, setUpdatedArticles] = useState(
    module?.parameters?.json_data?.articles || [
      {
        title1: t('pages.modules.newArticle'),
        title2: '',
        text: ''
      }
    ]
  )

  useEffect(() => {
    setSettings(module?.parameters)
  }, [module])

  const addNewArticle = () => {
    const newArticle = {
      title1: t('pages.modules.newArticle'),
      title2: '',
      text: ''
    }
    setUpdatedArticles({
      ...updatedArticles,
      [Object.keys(updatedArticles).length]: newArticle
    })
  }

  const handleDelete = index => {
    const orderedArticles = Object.values(updatedArticles).filter(
      (_, i) => i !== index
    )
    const newUpdatedArticles = {}
    orderedArticles.forEach((article, i) => {
      newUpdatedArticles[i] = article
    })
    setUpdatedArticles(newUpdatedArticles)
    hideDialog()
  }

  const TabsSortable = SortableContainer(() => {
    return (
      <Tabs
        variant='scrollable'
        sx={{ flex: 1 }}
        scrollButtons={true}
        aria-label='scrollable auto tabs example'
        value={selectedTab}
      >
        {Object.values(updatedArticles).map((article, index) => (
          <TabCell
            label={article.title1}
            index={index}
            tabIndex={index}
            key={index}
            handleDelete={index =>
              showDialog({
                variant: 'confirm',
                title: t('widgets.confirmDialog.areYouSure'),
                onYes: () => handleDelete(index),
                onNo: () => hideDialog()
              })
            }
            onSelect={val => val !== 'add' && setSelectedTab(val)}
          />
        ))}
      </Tabs>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const orderedArticles = Object.values(updatedArticles)
    const newOrderedArticles = arrayMove(orderedArticles, oldIndex, newIndex)

    const newUpdatedArticles = {}
    newOrderedArticles.forEach(
      (article, index) => (newUpdatedArticles[index] = article)
    )
    setUpdatedArticles(newUpdatedArticles)
  }

  return (
    <EditModule
      type='articles'
      module={module}
      presentation={presentation}
      loadModule={loadModule}
      content={
        <Div sx={{ bgcolor: 'background.paper', mx: 2 }}>
          <Stack
            direction='row'
            sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}
            display='flex'
          >
            <TabsSortable onSortEnd={onSortEnd} useDragHandle={true} axis='x' />
            <IconButton onClick={addNewArticle}>
              <AddCircleIcon fontSize='small' />
            </IconButton>
          </Stack>
          {Object.values(updatedArticles).map((article, index) => {
            return (
              <ArticleTab
                article={article}
                key={index}
                index={index}
                value={selectedTab}
                onChange={(articleIndex, article) =>
                  setUpdatedArticles({
                    ...updatedArticles,
                    [articleIndex]: article
                  })
                }
              />
            )
          })}
        </Div>
      }
      settings={
        module &&
        module.parameters && (
          <ModuleSettings module={module}>
            <FormController setSettings={setSettings} setErrors={setErrors} />
          </ModuleSettings>
        )
      }
      updateValue={{
        settings: {
          url: module?.setting?.url,
          parameters: {
            ...settings,
            json_data: { articles: Object.values(updatedArticles) }
          }
        },
        errors: errors
      }}
    />
  )
}

export default ArticlesModule
