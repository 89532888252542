import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tooltip,
  Typography
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import JumboColorPickerField from '@jumbo/components/JumboFormik/JumboColorPickerField'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '30vw',
  maxHeight: '70vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const DSOKProductAttributeForm = ({
  attribute,
  onAddAttribute,
  onEditAttribute,
  onCancel,
  productLanguages,
  defaultLanguage
}) => {
  const { t, i18n } = useTranslation()
  const ProductAttributeSchema = Yup.object().shape({
    name: Yup.object().required(t('pages.dsokCategories.nameRequired')),
    code: Yup.string().required(t('pages.dsokAttributes.codeRequired'))
  })
  const { showDialog, hideDialog } = useJumboDialog()
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage)
  const [selectedOptionLanguage, setSelectedOptionLanguage] =
    useState(defaultLanguage)
  const [openModal, setOpenModal] = useState()

  const handleAddOption = (values, setFieldValue) => {
    const code = values.options?.code
    let val = {}
    const options = { ...(values.options || {}) }
    if (openModal?.value) {
      val = { ...val, ...options[openModal.value] }
      delete options[openModal.value]
    }
    options[code] = { ...val }
    delete options.code
    setFieldValue('options', options)
    setOpenModal()
  }

  const handleDeleteOption = (key, values, setFieldValue) => {
    const options = { ...values.options }
    delete options[key]
    setFieldValue('options', {
      ...options
    })
    hideDialog()
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        _id: attribute?._id || '',
        name: attribute?.name || '',
        code: attribute?.code || '',
        options: attribute?.options || {}
      }}
      validationSchema={ProductAttributeSchema}
      onSubmit={async data => {
        const handler = attribute ? onEditAttribute : onAddAttribute
        console.log('data', data)
        handler(data)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 0, mb: 1, mx: 0, pr: 0, pl: 0, width: '100%' }}>
            <JumboTextField
              fullWidth
              required
              name={`code`}
              label={t('pages.dsokDetails.code')}
            />
          </Div>
          <TabContext value={selectedLanguage}>
            <Stack width='100%'>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider'
                }}
              >
                <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                  {productLanguages.map(lang => {
                    return (
                      <Tab
                        key={lang}
                        label={t(`languages.${lang}`)}
                        value={lang}
                      />
                    )
                  })}
                </TabList>
              </Box>
              {productLanguages.map(lang => {
                return (
                  <TabPanel
                    key={lang}
                    value={lang}
                    sx={{ pb: 1, pr: 0, px: 0 }}
                  >
                    <Div sx={{ mt: 1, mb: 1, mx: 0 }}>
                      <JumboTextField
                        fullWidth
                        required
                        name={`name.${lang}`}
                        label={t('pages.blocks.moduleName')}
                      />
                    </Div>
                  </TabPanel>
                )
              })}
            </Stack>
          </TabContext>
          <Typography variant='h5'>
            {t('pages.dsokAttributes.options')}
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Box display='flex' justifyContent='center'>
            <Button
              size='normal'
              variant='contained'
              onClick={() => {
                setOpenModal({ type: 'addOption' })
              }}
            >
              {t('pages.dsokAttributes.addOption')}
            </Button>
          </Box>
          {Object.keys(values?.options || {}).map(code => {
            if (['code'].includes(code)) {
              return null
            }
            const option = values.options[code]
            return (
              <>
                <Box
                  flexDirection='row'
                  justifyContent='space-between'
                  display='flex'
                  width='100%'
                  mt={2}
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {code}
                  </Typography>
                  <Div sx={{ display: 'flex', gap: 1 }}>
                    <IconButton
                      sx={{ p: 0.5 }}
                      onClick={e => {
                        showDialog({
                          variant: 'confirm',
                          title: t('widgets.confirmDialog.areYouSure'),
                          onYes: () =>
                            handleDeleteOption(code, values, setFieldValue),
                          onNo: () => hideDialog()
                        })
                      }}
                    >
                      <Tooltip
                        title={t('buttons.delete')}
                        placement='top-start'
                      >
                        <DeleteForeverIcon />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      sx={{ p: 0.5 }}
                      onClick={e => {
                        setFieldValue('options.code', code)
                        setOpenModal({ type: 'addOption', value: code })
                      }}
                    >
                      <Tooltip title={t('buttons.edit')} placement='top-start'>
                        <EditIcon />
                      </Tooltip>
                    </IconButton>
                  </Div>
                </Box>
                <Divider sx={{ mb: 1 }} />
                <Div sx={{ mt: 1, mb: 1, mx: 0 }}>
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    sx={{
                      margin: '0 0 0 10px',
                      background: 'white',
                      color: 'text.secondary',
                      width: 'fit-content',
                      paddingX: 1
                    }}
                  >
                    {t('pages.presentation.backgroundColor')}
                  </Typography>
                  <JumboColorPickerField
                    sx={{
                      height: '51px',
                      borderRadius: '4px',
                      mt: '-10px',
                      p: 1
                    }}
                    required={false}
                    InputLabelProps={{ required: false }}
                    name={`options.${code}.bgColor`}
                  />
                </Div>
                <Div sx={{ mt: 1, mb: 1, mx: 0 }}>
                  <Typography
                    variant='caption'
                    color='text.secondary'
                    sx={{
                      margin: '0 0 0 10px',
                      background: 'white',
                      color: 'text.secondary',
                      width: 'fit-content',
                      paddingX: 1
                    }}
                  >
                    {t('pages.modules.textColor')}
                  </Typography>
                  <JumboColorPickerField
                    sx={{
                      height: '51px',
                      borderRadius: '4px',
                      mt: '-10px',
                      p: 1
                    }}
                    required={false}
                    InputLabelProps={{ required: false }}
                    name={`options.${code}.color`}
                  />
                </Div>
                <TabContext
                  value={selectedOptionLanguage[code] || defaultLanguage}
                >
                  <Stack width='100%'>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider'
                      }}
                    >
                      <TabList
                        onChange={(_, val) =>
                          setSelectedOptionLanguage({
                            ...selectedOptionLanguage,
                            [code]: val
                          })
                        }
                      >
                        {productLanguages.map(lang => {
                          return (
                            <Tab
                              key={lang}
                              label={t(`languages.${lang}`)}
                              value={lang}
                            />
                          )
                        })}
                      </TabList>
                    </Box>
                    {productLanguages.map(lang => {
                      return (
                        <TabPanel
                          key={lang}
                          value={lang}
                          sx={{ pb: 1, pr: 0, px: 0 }}
                        >
                          <Div sx={{ mt: 1, mb: 1, mx: 0 }}>
                            <JumboTextField
                              fullWidth
                              required
                              name={`options.${code}.name.${lang}`}
                              label={t('pages.blocks.moduleName')}
                            />
                          </Div>
                        </TabPanel>
                      )
                    })}
                  </Stack>
                </TabContext>
              </>
            )
          })}
          <Div
            sx={{
              mt: 2,
              mb: 1,
              mx: 2,
              gap: '4px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button size='normal' onClick={onCancel}>
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' variant='contained' size='normal'>
              {t('buttons.ok')}
            </Button>
          </Div>
          <Modal open={openModal !== undefined} onClose={() => setOpenModal()}>
            <Div sx={modalStyle}>
              <JumboTextField
                fullWidth
                required
                name={`options.code`}
                label={t('pages.dsokDetails.code')}
              />
              <Div
                sx={{
                  mt: 2,
                  mb: 1,
                  mx: 2,
                  gap: '4px',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button size='normal' onClick={() => setOpenModal()}>
                  {t('buttons.cancel')}
                </Button>
                <Button
                  variant='contained'
                  size='normal'
                  onClick={() => handleAddOption(values, setFieldValue)}
                >
                  {t('buttons.ok')}
                </Button>
              </Div>
            </Div>
          </Modal>
        </Form>
      )}
    </Formik>
  )
}

export default DSOKProductAttributeForm
