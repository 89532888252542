import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import JumboTabs from '@jumbo/components/JumboTabs/JumboTabs'
import {
  Chip,
  Divider,
  Typography,
  Stack,
  useMediaQuery,
  Modal,
  Avatar
} from '@mui/material'
import {
  createPresentationFromTemplate,
  getTemplates
} from 'app/services/api/template'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useJumboTheme } from '@jumbo/hooks'
import JumboList from '@jumbo/components/JumboList'
import TemplateItem from '../../shared/widgets/TemplateItem/TemplateItem'
import Div from '@jumbo/shared/Div'
import { useNavigate } from 'react-router-dom'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const Templates = () => {
  const { t, i18n } = useTranslation()

  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()

  const resolutions = [
    { title: '16:9', value: '1920x1080' },
    { title: '9:16', value: '1080x1920' }
  ]

  const [categories, setCategories] = useState([])
  const [templates, setTemplates] = useState([])
  const [languages, setLanguages] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [selectedResolution, setSelectedResolution] = useState()
  const [selectedCategory, setSelectedCategory] = useState()
  const [selectedLanguage, setSelectedLanguage] = useState()
  const [previewTemplate, setPreviewTemplate] = useState()
  const [creating, setCreating] = useState(false)
  const [imageDimensions, setImageDimensions] = useState({
    width: 100,
    height: 100
  })
  const navigate = useNavigate()

  const { theme } = useJumboTheme()
  const smallScreen = useMediaQuery(theme => theme.breakpoints.down('md'))

  const handleCreate = async url => {
    setCreating(true)
    const presentation = await createPresentationFromTemplate(url, customerId)
    setCreating(false)
    navigate(`/presentation/${presentation}`)
  }

  const renderTemplate = React.useCallback(template => {
    return (
      <TemplateItem
        item={template}
        creating={creating}
        onCreate={() => handleCreate(template.url)}
        onPreview={() => {
          setPreviewTemplate(template)
        }}
      />
    )
  })

  useEffect(() => {
    const filteredTemplates = templates.filter(template => {
      let pass = true
      if (selectedResolution) {
        pass = selectedResolution.value === template.resolution
      }
      if (pass && selectedCategory) {
        pass =
          template.templateCategory &&
          template.templateCategory.includes(selectedCategory.value)
      }
      if (pass && selectedLanguage) {
        pass =
          template.language &&
          template.language.toUpperCase() === selectedLanguage.key
      }
      return pass
    })
    setFilteredTemplates(filteredTemplates)
  }, [selectedResolution, selectedCategory, selectedLanguage])

  useEffect(() => {
    getTemplates().then(data => {
      setCategories(
        data.categories.map(category => {
          return { title: t(`pages.templates.${category}`), value: category }
        })
      )
      setLanguages(
        [
          ...new Set(
            data.templates.map(template => {
              return template.language
            })
          )
        ]
          .filter(lang => lang)
          .map(lang => ({
            key: lang.toUpperCase(),
            name: t(`languages.${lang}`)
          }))
      )
      setTemplates([...data.templates])
      setFilteredTemplates([...data.templates])
    })
  }, [])

  const imageStyle =
    imageDimensions.width > imageDimensions.height
      ? {
          width: '60vw',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: 'auto',
          background: 'white'
        }
      : {
          width: 'auto',
          maxWidth: '75vw',
          maxHeight: '75vh',
          height: '60vh',
          background: 'white'
        }

  return (
    <JumboCardQuick
      title={
        <Typography
          component={'div'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
              flexWrap: 'wrap'
            }
          }}
        >
          <Typography
            variant={'h4'}
            mb={0}
            sx={{
              minWidth: 245,
              [theme.breakpoints.down('md')]: {
                minWidth: '100%',
                marginBottom: 2
              }
            }}
          >
            {t('pages.templates.title')}{' '}
            <Chip
              size={'small'}
              label={filteredTemplates.length}
              color='primary'
            />
          </Typography>
          <Stack
            direction={smallScreen ? 'column' : 'row'}
            divider={
              <Divider
                orientation={smallScreen ? 'horizontal' : 'vertical'}
                flexItem
              />
            }
            spacing={2}
            sx={{
              mb: 2
            }}
          >
            <JumboTabs
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
              tabs={categories}
              id='title'
              onChange={item => {
                setSelectedCategory(item)
              }}
            />
            <JumboTabs
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
              id='title'
              tabs={resolutions}
              onChange={item => {
                setSelectedResolution(item)
              }}
            />
            <JumboTabs
              sx={{
                display: 'flex',
                flexWrap: 'wrap'
              }}
              id='key'
              tabs={languages}
              onChange={item => {
                setSelectedLanguage(item)
              }}
            />
          </Stack>
        </Typography>
      }
      headerSx={{
        borderBottom: 1,
        borderBottomColor: 'divider',
        '& .MuiCardHeader-action': {
          my: -0.75
        }
      }}
      wrapperSx={{
        p: 0,
        '&:last-child': {
          pb: 2
        },
        '& .MuiCollapse-entered:last-child': {
          '& .MuiListItemButton-root': {
            borderBottom: 0,
            borderBottomColor: 'transparent'
          }
        }
      }}
    >
      <JumboScrollbar
        autoHeight
        autoHeightMin={'80vh'}
        autoHide
        autoHideDuration={200}
        autoHideTimeout={500}
      >
        <JumboList
          primaryKey={'id'}
          primary={'id'}
          view='grid'
          data={filteredTemplates}
          renderItem={renderTemplate}
          isLoading={!templates.length}
          componentElement={'div'}
          wrapperSx={{ pb: 1, paddingLeft: 3, paddingTop: 3 }}
        />
      </JumboScrollbar>
      <Modal
        open={previewTemplate !== undefined}
        onClose={() => setPreviewTemplate()}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Div sx={modalStyle}>
          <Stack
            direction='column'
            divider={<Divider orientation='horizontal' flexItem />}
            spacing={2}
            alignItems='center'
            sx={{
              mb: 2,
              color: 'white',
              height: '100%'
            }}
          >
            <Typography
              id='modal-modal-title'
              variant='h4'
              component='h2'
              color='white'
            >
              {previewTemplate && previewTemplate.name}
            </Typography>
            <Avatar
              src={previewTemplate && previewTemplate.image}
              variant={'rounded'}
              onLoad={e => {
                setImageDimensions({
                  width: e.target.offsetWidth,
                  height: e.target.offsetHeight
                })
              }}
              sx={{
                cursor: 'pointer',
                width: '100%',
                height: 'auto',
                ...imageStyle
              }}
            />
            <Typography
              id='modal-modal-description'
              sx={{ mt: 2 }}
              color='white'
            >
              {previewTemplate && previewTemplate.description[i18n.language]}
            </Typography>
          </Stack>
        </Div>
      </Modal>
    </JumboCardQuick>
  )
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: '#00000099',
  boxShadow: 24,
  p: 4
}

export default Templates
