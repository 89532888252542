import axios from 'axios'
import jwtAuthAxios from '../auth/jwtAuth'
import jwtAuthKioskAxios from '../auth/jwtAuthKiosk'
import jwtAuthKioskStagingAxios from '../auth/jwtAuthKioskStaging'
import { replaceAll } from 'app/utils/stringHelpers'
import { BE_DOMAINS } from 'app/utils/constants/settings'

const prepareFormData = data => {
  const bodyFormData = new FormData()

  Object.keys(data).forEach(field => {
    const isObject =
      typeof data[field] === 'object' &&
      data[field] !== null &&
      !(data[field] instanceof File)
    const value = isObject ? JSON.stringify(data[field]) : data[field]
    bodyFormData.append(field, value)
  })

  return bodyFormData
}

export const getCustomers = async customerId => {
  const { data } = await jwtAuthAxios.get(
    `/customers/list?customerId=${customerId}`
  )
  return data.response
}

export const getCustomer = async customerUrl => {
  const { data } = await jwtAuthAxios.get(`/customer?customer=${customerUrl}`)
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev'
  const beDomain = isDev
    ? process.env.REACT_APP_KIOSK_STAGING_URL
    : data.response?.dsokSettings?.customer?.beDomain || BE_DOMAINS[0]
  jwtAuthKioskStagingAxios.defaults.baseURL = `${beDomain}/`
  return data.response
}

export const getDSOKCategories = async () => {
  const { data } = await jwtAuthKioskStagingAxios.get(`/dsok/categories`)
  return data.categories
}

export const getDSOKOrders = async () => {
  const { data } = await jwtAuthKioskStagingAxios.get(`/dsok/orders`)
  return data.orders
}

export const upsertDSOKCategory = async category => {
  if (typeof category.image === 'string') {
    delete category.image
  }
  const bodyFormData = prepareFormData(category)
  const { data } = await jwtAuthKioskStagingAxios.post(
    `/dsok/categories`,
    bodyFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data?.result
}

export const deleteDSOKCategory = async categoryId => {
  const { data } = await jwtAuthKioskStagingAxios.delete(
    `/dsok/categories/${categoryId}`
  )
  return data?.result
}

export const editDSOKDetails = async dsok => {
  const { data } = await jwtAuthAxios.put(`/customer/dsok`, dsok)
  const response = await getCustomer(data.url)
  await jwtAuthKioskStagingAxios.post(
    `/dsok/customer/updateDsokSettingsTempFile`,
    response
  ) // temporarily update dsokSettings file
  return data?.result
}

export const getDeviceStatus = async (deviceId, customerUrl) => {
  const { data } = await jwtAuthKioskStagingAxios.get(`/dsok/deviceStatus`, {
    params: { deviceId, customerUrl }
  })
  return data
}

export const getDSOKProducts = async () => {
  const { data } = await jwtAuthKioskStagingAxios.get(`/dsok/product/list`)
  return data.products
}

export const deleteDSOKProduct = async productId => {
  const { data } = await jwtAuthKioskStagingAxios.delete(
    `/dsok/product/${productId}`
  )
  return data?.result
}

export const upsertDSOKProduct = async product => {
  if (typeof product.image === 'string') {
    delete product.image
  }
  const bodyFormData = prepareFormData(product)
  const { data } = await jwtAuthKioskStagingAxios.post(
    `/dsok/product`,
    bodyFormData,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
  return data?.result
}

export const getDSOKProductAttributes = async () => {
  const { data } = await jwtAuthKioskStagingAxios.get(
    `/dsok/product-attribute/list`
  )
  return data.attributes
}

export const deleteDSOKProductAttribute = async attributeId => {
  const { data } = await jwtAuthKioskStagingAxios.delete(
    `/dsok/product-attribute/${attributeId}`
  )
  return data?.result
}

export const upsertDSOKProductAttribute = async attribute => {
  const { data } = await jwtAuthKioskStagingAxios.post(
    `/dsok/product-attribute`,
    attribute
  )
  return data?.result
}

export const triggerDSOKBuild = async customer => {
  const { id, dsokSettings, siteEnv, bearerToken, branch } = customer
  const { data } = await axios.get(
    `https://jenkins.gastrokiosk.online/job/DSOK FE/buildWithParameters`,
    {
      params: {
        token: '4xtPWFEx4cJgxWWfNd',
        CUSTOMER_ID: id,
        CUSTOMER_CODE: replaceAll(dsokSettings.customer.code, '-', ''),
        SITE_ENV: siteEnv,
        BEARER_TOKEN: bearerToken,
        BRANCH: branch
      }
    }
  )
  return data
}

export const getBuildConfigurations = async () => {
  const { data } = await jwtAuthKioskStagingAxios.get(
    '/dsok/buildConfiguration'
  )
  return data?.buildConfigurations
}

export const upsertBuildConfiguration = async customer => {
  const { data } = await jwtAuthKioskStagingAxios.post(
    '/dsok/buildConfiguration',
    customer
  )
  return data?.result
}

export const sendEkasaRegister = async id => {
  const { data } = await jwtAuthKioskStagingAxios.post(
    '/dsok/order/ekasa-register',
    { id }
  )
  return data?.result
}

export const generateToken = async customerId => {
  const { data } = await jwtAuthKioskStagingAxios.post(
    '/customer/generateToken',
    { customerId }
  )
  return data?.token
}

export const fetchDSOKProducts = async url => {
  const { data } = await jwtAuthKioskStagingAxios.post('/dsok/fetchProducts', {
    url
  })
  return data
}

export const deleteDSOKOrder = async id => {
  const { data } = await jwtAuthKioskStagingAxios.delete(`/dsok/order/${id}`)
  return data?.result
}
