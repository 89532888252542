import JumboColorPickerField from "@jumbo/components/JumboFormik/JumboColorPickerField";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  Typography,
  TextField as TextFieldMUI,
  Tooltip,
  Stack,
  Avatar,
  ImageListItemBar,
  ImageListItem,
} from "@mui/material";
import { Box } from "@mui/system";
import { FontManager } from "@samuelmeuli/font-manager";
import { FONT_API_KEY, MAPS_API_KEY } from "app/services/config";
import { Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { usePlacesWidget } from "react-google-autocomplete";
import { CKEditor } from "ckeditor4-react";
import HelpIcon from "@mui/icons-material/Help";
import DeleteIcon from "@mui/icons-material/Delete";
import { TreeItem, TreeView } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getNestedFormValue } from "app/utils/objectHelpers";
import { fancyTimeFormat } from "app/utils/timeHelpers";
import range from "lodash/range";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";
import DndWrapper from "@jumbo/components/DropZone/DndWrapper";

export const CK_FONT_SIZE_OPTIONS = range(28, 102, 2)
  .map((size) => `${size}/${size}px`)
  .join(";");

export const Label = ({ text, helpText, required, sx, color }) => (
  <Div
    sx={{
      display: "flex",
      gap: 1,
      alignItems: "center",
    }}
  >
    <Typography
      variant="body"
      color={`text.${color || "secondary"}`}
      noWrap
      sx={{
        background: "white",
        color: `text.${color || "secondary"}`,
        ...sx,
      }}
    >{`${text}`}</Typography>
    {`${required ? " *" : ""}`}
    {helpText && (
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: helpText }} />}>
        <HelpIcon />
      </Tooltip>
    )}
  </Div>
);

const TextField = ({ name, parameters, lang }) => {
  const { label, required, max, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  return (
    <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
      <JumboTextField
        required={required}
        fullWidth
        margin="dense"
        name={name}
        sx={{ mt: 0 }}
        label={<Label text={labelText} helpText={help} required={required} />}
        InputLabelProps={{ required: false }}
        inputProps={{ maxLength: max }}
      />
    </Div>
  );
};
const SimpleTextAreaField = ({ name, parameters, lang }) => {
  const { label, required, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";

  return (
    <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
      <JumboTextField
        required={required}
        fullWidth
        multiline
        rows={15}
        margin="dense"
        name={name}
        sx={{ mt: 0 }}
        label={<Label text={labelText} helpText={help} required={required} />}
        InputLabelProps={{ required: false }}
      />
    </Div>
  );
};

const TextAreaField = ({ name, parameters, lang }) => {
  const { label, required, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const { values, setFieldValue } = useFormikContext();
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";

  return (
    <Div
      sx={{
        mt: 0,
        mb: 2,
        mx: 2,
      }}
    >
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          background: "white",
          color: "text.secondary",
          width: "fit-content",
        }}
      >
        {`${labelText}${required ? " *" : ""}`}
        {help && (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: help }} />}>
            <HelpIcon fontSize="small" sx={{ pt: 0.3, ml: 0.5, mb: -0.5 }} />
          </Tooltip>
        )}
      </Typography>
      <CKEditor
        onChange={(event) => {
          const data = event.editor.getData();
          setFieldValue(name, data);
        }}
        initData={values[name]}
        config={{
          contentsCss: [
            `${process.env.PUBLIC_URL}/fonts/noir-pro/styles.css`,
            `${process.env.PUBLIC_URL}/vendors/ck-editor/style.css`,
          ],
          extraPlugins: "font,colorbutton,justify,colordialog",
          fontSize_sizes: CK_FONT_SIZE_OPTIONS,
          colorButton_enableMore: true,
          versionCheck: false,
        }}
      />
    </Div>
  );
};

export const BooleanField = ({ name, parameters, lang, sx }) => {
  const { label, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const { values, setFieldValue } = useFormikContext();
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  return (
    <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
      <FormControlLabel
        control={
          <Field
            name={name}
            component={Switch}
            onChange={(e) => setFieldValue(name, e.target.checked)}
          />
        }
        label={<Label text={labelText} helpText={help} required={false} />}
        checked={values[name]}
      />
    </Div>
  );
};

const SelectField = ({ name, parameters, lang, sx }) => {
  const { t } = useTranslation();
  const { label, required, values, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  return (
    <FormControl sx={{ mt: 0, mb: 2, px: 2 }} fullWidth key={name}>
      <JumboTextField
        required={required}
        select
        InputLabelProps={{ required: false }}
        name={name}
        label={<Label text={labelText} helpText={help} required={required} />}
      >
        {values?.map &&
          values.map((item, index) => {
            const label = item?.key
              ? t("selectOptions." + item?.key)
              : item?.label;
            return (
              <MenuItem value={item.value} key={index}>
                {label}
              </MenuItem>
            );
          })}
      </JumboTextField>
    </FormControl>
  );
};

const NumberField = ({ name, parameters, lang, sx }) => {
  const { label, required, min, max, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const { values, setFieldValue } = useFormikContext();
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  return (
    <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
      <JumboTextField
        type="number"
        required={required}
        fullWidth
        name={name}
        value={values[name]}
        label={<Label text={labelText} helpText={help} required={required} />}
        InputLabelProps={{ required: false }}
        InputProps={{
          inputProps: {
            max: max,
            min: min,
          },
          onChange: (e) => setFieldValue(name, `${e.target.value}`),
        }}
      />
    </Div>
  );
};

export const ColorField = ({ name, parameters, lang, sx }) => {
  const { label, required, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  return (
    <Div sx={{ mt: -1, mb: 2.5, mx: 2, ...(sx || {}) }}>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{
          margin: "0 0 0 10px",
          background: "white",
          color: "text.secondary",
          width: "fit-content",
          paddingX: 1,
        }}
      >
        {`${labelText}${required ? " *" : ""}`}
        {help && (
          <Tooltip title={<div dangerouslySetInnerHTML={{ __html: help }} />}>
            <HelpIcon fontSize="small" sx={{ pt: 0.3, ml: 0.5, mb: -0.5 }} />
          </Tooltip>
        )}
      </Typography>
      <JumboColorPickerField
        sx={{ height: "51px", borderRadius: "4px", mt: "-10px", p: 1 }}
        required={required}
        InputLabelProps={{ required: false }}
        name={name}
      />
    </Div>
  );
};

const GoogleFontField = ({ name, parameters, lang, sx }) => {
  const { label, required, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const [fonts, setFonts] = useState([]);
  const [fontsMap, setFontsMap] = useState([]);
  const { values, setFieldValue } = useFormikContext();
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";

  useEffect(() => {
    const fontManager = new FontManager(FONT_API_KEY, "", { limit: 150 });
    fontManager
      .init()
      .then(() => {
        const googleFonts = Array.from(fontManager.getFonts().values()).slice(
          1
        );
        setFonts(googleFonts);
        const fontMap = {};
        const emptyFont = {
          undefined: {
            variants: ["100"],
            version: "v30",
            lastModified: "2022-09-22",
            files: {
              100: "http://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
            },
            category: "sans-serif",
            kind: "webfonts#webfont",
            family: undefined,
            id: "roboto",
            scripts: ["cyrillic"],
          },
        };
        googleFonts.forEach((font) => (fontMap[font.family] = font));
        const updatedFonts = Object.assign(emptyFont, fontMap);
        setFontsMap(updatedFonts);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, []);
  return (
    <Div sx={{ mt: 0, mb: 2, px: 2 }} key={name}>
      {fontsMap[values[name]] && (
        <Autocomplete
          ListboxProps={{
            style: {
              maxHeight: "320px",
            },
          }}
          disablePortal
          options={fonts}
          value={fontsMap[values[name]]}
          onChange={(e) =>
            e.target.innerText && setFieldValue(name, e.target.innerText)
          }
          renderInput={(params) => (
            <TextFieldMUI
              {...params}
              InputLabelProps={{ required: false }}
              label={
                <Label text={labelText} helpText={help} required={required} />
              }
              required={required}
            />
          )}
          getOptionLabel={(option) => option.family}
          renderOption={(props, option) => {
            const font = fontsMap[option.family];
            return (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Helmet>
                  <link
                    rel="stylesheet"
                    href={`https://fonts.googleapis.com/css2?family=${encodeURIComponent(
                      font.family
                    )}`}
                  />
                </Helmet>
                <Typography fontFamily={font.family}>{font.family}</Typography>
              </Box>
            );
          }}
        />
      )}
    </Div>
  );
};

const GoogleFontVariantField = ({ name, parameters, lang, sx }) => {
  const { label, required, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const [variants, setVariants] = useState([]);
  const { values } = useFormikContext();
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";

  useEffect(() => {
    const selectedFont = values["google_font"];
    if (selectedFont) {
      const fontManager = new FontManager(FONT_API_KEY, "", { limit: 150 });
      fontManager
        .init()
        .then(() => {
          const googleFont = Array.from(fontManager.getFonts().values())
            .slice(1)
            .find((font) => font.family === selectedFont);
          setVariants(googleFont?.variants || []);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [values]);

  return (
    <FormControl sx={{ mt: 0, mb: 2, px: 2 }} fullWidth key={name}>
      <JumboTextField
        required={required}
        name={name}
        select
        InputLabelProps={{ required: false }}
        label={<Label text={labelText} helpText={help} required={required} />}
      >
        {variants &&
          variants.map((item, index) => {
            return (
              <MenuItem value={item} key={index}>
                <Typography>{item}</Typography>
              </MenuItem>
            );
          })}
      </JumboTextField>
    </FormControl>
  );
};

export const GooglePlaceField = ({
  name,
  parameters,
  lang,
  sx,
  coordinatesName,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const { ref } = usePlacesWidget({
    apiKey: MAPS_API_KEY,
    onPlaceSelected: (place) => {
      let matches = place.address_components.filter((address_component) =>
        ["locality", "colloquial_area"].some(
          (word) => ~address_component.types.indexOf(word)
        )
      );
      const city = matches && matches.length && matches[0].short_name;
      const coordinates = place.geometry?.location?.toJSON() || {};
      setFieldValue(name, city);
      coordinatesName && setFieldValue(coordinatesName, coordinates);
    },
  });
  const { label, required, max, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  const value = getNestedFormValue(values, name);

  return (
    <FormControl sx={{ mt: 0, mb: 2, px: 2 }} fullWidth key={name}>
      <JumboTextField
        required={required}
        inputRef={ref}
        fullWidth
        margin="dense"
        name={name}
        label={<Label text={labelText} helpText={help} required={required} />}
        InputLabelProps={{ required: false }}
        value={value}
        inputProps={{ maxLength: max }}
      />
    </FormControl>
  );
};

const FileStructureField = ({ name, parameters, lang, sx }) => {
  const { label, required, values: folders, helpText } = parameters;
  const labelText = label[lang] || label["en"];
  const [selectedFolder, setSelectedFolder] = useState("");
  const { values, setFieldValue } = useFormikContext();
  const [reload, setReload] = useState(false);

  const help = (helpText && (helpText[lang] || helpText["en"])) || "";
  useEffect(() => {
    if (values.text_input) {
      const defaultFolder = Object.keys(folders).find((folderName) =>
        folders[folderName].find((file) => file.url === values.text_input)
      );
      defaultFolder && setSelectedFolder(defaultFolder);
    }
  }, [values.text_input]);

  return (
    <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
      <Label text={labelText} helpText={help} required={required} />
      <Stack
        direction="row"
        pr={1}
        mt={1}
        border="solid 1px"
        borderColor="#8595A6"
        borderRadius="4px"
        sx={{
          ".MuiTreeItem-content": {
            "&.Mui-selected": {
              backgroundColor: "rgb(94, 59, 183) !important",
              color: "#fff",
            },
            "&:hover": {
              backgroundColor: "rgb(94, 59, 183, 70%)",
              color: "#fff",
            },
          },
        }}
      >
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={[]}
          selected={selectedFolder}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ height: "100%", flexGrow: 1, overflowY: "auto", width: "30%" }}
        >
          {Object.keys(folders || {}).map((folderName, index) => (
            <TreeItem
              key={index}
              nodeId={folderName}
              label={folderName}
              onClick={() => setSelectedFolder(folderName)}
            />
          ))}
        </TreeView>
        {selectedFolder && (
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={[]}
            selected={values.text_input}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              height: "100%",
              flexGrow: 1,
              overflowY: "auto",
              width: "70%",
            }}
          >
            {folders[selectedFolder].map(
              ({ filename, url, duration }, index) => (
                <Div key={url}>
                  <TreeItem
                    nodeId={url}
                    label={
                      <Typography>
                        {filename}
                        <Typography display="inline" fontWeight="medium">
                          {duration
                            ? ` (${fancyTimeFormat(duration)} min)`
                            : ""}
                        </Typography>
                      </Typography>
                    }
                    onClick={() => setFieldValue("text_input", url)}
                  />
                  <video
                    src={url}
                    hidden
                    onLoadedMetadata={(e) => {
                      folders[selectedFolder][index].duration =
                        e.target.duration;
                      setReload(!reload);
                    }}
                  />
                </Div>
              )
            )}
          </TreeView>
        )}
      </Stack>
    </Div>
  );
};

const ACCEPT_TYPES = {
  image: ["jpg", "jpeg", "png", "svg"],
};

const MAX_SIZES = {
  image: 5,
};

const options = {
  accept: ACCEPT_TYPES.image.map((type) => `image/${type}`).join(","),
  maxSize: MAX_SIZES.image * 1024 * 1024,
};
export const UploadComponent = ({ name, setFieldValue, value, onUpload }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleFileUpload = async (acceptedFiles, fileRejections) => {
    if (fileRejections && fileRejections.length) {
      const message = (
        <>
          {`${t("widgets.dropZone.supportedTypes")}: ${ACCEPT_TYPES.image.join(
            ", "
          )}`}
          <br />
          {`${t("widgets.dropZone.maxFileSize")}: ${MAX_SIZES.image} MB`}
        </>
      );
      enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    }
    if (acceptedFiles && acceptedFiles.length) {
      let imageUrl = acceptedFiles[0];
      if (onUpload) {
        try {
          imageUrl = await onUpload(acceptedFiles[0]);
        } catch (err) {}
      }
      setFieldValue(name, imageUrl);
      enqueueSnackbar(t("widgets.dropZone.filesUploaded"), {
        variant: "success",
      });
    }
  };
  let src = "";
  if (value?.includes && value.includes("http")) {
    src = value;
  } else {
    try {
      src = URL.createObjectURL(value);
    } catch (error) {}
  }
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFileUpload,
    disabled: false,
    ...options,
  });
  return (
    <DndWrapper>
      <div
        {...getRootProps()}
        style={{
          width: "100%",
          minHeight: "180px",
          display: "flex",
          alignItems: "center",
          p: 2,
          mt: 1,
          mb: 3,
          padding: 8,
        }}
      >
        <input name="image" {...getInputProps()} />
        {value ? (
          <ImageListItem
            sx={{
              "& .MuiImageListItemBar-root": {
                transition: "visibility 0s, opacity 0.3s linear",
                opacity: 0,
              },

              "&:hover .MuiImageListItemBar-root": {
                visibility: "visible",
                opacity: 1,
              },
              width: "fit-content",
              height: "auto",
            }}
          >
            <Avatar
              src={src}
              variant={"rounded"}
              sx={{
                height: "auto",
                width: `100%`,
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
            <ImageListItemBar
              subtitle={
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  sx={{
                    mb: 0,
                  }}
                >
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setFieldValue(name, "")}
                  />
                </Stack>
              }
            />
          </ImageListItem>
        ) : (
          <Typography variant={"body1"} align="center">
            {t("widgets.dropZone.dropFiles")}
          </Typography>
        )}
      </div>
    </DndWrapper>
  );
};

export const GeneratedFormField = ({ name, parameters, lang, sx }) => {
  const { i18n } = useTranslation();
  const { values } = useFormikContext();
  const { masterParameter } = parameters;
  const hideField =
    parameters.hidden ||
    (masterParameter &&
      values[masterParameter.parameterCode] !== masterParameter.value);
  if (hideField) {
    return <></>;
  }
  const map = {
    text: (
      <TextField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    textArea: (
      <TextAreaField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    simpleTextArea: (
      <SimpleTextAreaField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    boolean: (
      <BooleanField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    select: (
      <SelectField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    number: (
      <NumberField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    colorPicker: (
      <ColorField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    googleFontType: (
      <GoogleFontField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    googleFontVariants: (
      <GoogleFontVariantField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    googlePlace: (
      <GooglePlaceField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
    fileStructure: (
      <FileStructureField
        name={name}
        parameters={parameters}
        lang={i18n.language}
        sx={sx}
      />
    ),
  };
  return (
    <>
      {map[parameters?.type] || (
        <>
          <Div sx={{ mt: 0, mb: 2, mx: 2 }}>
            {parameters.type} type not exist
          </Div>
        </>
      )}
    </>
  );
};
