import { removeAuth } from "@jumbo/components/JumboAuthProvider/authHelpers";
import jwtAxios from "axios";


const BE_URL = process.env.REACT_APP_BE_URL || ''
const jwtAuthAxios = jwtAxios.create({
  baseURL: `${BE_URL}/V1`,
  headers: {
    'Content-Type': 'application/json'
  }
});

jwtAuthAxios.interceptors.response.use(
  res => res,
  err => {
    if (err.response && err.response.data.message === 'Unauthorised access') {
      delete jwtAuthAxios.defaults.headers.common['Authorization'];
      removeAuth()
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    sessionStorage.setItem('token', token);
  } else {
    delete jwtAuthAxios.defaults.headers.common['Authorization'];
    sessionStorage.removeItem('token');
  }
};

export const getAuthToken = () => {
  return sessionStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;