import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  FormControl,
  Grid,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import Div from '@jumbo/shared/Div/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { useFormikContext } from 'formik'
import HelpIcon from '@mui/icons-material/Help'
import DeleteIcon from '@mui/icons-material/Delete'
import Images from 'app/pages/media/images/Images'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { PROD_URL } from 'app/services/config'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75vw',
  maxHeight: '90vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

const FIELD_NAME = 'CONTACTS_FIELD'

export const CONTACTS_FIELD_SCHEMA = {
  street: '',
  zip: '',
  city: '',
  country: '',
  phone: '',
  email: '',
  contactImage: ''
}

const ContactsField = ({ handleChange, expanded }) => {
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext()
  const [openSelector, setOpenSelector] = useState()
  const { getAuthUser } = useJumboAuth()
  const { customerId } = getAuthUser()

  const handleImageSelect = url => {
    setOpenSelector()
    setFieldValue('contact.contactImage', url)
  }

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.editDevice.contacts')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.street'
                label={t('pages.editDevice.street')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.zip'
                label={t('pages.editDevice.zip')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.city'
                label={t('pages.editDevice.city')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.country'
                label={t('pages.editDevice.country')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.phone'
                label={t('pages.editDevice.phone')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='contact.email'
                label={t('pages.editDevice.email')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 0, mb: 2.5 }}>
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{
                  margin: '0 0 0 10px',
                  background: 'white',
                  color: 'text.secondary',
                  width: 'fit-content'
                }}
              >
                {t('pages.editDevice.image.label')}
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t('pages.editDevice.image.help')
                      }}
                    />
                  }
                >
                  <HelpIcon
                    fontSize='small'
                    sx={{ pt: 0.3, ml: 0.5, mb: -0.5 }}
                  />
                </Tooltip>
              </Typography>
              <ImageListItem
                sx={{
                  '& .MuiImageListItemBar-root': {
                    transition: 'visibility 0s, opacity 0.3s linear',
                    opacity: 0
                  },

                  '&:hover .MuiImageListItemBar-root': {
                    visibility: 'visible',
                    opacity: 1
                  },
                  width: 'fit-content',
                  height: 'auto'
                }}
              >
                <Avatar
                  src={
                    values.contact?.contactImage ||
                    '/images/pages/not_found.svg'
                  }
                  variant={'rounded'}
                  sx={{
                    height: 'auto',
                    width: `100%`,
                    objectFit: 'contain',
                    cursor: 'pointer'
                  }}
                  onClick={() => setOpenSelector(true)}
                />
                {values.contact?.contactImage && (
                  <ImageListItemBar
                    subtitle={
                      <Stack
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-end'
                        sx={{
                          mb: 0
                        }}
                      >
                        <DeleteIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            setFieldValue('contact.contactImage', '')
                          }
                        />
                      </Stack>
                    }
                  />
                )}
              </ImageListItem>
            </Div>
          </Grid>
        </Grid>
      </AccordionDetails>
      <Modal
        open={openSelector !== undefined}
        onClose={() => setOpenSelector()}
      >
        <Div sx={modalStyle}>
          {openSelector && (
            <Images
              layout={'row-reverse'}
              selectorMode
              onCancel={() => setOpenSelector()}
              allowed={['png', 'jpeg', 'jpg']}
              onSelect={image => {
                const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`
                handleImageSelect(imageUrl)
              }}
            />
          )}
        </Div>
      </Modal>
    </Accordion>
  )
}

export default ContactsField
