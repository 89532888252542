import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  Table,
  TableBody,
  Typography
} from '@mui/material'
import { arrayMove, SortableContainer } from 'react-sortable-hoc'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import BlockCell from './BlockCell'
import JumboColorPickerField from '@jumbo/components/JumboFormik/JumboColorPickerField'
import Images from '../media/images/Images'
import CollectionsIcon from '@mui/icons-material/Collections'
import CancelIcon from '@mui/icons-material/Cancel'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { PROD_URL } from 'app/services/config'

const Blocks = SortableContainer(
  ({ form, blocks, url, onDeleteBlock, template }) => {
    const { t } = useTranslation()
    useEffect(() => {
      form.setFieldValue('blocks', blocks)
    }, [blocks])

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell sx={{ pl: 0 }}>
                {t('pages.presentation.blockName')}
              </TableCell>
              <TableCell>{t('pages.presentation.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blocks &&
              Object.keys(blocks)
                .map(blockId => ({ ...blocks[blockId] }))
                .sort(
                  (a, b) =>
                    Math.abs(a.block_settings.order) -
                    Math.abs(b.block_settings.order)
                )
                .map((block, index) => {
                  const {
                    block_settings: { block_url }
                  } = block
                  return (
                    <BlockCell
                      key={index}
                      index={block.index}
                      block={block.block_settings}
                      url={
                        block_url &&
                        !block.block_settings.is_shared &&
                        `/presentation/${url}/block/${block_url}`
                      }
                      onDelete={onDeleteBlock}
                      template={template}
                    />
                  )
                })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)

const PresentationSettings = ({
  presentation: {
    name,
    template,
    language,
    presentation_parameters: {
      presentation_width,
      presentation_height,
      background_color,
      border_color,
      background_image
    },
    template_languages,
    blocks,
    sourceId,
    url,
    resolutions
  },
  onDeleteBlock,
  addedBlocks,
  children
}) => {
  const { t, i18n } = useTranslation()
  const { getAuthUser } = useJumboAuth()
  const { customerId, customer } = getAuthUser()
  const [blockList, setBlockList] = useState(blocks)
  const [openImageSelector, setOpenImageSelector] = useState(false)
  const resolutionValue = `${presentation_width}x${presentation_height}`
  const resolution = resolutions[resolutionValue] ? resolutionValue : 'custom'
  const superadmin = customer?.customerType === 1

  useEffect(() => {
    const newBlocks = { ...blocks, ...addedBlocks } || {}
    const orderedBlocks = {}
    Object.keys(newBlocks)
      .map(blockId => ({
        ...newBlocks[blockId],
        block_settings: {
          ...newBlocks[blockId].block_settings,
          order: 1000 - newBlocks[blockId].block_settings.order
        }
      }))
      .sort(
        (a, b) =>
          Math.abs(a.block_settings?.order) - Math.abs(b.block_settings?.order)
      )
      .forEach(
        (block, index) =>
          (orderedBlocks[block.block_settings.block_id] = {
            ...block,
            index
          })
      )
    setBlockList(orderedBlocks)
  }, [blocks, addedBlocks])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const orderedBlocks = {}
    const sortedBlockList = Object.keys(blockList)
      .map(blockId => ({ ...blockList[blockId] }))
      .sort(
        (a, b) =>
          Math.abs(a.block_settings.order) - Math.abs(b.block_settings.order)
      )
    arrayMove(sortedBlockList, oldIndex, newIndex)
      .map((block, index) => ({
        ...block,
        block_settings: { ...block.block_settings, order: index }
      }))
      .forEach(
        (block, index) =>
          (orderedBlocks[block.block_settings.block_id] = { ...block, index })
      )
    setBlockList(orderedBlocks)
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={{
        name,
        resolution,
        presentation_width,
        presentation_height,
        background_color,
        border_color,
        background_image,
        template,
        language
      }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        // await onReset(data.email);
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form style={{ textAlign: 'left' }} noValidate>
          <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
            <JumboTextField
              fullWidth
              name='name'
              label={t('pages.presentation.label')}
            />
          </Div>
          <FormControl sx={{ mt: 1, mb: 1, mx: 2, pr: 4 }} fullWidth>
            <JumboTextField
              select
              name='resolution'
              disabled={sourceId}
              label={t('pages.presentation.resolution')}
            >
              {resolutions &&
                Object.keys(resolutions).map((key, index) => {
                  const { translations } = resolutions[key]
                  return (
                    <MenuItem key={index} value={key}>
                      {translations[i18n.language]}
                    </MenuItem>
                  )
                })}
            </JumboTextField>
          </FormControl>
          {values.resolution === 'custom' && (
            <>
              <Div sx={{ mt: 1, mb: 1, mx: 2 }}>
                <JumboTextField
                  fullWidth
                  type='number'
                  name='presentation_width'
                  label={t('pages.presentation.width')}
                />
              </Div>
              <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
                <JumboTextField
                  fullWidth
                  type='number'
                  name='presentation_height'
                  label={t('pages.presentation.height')}
                />
              </Div>
            </>
          )}
          <Div sx={{ mt: -0.5, mb: 1, mx: 2 }}>
            <Typography
              variant='caption'
              color='text.secondary'
              sx={{
                margin: '0 0 0 10px',
                background: 'white',
                color: 'text.secondary',
                width: 'fit-content',
                paddingX: 1
              }}
            >
              {t('pages.presentation.backgroundColor')}
            </Typography>
            <JumboColorPickerField
              sx={{ height: '51px', borderRadius: '4px', mt: '-10px', p: 1 }}
              name={'background_color'}
            />
          </Div>
          <Div sx={{ mt: -0.5, mb: 1, mx: 2 }}>
            <Typography
              variant='caption'
              color='text.secondary'
              sx={{
                margin: '0 0 0 10px',
                background: 'white',
                color: 'text.secondary',
                width: 'fit-content',
                paddingX: 1
              }}
            >
              {t('pages.presentation.borderColor')}
            </Typography>
            <JumboColorPickerField
              sx={{ height: '51px', borderRadius: '4px', mt: '-10px', p: 1 }}
              name={'border_color'}
            />
          </Div>
          <Div sx={{ mt: 2, mb: 1, mx: 2, gap: '4px', display: 'flex' }}>
            <Button
              variant='contained'
              size='small'
              startIcon={<CollectionsIcon />}
              onClick={() => setOpenImageSelector(true)}
            >
              {t('pages.presentation.selectBackgroundImage')}
            </Button>
            <IconButton
              size='small'
              onClick={() => setFieldValue('background_image', 'removed')}
            >
              <CancelIcon />
            </IconButton>
          </Div>
          {superadmin && (
            <Div sx={{ mt: 1, mb: 1, mx: 2 }}>
              <FormControlLabel
                control={
                  <Field
                    name={'template'}
                    component={Switch}
                    onChange={e => setFieldValue('template', e.target.checked)}
                  />
                }
                label={
                  <FormLabel sx={{ color: 'text.primary' }}>
                    {t('pages.presentation.templateSwitch')}
                  </FormLabel>
                }
                checked={values['template']}
              />
            </Div>
          )}
          {superadmin && values.template ? (
            <FormControl sx={{ mt: 1, mb: 1, mx: 2, pr: 4 }} fullWidth>
              <JumboTextField
                select
                name='language'
                disabled={sourceId}
                label={t('pages.presentation.templateLanguage')}
              >
                {template_languages?.options?.templateLanguages &&
                  Object.keys(
                    template_languages?.options?.templateLanguages
                  ).map((key, index) => {
                    return (
                      <MenuItem key={index} value={key}>
                        {t(`languages.${key}`)}
                      </MenuItem>
                    )
                  })}
              </JumboTextField>
            </FormControl>
          ) : null}
          <Modal
            open={openImageSelector}
            onClose={() => setOpenImageSelector()}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Div sx={modalStyle}>
              <Images
                layout={'row-reverse'}
                selectorMode
                onCancel={() => setOpenImageSelector(false)}
                onSelect={image => {
                  const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`
                  setFieldValue('background_image', imageUrl)
                  setOpenImageSelector(false)
                }}
              />
            </Div>
          </Modal>
          <Field
            name='lastName'
            placeholder='Doe'
            component={Blocks}
            blocks={blockList}
            onSortEnd={onSortEnd}
            useDragHandle={true}
            url={url}
            onDeleteBlock={onDeleteBlock}
            template={sourceId}
          />
          {children}
        </Form>
      )}
    </Formik>
  )
}

export default PresentationSettings
