import React, { useEffect, useState } from 'react'
import JumboListNoDataPlaceHolder from '@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import { Card, IconButton, Tooltip, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { addMenu, deleteMenu, getMenus, editMenu } from 'app/services/api/menu'
import MenuItem from './MenuItem'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import JumboSearch from '@jumbo/components/JumboSearch'
import AddBoxIcon from '@mui/icons-material/AddBox'
import MenuForm from './MenuForm'
import { useNavigate } from 'react-router-dom'
import { DAYS } from 'app/utils/constants/settings'

const Menus = () => {
  const { getAuthUser } = useJumboAuth()
  const {
    customerId,
    customer: { productLanguages }
  } = getAuthUser()
  const { t, i18n } = useTranslation()
  const { showDialog, hideDialog } = useJumboDialog()
  const [searchName, setSearchName] = useState()
  const navigate = useNavigate()

  const [menus, setMenus] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const loadMenus = async () => {
    await getMenus(customerId).then(menus => {
      const filteredMenus = searchName
        ? menus.filter(menu => menu.label.toLowerCase().includes(searchName))
        : menus
      setMenus(filteredMenus || [])
    })
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    searchName !== undefined && loadMenus()
  }, [searchName, i18n.language])

  const handleDelete = async menu => {
    hideDialog()
    setLoading(true)
    try {
      await deleteMenu(menu.url, customerId)
      await loadMenus()
      const message = `${t('buttons.delete')} ${t(
        'notifications.wasSuccessful'
      )}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.delete')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const openModal = (type, menu) => {
    showDialog({
      variant: 'default',
      title: t(`pages.menus.${type}`),
      sx: {
        '& .MuiDialog-container': {
          alignItems: 'flex-start'
        }
      },
      content: (
        <MenuForm
          menu={menu}
          onAddMenu={handleAddMenu}
          onEditMenu={handleEditMenu}
          onCancel={hideDialog}
          productLanguages={productLanguages}
        />
      )
    })
  }

  const handleAddMenu = async menu => {
    setLoading(true)
    hideDialog()
    var curr = new Date() // get current date
    var firstDay = curr.getDate() - curr.getDay() + 1 // First day is the day of the month - the day of the week

    const metadata = {}
    DAYS.forEach(day => {
      var date = new Date(curr.setDate(firstDay)).toISOString().split('T')[0]
      firstDay++
      metadata[day] = { date }
    })
    try {
      const url = await addMenu(customerId, { ...menu, metadata })
      await loadMenus()
      const message = `${t('buttons.add')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
      navigate(`/menu/${url}`)
    } catch (error) {
      const message = `${t('buttons.add')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }
  const handleEditMenu = async menu => {
    setLoading(true)
    hideDialog()
    try {
      await editMenu(menu)
      await loadMenus()
      const message = `${t('buttons.edit')} ${t('notifications.wasSuccessful')}`
      enqueueSnackbar(message, {
        variant: 'success'
      })
    } catch (error) {
      const message = `${t('buttons.edit')} ${t('notifications.wasFailed')}`
      enqueueSnackbar(message, {
        variant: 'error'
      })
      setLoading(false)
    }
  }

  const deleteConfirmation = menu => {
    showDialog({
      variant: 'confirm',
      title: t('widgets.confirmDialog.areYouSure'),
      onYes: () => handleDelete(menu),
      onNo: () => hideDialog()
    })
  }

  return (
    <React.Fragment>
      <Typography variant={'h2'} mb={3}>
        {t('sidebar.menuItem.menus')}
      </Typography>
      <Stack
        direction='row'
        display='flex'
        alignItems='center'
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: '50%' }}>
          <JumboSearch
            page='menus'
            onChange={setSearchName}
            sx={{
              width: '100%'
            }}
            placeholder={t('pages.presentation.searchPresentation')}
          />
        </Card>
        <Tooltip title={t('pages.menus.addMenu')}>
          <IconButton onClick={() => openModal('addMenu')}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {menus?.length ? (
        menus.map((menu, index) => (
          <MenuItem
            key={index}
            item={menu}
            disabled={loading}
            onDelete={deleteConfirmation}
            onEdit={() => {
              navigate(`/menu/${menu.url}`)
            }}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  )
}

export default Menus
