import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Button, FormControl, MenuItem } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

const STATUS_LIST = ['Active', 'Inactive']

const DeviceForm = ({ device, onAddDevice, onCancel }) => {
  const { t } = useTranslation()
  const DeviceSchema = Yup.object().shape({
    label: Yup.string().required(t('pages.articleCategories.labelRequired')),
    status: Yup.string().required(t('pages.articles.statusRequired'))
  })

  return (
    <Formik
      validateOnChange={true}
      initialValues={device?.settings?.main || { label: '', status: '' }}
      validationSchema={DeviceSchema}
      onSubmit={async data => {
        onAddDevice(data)
      }}
    >
      <Form style={{ textAlign: 'left' }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            required
            name='label'
            label={t('pages.presentation.label')}
          />
        </Div>
        <FormControl sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 3 }} fullWidth>
          <JumboTextField
            select
            name='status'
            required
            label={t('pages.screens.status')}
          >
            {STATUS_LIST?.map((status, index) => {
              return (
                <MenuItem key={index} value={status}>
                  {status}
                </MenuItem>
              )
            })}
          </JumboTextField>
        </FormControl>
        <Div
          sx={{
            mt: 2,
            mb: 1,
            mx: 2,
            gap: '4px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button size='normal' onClick={onCancel}>
            {t('buttons.cancel')}
          </Button>
          <Button type='submit' variant='contained' size='normal'>
            {t('buttons.ok')}
          </Button>
        </Div>
      </Form>
    </Formik>
  )
}

export default DeviceForm
