import jwtAuthAxios from '../auth/jwtAuth'

export const getProductAttribute = async (url, customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/product-attribute/get?url=${url}&customerId=${customerId}`
  )
  return data.product
}

export const addProductAttribute = async (customerId, attribute) => {
  const { data } = await jwtAuthAxios.post('/product-attribute/add', {
    customerId,
    metadata: attribute,
    code: 'allergens'
  })
  return data.url
}

export const editProductAttribute = async (customerId, attribute, url) => {
  const { data } = await jwtAuthAxios.post('/product-attribute/update', {
    customerId,
    metadata: attribute,
    code: 'allergens',
    url
  })
  return data
}

export const deleteProductAttribute = async (url, customerId) => {
  const { data } = await jwtAuthAxios.delete(
    `/product-attribute/delete?url=${url}&customerId=${customerId}`
  )
  return data
}
