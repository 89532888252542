import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import Div from '@jumbo/shared/Div'
import { Field, Form, Formik, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Switch,
  Table,
  TableBody,
  Typography
} from '@mui/material'
import JumboColorPickerField from '@jumbo/components/JumboFormik/JumboColorPickerField'
import CollectionsIcon from '@mui/icons-material/Collections'
import CancelIcon from '@mui/icons-material/Cancel'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'
import Images from 'app/pages/media/images/Images'
import { BE_URL } from 'app/services/config'

const ScreenSettings = ({
  settings: { screenName, screenWidth, screenHeight },
  children
}) => {
  const { t } = useTranslation()

  return (
    <Formik
      validateOnChange={true}
      initialValues={{ screenName, screenWidth, screenHeight }}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        // await onReset(data.email);
        setSubmitting(false)
      }}
    >
      <Form style={{ textAlign: 'left' }} noValidate>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            name='screenName'
            label={t('pages.presentation.label')}
          />
        </Div>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            type='number'
            name='screenWidth'
            label={t('pages.presentation.width')}
          />
        </Div>
        <Div sx={{ mt: 2, mb: 1, mx: 2 }}>
          <JumboTextField
            fullWidth
            type='number'
            name='screenHeight'
            label={t('pages.presentation.height')}
          />
        </Div>
        {children}
      </Form>
    </Formik>
  )
}

export default ScreenSettings
