import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'
import Div from '@jumbo/shared/Div/Div'
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField'
import { GooglePlaceField } from 'app/pages/presentation/module/form/types'

const FIELD_NAME = 'WEATHER_FIELD'

export const WEATHER_FIELD_SCHEMA = {
  apiKey: '',
  geocodeApiKey: '',
  location: {
    lat: '',
    lng: ''
  }
}

const WeatherField = ({ handleChange, expanded }) => {
  const { t, i18n } = useTranslation()

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: '33%', flexShrink: 0 }}>
          {t('pages.editDevice.weather')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <GooglePlaceField
              name='weather.city'
              coordinatesName='weather.location'
              lang={i18n.language}
              parameters={{
                label: { [i18n.language]: t(`pages.editDevice.selectLocation`) }
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='weather.apiKey'
                label={t('pages.editDevice.apiKey')}
              />
            </Div>
          </Grid>
          <Grid item xs={12} md={4}>
            <Div sx={{ mt: 1, mb: 1 }}>
              <JumboTextField
                fullWidth
                name='weather.geocodeApiKey'
                label={t('pages.editDevice.geocodeApiKey')}
              />
            </Div>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default WeatherField
