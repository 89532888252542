import { Form, Formik } from 'formik'
import React from 'react'
import { GeneratedFormField } from './types'
import * as yup from 'yup'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2

const FieldsGrid = ({ fields }) => {
  const rows = {}
  Object.keys(fields).forEach(name => {
    const parameters = fields[name]
    if (!rows[parameters.configuration.row]) {
      rows[parameters.configuration.row] = {}
    }
    rows[parameters.configuration.row][name] = parameters
  })
  return (
    <>
      {Object.keys(rows).map((row, index) => {
        const fields = rows[row]
        return (
          <Grid container spacing={2} key={index}>
            {Object.keys(fields).map((name, index) => {
              const parameters = fields[name]
              return (
                <Grid
                  item
                  key={index}
                  xs={parameters.configuration.xs}
                  xl={parameters.configuration.xl}
                >
                  <GeneratedFormField
                    key={index}
                    name={name}
                    sx={{}}
                    parameters={parameters}
                  />
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </>
  )
}

const FormGenerator = ({ parameters, children, type }) => {
  if (!parameters?.parameters_settings) {
    return null
  }
  const {
    parameters_settings: { fields }
  } = parameters
  const filteredFields = Object.keys(fields)
    .filter((name, index) => {
      const parameters = fields[name]
      if (type === 'content') {
        return (
          parameters &&
          parameters.configuration &&
          parameters.configuration.section === 'content'
        )
      } else if (type === 'settings') {
        return (
          parameters &&
          (!parameters.configuration ||
            parameters.configuration.section !== 'content')
        )
      }
    })
    .reduce((res, key) => ((res[key] = fields[key]), res), {})
  const validationObj = {}
  filteredFields &&
    Object.keys(filteredFields).map((name, index) => {
      const parameters = filteredFields[name]
      if (
        parameters.required &&
        !parameters.hidden &&
        parameters.type !== 'boolean'
      ) {
        validationObj[name] = yup.mixed().required(<></>)
      }
    })
  const validationSchema = yup.object(validationObj)
  return (
    <Formik
      validateOnChange={true}
      validateOnMount
      initialValues={parameters}
      validationSchema={validationSchema}
      onSubmit={async (data, { setSubmitting }) => {
        setSubmitting(true)
        // await onReset(data.email);
        setSubmitting(false)
      }}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form style={{ textAlign: 'left', paddingTop: 16 }} noValidate>
          {filteredFields &&
            type === 'settings' &&
            Object.keys(filteredFields).map((name, index) => {
              const parameters = filteredFields[name]
              return (
                <GeneratedFormField
                  key={index}
                  name={name}
                  sx={{}}
                  parameters={parameters}
                />
              )
            })}
          {filteredFields && type === 'content' && (
            <FieldsGrid fields={filteredFields} />
          )}
          {children}
        </Form>
      )}
    </Formik>
  )
}

export default FormGenerator
